import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { TextCenter } from "../../../components/StandardComponents";
import LoadableEditor from "../../components/editor/loadableEditor";
import { ImageUpload } from "../../components/imageUpload";
import { promotionEditorUploadUrl } from "../../../backend-api/admin-api";

const CardContentContainer = styled.div`
  width: 100%;
  & strong {
    font-weight: 500;
  }
  & h1 {
    font-size: 100%;
  }
`;

interface LocalizatedViewProps {
  title: string;
  titleChange: (newTitle: string) => void;
  titleBlur: () => void;
  summary: string;
  summaryChange: (newSummary: string) => void;
  details: string;
  detailsChange: (newDetails: string) => void;
  seoImageUrl: string;
  seoImageChange: (newImaeg: string) => void;
}

export const PromotionLocalizedView = (props: LocalizatedViewProps) => {
  return (
    <>
      <div className="pt-2 pb-2">
        <label htmlFor="title" className={"pr-2 text-xl"}>
          Title
        </label>
        <InputText
          id="title"
          value={props.title}
          onChange={(e) =>
            props.titleChange &&
            props.titleChange((e.target as HTMLInputElement).value)
          }
          autoFocus
          onBlur={() => props.titleBlur && props.titleBlur()}
        />
      </div>
      <div className={"pt-2 pb-2"}>
        <div className={"flex"}>
          <div className="mr-2">
            <label className={"text-xl block"}>Summary</label>
            <InputTextarea
              value={props.summary}
              onChange={(e) =>
                props.summaryChange &&
                props.summaryChange((e.target as HTMLInputElement).value)
              }
              autoResize={true}
              rows={10}
              cols={40}
            />
          </div>
          <div className="ml-2">
            <label className={"text-xl block"}>Summary Preview</label>
            <CardContentContainer>
              <TextCenter>
                <ReactMarkdown source={props.summary} />
              </TextCenter>
            </CardContentContainer>
          </div>
        </div>
      </div>
      <div className={"pt-2 pb-2"}>
        <label className={"text-xl"}>Details</label>
        <LoadableEditor
          url={promotionEditorUploadUrl}
          data={props.details}
          dataUpdated={(data) =>
            props.detailsChange && props.detailsChange(data)
          }
        ></LoadableEditor>
      </div>
      <div className={"pt-2 pb-2"}>
        <label htmlFor="promotion image" className={"pr-2 text-xl"}>
          Localized SEO Image
        </label>
        <ImageUpload
          imageHeight={630}
          imageWidth={1200}
          initialImageUrl={props.seoImageUrl}
          onChangeImage={props.seoImageChange}
        />
      </div>
    </>
  );
};
