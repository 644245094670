import React from "react";
import styled from "styled-components";
import { rgba } from "polished";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const Button: React.FC<{
  className?: string;
  placeholder?: string;
  text: string;
}> = ({ className, text, ...rest }) => (
  <button className={className} {...rest}>
    {text}
  </button>
);

const StyledButton = styled(Button)`
  &&& {
    color: white;
    font-family: "Roboto", sans-serif;
    font-size: 14px;

    width: 120px;
    height: 30px;

    background-color: #a08036;
    border-radius: 4px;
    border: 1px solid white /*#a6a6a6*/;

    :focus {
      outline: 0 none;
      outline-offset: 0;
    }
    :hover {
      border-color: #212121;
    }
  }
`;

export default StyledButton;
