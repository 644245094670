/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// You can delete this file if you're not using it

require("typeface-roboto");
require("typeface-great-vibes");
require("typeface-playfair-display");
require("typeface-raleway");
require("typeface-montserrat");
require("typeface-work-sans");

require("./src/styles/global.css");

export const wrapPageElement = ({ element, props }) => {
  useEffect(() => {
    console.log("Page Element");
    console.log(props);
    if (
      props.location.pathname.match(`\/${props.pageContext.language}\/.*`) ===
        null &&
      !props.location.pathname.startsWith("/admin")
    ) {
      console.log("Navigate to langauge specific page");
      navigate(`/${props.pageContext.language}${props.location.pathname}`);
    }
  }, []);
  return <>{element}</>;
};


export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LchGCwpAAAAAJOykb7MPkmfGr0TN-grkWXNjGnk">
      {element}
    </GoogleReCaptchaProvider>
  )
}