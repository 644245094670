import React from "react";
import {
  TourSchedules,
  PriceOption,
} from "../../dataProviders/ToursDataProvider";
import styled from "styled-components";
import { NumericFormat } from "react-number-format";

const PriceContainer = styled.div`
  //font-size: 120%
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`;

const PriceCurrencySpan = styled.span`
    font-size: 120%
    font-weight: 500;
`;
const PriceUnitSpan = styled.span`
  font-size: 80%;
`;

const ScheduleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SchedulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  //background-color: red;
  align-items: center;
  margin-bottom: 10px;
`;

const ScheduleNameSpan = styled.span`
  font-weight: 500;
  margin-right: 5px;
`;

const ScheduleStartEndSpan = styled.span``;

const GroupSizeDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 80%;
`;

const SmallTextSpan = styled.span`
  font-size: 80%;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  //background-color: purple;
`;

export const TourCardDetailsComposite: React.FC<{
  price: PriceOption;
  minGroup?: number;
  maxGroup?: number;
  schedules: TourSchedules;
}> = ({ price, minGroup, maxGroup, schedules }) => {
  return (
    <Container>
      <PriceContainer>
        <PriceCurrencySpan>
          <NumericFormat
            value={price.price}
            displayType={"text"}
            thousandSeparator={true}
          ></NumericFormat>
          {` ${price.currency}`}
        </PriceCurrencySpan>
        <PriceUnitSpan>{`per ${price.unit}`}</PriceUnitSpan>
      </PriceContainer>
      <SchedulesContainer>
        {schedules.map((schedule, index) => (
          <ScheduleContainer>
            {schedule.scheduleName && (
              <ScheduleNameSpan>
                {schedule.scheduleName}
                {": "}
              </ScheduleNameSpan>
            )}
            <ScheduleStartEndSpan>{`${schedule.start} to ${schedule.end}`}</ScheduleStartEndSpan>
          </ScheduleContainer>
        ))}
      </SchedulesContainer>
      <GroupSizeDetailsContainer>
        {minGroup && <div>Minimum {minGroup} People</div>}
        {minGroup && maxGroup && <SmallTextSpan>and</SmallTextSpan>}
        {maxGroup && <div>Maximum {maxGroup} People</div>}
      </GroupSizeDetailsContainer>
    </Container>
  );
};
