import React from "react";
//import styled from "styled-components";
import {
  ContentBox,
  TextCenter,
  Center,
  Link,
  DesktopOnly,
  MobileOnly,
} from "../StandardComponents";
import { styled } from "../../styles/ThemeProvider";
import {
  LocationMarkerIcon,
  PhoneIcon,
  EnvelopeIcon,
  FaxIcon,
} from "../../components/icons";

import {
  Link as UnstyledLink,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import SocialLinks from "../SocialLinks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 5px solid
    ${(props) => props.theme.components.footer.backgroundColorBottom};
`;

const StyledLink = styled(UnstyledLink)`
  text-decoration: none;
  color: ${(props) => props.theme.colorOneLighter};
  :hover {
    color: ${(props) => props.theme.colorOne};
    text-decoration: underline;
  }
`;

const HelpfulLinkContainer = styled.div`
  &::after {
    content: "⋅";
    margin-left: 3px;
    margin-right: 3px;
    font-size: 150%;
    position: relative;
    top: 2px;
  }

  &:last-child::after {
    content: "";
  }
`;
const HelpfulLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  font-size: 90%;
  font-weight: 500;
`;

const HelpfulLinksSocial = styled(HelpfulLinks)`
  margin-top: 20px;
`;

const BoxTop = styled.div`
  height: 200px;
  background-color: ${(props) =>
    props.theme.components.footer.backgroundColorMain};
  display: flex;
  align-items: center;
  padding: 10px;
  @media only screen and (max-width: 660px) {
    flex-direction: column;
    height: 480px;
  }
`;

const BoxBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50px;
  background-color: ${(props) =>
    props.theme.components.footer.backgroundColorBottom};

  @media only screen and (max-width: 660px) {
    font-size: 90%;
  }
`;

const BottomBoxContent = styled.div`
  color: white;
  display: flex;
  text-align: center;
`;

const TopBoxContent = styled.div`
  color: white;
  flex: 1;
  display: flex;
  justify-content: stretch;
  margin-left: 15px;
  @media only screen and (max-width: 660px) {
    margin-left: 0;
    flex-direction: column-reverse;
    & > div {
      margin-bottom: 20px;
    }
  }
  //background-color: blue;
`;

const ImageLogo = styled.img`
  height: 100px;
  filter: saturate(0.2);
  @media only screen and (max-width: 660px) {
    height: 70px;
  }
`;

const FooterItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
`;

const FooterItemText = styled.div`
  font-size: 70%;
  display: flex;
  align-items: center;
`;

const FooterItem = styled.div`
  display: flex;
  padding-bottom: 5px;

  & > ${FooterItemText} {
    margin-left: 10px;
  }
  & > ${FooterItemContainer} {
    margin-left: 5px;
  }
`;

const StyledContactLinks = styled.a`
  color: white;
`;
const StyledContactLinksPhone: React.FC<{ children: React.ReactNode, href: string }> = ({
  children,
  href
}) => (
  <>
    <DesktopOnly>{children}</DesktopOnly>
    <MobileOnly>
      <StyledContactLinks href={href}>{children}</StyledContactLinks>
    </MobileOnly>
  </>
);

const Footer: React.FC = () => {
  const { t } = useTranslation();

  const linkMap = [
    { label: t("Home"), link: "/" },
    { label: t("Rooms & Amenities"), link: "/rooms" },
    { label: t("Restaurant & Bar"), link: "/restaurant-and-bar" },
    { label: t("Spa Services"), link: "/spa" },
    {
      label: t("Private Car Tours"),
      link: "/transportation-and-tours/private-car",
    },
    {
      label: t("Airport Transfers"),
      link: "/transportation-and-tours/airport-transfer",
    },
    { label: t("Tours"), link: "/transportation-and-tours/tours" },
    { label: t("Promotions"), link: "/promotions" },
    { label: t("Contact Us"), link: "/contact-us" },
  ];
  return (
    <Container>
      <BoxTop>
        <ImageLogo src="/assets/Laluna-Logo-150x150.png"></ImageLogo>
        <TopBoxContent>
          <FooterItemContainer>
            <FooterItem>
              <div>
                <LocationMarkerIcon />
              </div>
              <FooterItemText>
                {
                  "12 Nguyen Du Street, Cam Pho Ward, Hoi An City, Quang Nam Province, Vietnam"
                }
              </FooterItemText>
            </FooterItem>
            <FooterItem>
              <div>
                <EnvelopeIcon />
              </div>
              <FooterItemText>
                <StyledContactLinks href="mailto:support@lalunahoian.com">
                  {"support@lalunahoian.com"}
                </StyledContactLinks>
              </FooterItemText>
            </FooterItem>
            <FooterItem>
              <div>
                <PhoneIcon />
              </div>
              <FooterItemText>
                <StyledContactLinksPhone href="tel:+842353666678">
                  {"(+84) 235 3666 678"}
                </StyledContactLinksPhone>
              </FooterItemText>
            </FooterItem>
            {/*<FooterItem>
              <div>
                <FaxIcon />
              </div>
              <FooterItemText>{"(+84) 235 3666 676"}</FooterItemText>
              </FooterItem>*/}
          </FooterItemContainer>
          <FooterItemContainer>
            <HelpfulLinks>
              {linkMap &&
                linkMap.map((link, index) => (
                  <HelpfulLinkContainer>
                    <StyledLink to={link.link}>{link.label}</StyledLink>
                  </HelpfulLinkContainer>
                ))}
            </HelpfulLinks>
            <HelpfulLinksSocial>
              <SocialLinks></SocialLinks>
            </HelpfulLinksSocial>
          </FooterItemContainer>
          <FooterItemContainer></FooterItemContainer>
        </TopBoxContent>
      </BoxTop>
      <BoxBottom>
        <BottomBoxContent>{`Copyright © ${new Date().getFullYear()} Laluna Hoi An Riverside Hotel & Spa. All Rights Reserved. Designed by Chris & Quynh Gabel`}</BottomBoxContent>
      </BoxBottom>
    </Container>
  );
};

export default Footer;
