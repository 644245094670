import React, { ReactElement } from "react";
import { I18n, I18nTFunction } from "react-i18next";
import { ReactComponentLike } from "prop-types";
import { CarSerivceOptions } from "../components/Transportation/Types";

const HOI_AN = "Hoi An";
const INTERCONTINENTAL_DA_NANG = "Intercontinental Da Nang Hotel";
const MARBEL_MOUNTAINS = "Marbel Mountains";
const MY_KHE_BEACH = "My Khe Beach";
const HAN_MARKET = "Han Market";
const DRAGON_BRIDGE = "Dragon Bridge";
const LUNCH = "Stop for Lunch";
const DA_NANG_AIRPORT = "Da Nang Airport";
const DA_NANG_TRAIN_STATION = "Da Nang Train Station";
const DA_NANG = "Da Nang";
const DA_NANG_HOTEL = "Da Nang Hotel";
const LING_UNG_PAGODA = "Ling Ung Pagoda";
const CHAM_MUSEUM = "Cham Museum";
const MY_SON = "My Son";
const HUE = "Hue";
const HAI_VAN_PASS = "Hai Van Pass";
const LANG_CO_BEACH = "Lang Co Beach";
const LANG_CO = "Lang Co";
const BA_NA_HILLS = "Ba Na Hills";
const HUE_CITADEL = "Hue Citadel";
const THIEN_MU_PAGODA = "Thien Mu Pagoda";
const DONG_BA_MARKET = "Dong Ba Market";
const KHAI_DINH_TEMPLE = "Khai Dinh Temple";

const PrvateCarData: CarSerivceOptions = [
  {
    start: HOI_AN,
    end: INTERCONTINENTAL_DA_NANG,
    prices: [
      { title: "Car", price: "$25" },
      { title: "SUV", price: "$35" },
      { title: "Van", price: "$45" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [MARBEL_MOUNTAINS],
    prices: [
      { title: "Car", price: "$35" },
      { title: "SUV", price: "$45" },
      { title: "Van", price: "$55" },
    ],
  },
  {
    start: HOI_AN,
    end: [DA_NANG_AIRPORT, DA_NANG_TRAIN_STATION, DA_NANG_HOTEL],
    stops: [MARBEL_MOUNTAINS, [MY_KHE_BEACH, HAN_MARKET, DRAGON_BRIDGE], LUNCH],
    prices: [
      { title: "Car", price: "$48" },
      { title: "SUV", price: "$55" },
      { title: "Van", price: "$65" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [MARBEL_MOUNTAINS, LING_UNG_PAGODA],
    prices: [
      { title: "Car", price: "$55" },
      { title: "SUV", price: "$60" },
      { title: "Van", price: "$70" },
    ],
  },
  {
    start: HOI_AN,
    end: DA_NANG,
    stops: [MARBEL_MOUNTAINS, LING_UNG_PAGODA],
    prices: [
      { title: "Car", price: "$65" },
      { title: "SUV", price: "$70" },
      { title: "Van", price: "$75" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [MARBEL_MOUNTAINS, CHAM_MUSEUM, LING_UNG_PAGODA, HAN_MARKET],
    prices: [
      { title: "Car", price: "$75" },
      { title: "SUV", price: "$85" },
      { title: "Van", price: "$95" },
    ],
  },
  {
    start: HOI_AN,
    end: [DA_NANG_AIRPORT, DA_NANG_TRAIN_STATION, DA_NANG_HOTEL],
    stops: [MARBEL_MOUNTAINS, CHAM_MUSEUM, LING_UNG_PAGODA, HAN_MARKET],
    prices: [
      { title: "Car", price: "$65" },
      { title: "SUV", price: "$70" },
      { title: "Van", price: "$75" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [MARBEL_MOUNTAINS, MY_KHE_BEACH],
    prices: [
      { title: "Car", price: "$40" },
      { title: "SUV", price: "$50" },
      { title: "Van", price: "$60" },
    ],
  },
  {
    start: HOI_AN,
    end: [DA_NANG_AIRPORT, DA_NANG_TRAIN_STATION, DA_NANG_HOTEL],
    stops: [MARBEL_MOUNTAINS, MY_KHE_BEACH],
    prices: [
      { title: "Car", price: "$40" },
      { title: "SUV", price: "$50" },
      { title: "Van", price: "$60" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [MARBEL_MOUNTAINS, CHAM_MUSEUM],
    prices: [
      { title: "Car", price: "$45" },
      { title: "SUV", price: "$55" },
      { title: "Van", price: "$65" },
    ],
  },
  {
    start: HOI_AN,
    end: [DA_NANG_AIRPORT, DA_NANG_TRAIN_STATION, DA_NANG_HOTEL],
    stops: [MARBEL_MOUNTAINS, CHAM_MUSEUM],
    prices: [
      { title: "Car", price: "$40" },
      { title: "SUV", price: "$50" },
      { title: "Van", price: "$60" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [MY_SON],
    prices: [
      { title: "Car", price: "$45" },
      { title: "SUV", price: "$55" },
      { title: "Van", price: "$65" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [MY_SON, MARBEL_MOUNTAINS, MY_KHE_BEACH],
    prices: [
      { title: "Car", price: "$70" },
      { title: "SUV", price: "$80" },
      { title: "Van", price: "$90" },
    ],
  },
  {
    start: HOI_AN,
    end: [DA_NANG_AIRPORT, DA_NANG_TRAIN_STATION, DA_NANG_HOTEL],
    stops: [MY_SON],
    prices: [
      { title: "Car", price: "$55" },
      { title: "SUV", price: "$65" },
      { title: "Van", price: "$75" },
    ],
  },
  {
    start: HOI_AN,
    end: HUE,
    stops: [MY_SON],
    prices: [
      { title: "Car", price: "$110" },
      { title: "SUV", price: "$120" },
      { title: "Van", price: "$130" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [HAI_VAN_PASS, LANG_CO_BEACH],
    prices: [
      { title: "Car", price: "$70" },
      { title: "SUV", price: "$80" },
      { title: "Van", price: "$90" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [LANG_CO],
    prices: [
      { title: "Car", price: "$75" },
      { title: "SUV", price: "$85" },
      { title: "Van", price: "$100" },
    ],
  },
  {
    start: HOI_AN,
    end: BA_NA_HILLS,
    prices: [
      { title: "Car", price: "$35" },
      { title: "SUV", price: "$40" },
      { title: "Van", price: "$45" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [BA_NA_HILLS],
    prices: [
      { title: "Car", price: "$65" },
      { title: "SUV", price: "$75" },
      { title: "Van", price: "$85" },
    ],
  },
  {
    start: HOI_AN,
    end: HUE,
    prices: [
      { title: "Car", price: "$70" },
      { title: "SUV", price: "$75" },
      { title: "Van", price: "$85" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [HUE],
    prices: [
      { title: "Car", price: "$85" },
      { title: "SUV", price: "$95" },
      { title: "Van", price: "$105" },
    ],
  },
  {
    start: HOI_AN,
    end: HUE,
    stops: [MARBEL_MOUNTAINS, CHAM_MUSEUM, HAI_VAN_PASS, LANG_CO_BEACH],
    prices: [
      { title: "Car", price: "$90" },
      { title: "SUV", price: "$105" },
      { title: "Van", price: "$115" },
    ],
  },
  {
    start: HOI_AN,
    end: HUE,
    stops: [HUE_CITADEL, THIEN_MU_PAGODA, DONG_BA_MARKET],
    prices: [
      { title: "Car", price: "$90" },
      { title: "SUV", price: "$105" },
      { title: "Van", price: "$115" },
    ],
  },
  {
    start: HOI_AN,
    end: HOI_AN,
    stops: [HUE_CITADEL, THIEN_MU_PAGODA, DONG_BA_MARKET, KHAI_DINH_TEMPLE],
    prices: [
      { title: "Car", price: "$95" },
      { title: "SUV", price: "$100" },
      { title: "Van", price: "$110" },
    ],
  },
];

export const TransportationDataProvider: ReactComponentLike = ({
  children,
}: {
  children: (privateCarOptions: CarSerivceOptions) => ReactElement;
}) => {
  return <>{children && children(PrvateCarData)}</>;
};
