import React, { useState, useEffect, useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import GlobalStyle from "../../styles/GlobalStyle";
import { ContentBox, Center } from "../../components/StandardComponents";

import { MainLayout, MainLayout2 } from "../../components/layouts";
import { I18nTFunction, I18n } from "react-i18next";

import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import styled, { keyframes, css } from "styled-components";

import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { getEncodedData } from "../../utils";
import {
  getContactUsForm,
  submitContactUsForm,
} from "../../backend-api/user-api";
import {
  ContactUsField,
  ContactUsOption,
  ContactUsOptions,
} from "../../backend-api/types";
import { DateTime } from "luxon";

const FormControlWrapper = styled.div`
  display: flex;
  padding-top: 20px;

  & input {
    width: 300px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSubmitButton = styled.button`
  background-color: #a08036;
  color: white;
  font-family: "Playfair Display", serif;
  font-style: italic;
  border: 1px #1c1c1c solid;
  border-radius: 13px;
  padding: 3px 20px;
  text-shadow: 0px 0px 6px #1c1c1c;
  display: inline-block;
  box-shadow: 2px 2px 3px 0px #1c1c1c;
`;

const FormPlaceHolder = styled.div`
  height: ${(props) => `${props.height || 0}px`};
  min-height: ${(props) => `${props.height || 0}px`};
  //background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageKeyFrames = keyframes`
  0% {
    color: black;
  }
  50% {
    color: #999;
  }
`;

const MessageWrapper = styled.div`
  ${(props) =>
    props.doAnimation &&
    css`
      animation: ${MessageKeyFrames} 2s linear infinite;
    `};

  font-size: 150%;
  font-weight: 600;
`;

/*const optionTree = [
  {
    label: "Airport Transfer",
    value: "airport-transfer",
    fields: [
      {
        type: "message",
        label:
          "Please fill provide the following information to schedule an airport transfer. Once submitted our support staff will reach out by email to confirm.",
      },
      { type: "datetime", label: "Transfer Date and Time" },
      { type: "text", label: "Flight Number" },
      { type: "text", label: "Airline" },
      { type: "text", label: "Email" },
      { type: "text", label: "Hotel Reservation" },
      {
        type: "message",
        label:
          "Please let us know if there are any other special considerations or travel details that would help us serve you better.",
      },
      { type: "textarea", label: "Additional Details" },
    ],
  },
  {
    label: "Other",
    value: "other",
    fields: [
      { type: "text", label: "Email" },
      { type: "textarea", label: "Details" },
    ],
  },
];*/

const ContactUsForm: React.FC = (props) => {
  const [optionTree, setOptionTree] = useState<ContactUsOptions | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [formHeight, setFormHeight] = useState(0);
  const [submittedSuccess, setSubmittedSuccess] = useState(null);
  const [selected, setSelected] = useState<ContactUsOption | null>(null);

  const formRef = useRef(null);

  const [values, setValues] = useState<Array<{ key: string; value: any }>>([]);

  useEffect(() => {
    getContactUsForm().then((result) => {
      setOptionTree(result);
      //if an item was specfied, then select it
      const data = getEncodedData();
      if (data && data.selectedName && result !== null) {
        const option = result.find(
          (option) => option.label === data.selectedName
        );
        option && setSelected(option);

        if (data.values && Array.isArray(data.values)) {
          const filteredDataValues = data.values.filter(
            (item) =>
              option?.fields.find((opt) => opt.label === item.key) !== null
          );

          setValues(filteredDataValues);
        }
      }
    });
  }, []);

  const getSafeSelected = (): ContactUsOption => {
    if (selected && selected.fields && selected.value) {
      return selected;
    }
    return { value: null, fields: [] };
  };

  const getValueForField = (label: string) => {
    const found = values.find((value) => value.key === label);
    if (found) {
      return found.value;
    }
    return undefined;
  };

  const setValueForField = (
    label: string,
    newValue: string | boolean | Date | Date[]
  ) => {
    const temp = [];
    values.forEach((value) => {
      if (value.key !== label) {
        temp.push(value);
      }
    });
    temp.push({ key: label, value: newValue });
    setValues(temp);
  };

  const getFieldForType = (item: ContactUsField) => {
    //console.log(item);
    switch (item.type) {
      case "option":
        return (
          <FormControlWrapper>
            <Dropdown
              id={`${item.label.replace(" ", "")}-field`}
              name={`${item.label.replace(" ", "")}-field`}
              placeholder={item.label}
              options={item.options?.map((opt) => ({ label: opt, value: opt }))}
              value={getValueForField(item.label)}
              onChange={(e) => {
                const selectedItem = item.options?.find(
                  (item) => item === e.value
                );
                if (selectedItem) setValueForField(item.label, selectedItem);
              }}
            ></Dropdown>
          </FormControlWrapper>
        );
      case "date":
        return (
          <FormControlWrapper>
            <span className="p-float-label">
              <Calendar
                id={`${item.label.replace(" ", "")}-field`}
                name={`${item.label.replace(" ", "")}-field`}
                showTime={false}
                hourFormat="12"
                value={getValueForField(item.label)}
                onChange={(e) => setValueForField(item.label, e.target.value)}
              ></Calendar>
              <label htmlFor={`field-${item.label.replace(" ", "")}`}>
                {item.label}
              </label>
            </span>
          </FormControlWrapper>
        );
      case "checkbox":
        return (
          <FormControlWrapper>
            <Checkbox
              id={`${item.label.replace(" ", "")}-field`}
              name={`${item.label.replace(" ", "")}-field`}
              onChange={(e) => setValueForField(item.label, e.checked)}
              checked={getValueForField(item.label)}
            ></Checkbox>
            <label
              htmlFor={`field-${item.label.replace(" ", "")}`}
              className="p-checkbox-label"
            >
              {item.label}
            </label>
          </FormControlWrapper>
        );
      case "text":
        return (
          <FormControlWrapper>
            <span className="p-float-label">
              <InputText
                id={`${item.label.replace(" ", "")}-field`}
                name={`${item.label.replace(" ", "")}-field`}
                autoComplete={item.label.replace(" ", "")}
                value={getValueForField(item.label)}
                onChange={(e) => setValueForField(item.label, e.target.value)}
                size="30"
              ></InputText>
              <label htmlFor={`field-${item.label.replace(" ", "")}`}>
                {item.label}
              </label>
            </span>
          </FormControlWrapper>
        );
      case "textarea":
        return (
          <FormControlWrapper key={`field-${item.label.replace(" ", "")}`}>
            <InputTextarea
              name={`${item.label.replace(" ", "")}-field`}
              autoComplete={item.label.replace(" ", "")}
              rows={5}
              cols={80}
              placeholder={item.label}
              value={getValueForField(item.label)}
              onChange={(e) => setValueForField(item.label, e.target.value)}
            ></InputTextarea>
          </FormControlWrapper>
        );
      case "datetime":
        return (
          <FormControlWrapper key={`field-${item.label.replace(" ", "")}`}>
            <span className="p-float-label">
              <Calendar
                id={`${item.label.replace(" ", "")}-field`}
                name={`${item.label.replace(" ", "")}-field`}
                showTime={true}
                hourFormat="12"
                value={getValueForField(item.label)}
                onChange={(e) => setValueForField(item.label, e.target.value)}
              ></Calendar>
              <label htmlFor={`field-${item.label.replace(" ", "")}`}>
                {item.label}
              </label>
            </span>
          </FormControlWrapper>
        );
      case "message":
        return (
          <FormControlWrapper key={`field-${item.label.replace(" ", "")}`}>
            {item.label}
          </FormControlWrapper>
        );
    }
  };

  const renderFields = () => {
    const safe = getSafeSelected();
    if (safe) {
      return safe.fields.map((field, i) => <>{getFieldForType(field)}</>);
    }
    return <></>;
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmitWithRecaptchaV3 = useCallback(async () => {
    const mergedData: any = { ...selected };
    if (mergedData.fields) {
      mergedData.fields = mergedData.fields
        .filter((x: any) => x.type !== "message")
        .map((x: any) => {
          const foundValue = values.find((item) => item.key === x.label);
          if (foundValue) {
            if (x.type === "datetime" || x.type === "date") {
              let formattedData = DateTime.fromJSDate(foundValue.value)
                .setZone("UTC+7", { keepLocalTime: true })
                .toFormat("LLLL dd, yyyy 'at' h:mm a ZZZZ");
              return {
                ...x,
                value: formattedData,
              };
            }

            return {
              ...x,
              value: foundValue.value,
            };
          }
          return x;
        });
    }
    setFormHeight(formRef.current.getClientRects()[0].height);
    setSubmitted(true);
    setSubmittedSuccess(null);
    console.log(mergedData)
    submitContactUsForm(mergedData, await executeRecaptcha("contact_us_submit"))
      .catch((err) => {
        setSubmitted(false);
        setSubmittedSuccess(false);
        setTimeout(() => setSubmittedSuccess(null), 6000);
      })
      .then((result) => {
        setSubmitted(false);
        setSubmittedSuccess(true);
        setValues([]);
        setSelected(null);
        setTimeout(() => setSubmittedSuccess(null), 6000);
        console.log(formRef.current);
      });
  }, [executeRecaptcha, selected, formRef.current, values]);

  return (
    <>
      {submitted && !submittedSuccess && (
        <FormPlaceHolder height={formHeight}>
          <MessageWrapper doAnimation={true}>Sending...</MessageWrapper>
        </FormPlaceHolder>
      )}
      {submittedSuccess === true && (
        <FormPlaceHolder height={formHeight}>
          <MessageWrapper>Our team was successfully notified</MessageWrapper>
        </FormPlaceHolder>
      )}
      {submittedSuccess === false && (
        <FormPlaceHolder height={formHeight}>
          <MessageWrapper>
            There was an error... please try again...
          </MessageWrapper>
        </FormPlaceHolder>
      )}
      {!submitted && submittedSuccess === null && (
        <FormContainer ref={formRef}>
          <FormControlWrapper>
            {"Please select a topic below"}
          </FormControlWrapper>
          <Dropdown
            placeholder="Select a Topic"
            options={optionTree}
            value={getSafeSelected().value}
            onChange={(e) => {
              const selectedItem = optionTree.find(
                (item) => item.value === e.value
              );
              if (selectedItem) setSelected(selectedItem);
            }}
          ></Dropdown>
          {renderFields()}
          <FormControlWrapper>
            {selected && (
              <StyledSubmitButton
                disabled={submitted}
                onClick={handleSubmitWithRecaptchaV3}
              >
                Send
              </StyledSubmitButton>
            )}
          </FormControlWrapper>
        </FormContainer>
      )}
    </>
  );
};

const IndexPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainLayout2
        pageTitle={t("Contact Us")}
        pageBannerImg={"/assets/general-banner.jpg"}
        pageCrumbMap={[{ link: "/", name: t("Home") }]}
      >
        <ContentBox>
          <ContactUsForm></ContactUsForm>
        </ContentBox>
      </MainLayout2>
    </>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
