import React, { useState, useEffect } from "react";
import { styled } from "../../styles/ThemeProvider";
import { keyframes, css } from "styled-components";

const keyframesFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    
  }
`;

const keyframesFadeInAndTranslateLeft = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(-100%)
  }
`;

const keyframesFadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const keyframesFadeOutAndTranslateLeft = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-100%)
  }
`;

const translateLeft = keyframes`
  0% {
  }
  100% {
    transform: translateX(-100%)
  }
`;

interface TrackContainerProps {
  itemWidth: number;
  itemsVisible: number;
}
const TrackContainer = styled.div<TrackContainerProps>`
  overflow: hidden;
  max-width: ${(props) => `${props.itemWidth * props.itemsVisible}px`};
`;

const Track = styled.div`
  display: flex;
`;

interface SlideProps {
  visible: boolean;
  fadeout?: boolean;
  translateLeft?: boolean;
  fadeoutAndTranslateLeft?: boolean;
  fadein?: boolean;
  fadeinAndTranslateLeft?: boolean;
}

const Slide = styled.div<SlideProps>`
  opacity: ${(props) => (props.visible ? "1" : "0")};
  ${(props) =>
    props.fadeout
      ? css`
          animation-name: ${keyframesFadeOut};
        `
      : ""};
  ${(props) =>
    props.translateLeft
      ? css`
          animation-name: ${translateLeft};
        `
      : ""};
  ${(props) =>
    props.fadeoutAndTranslateLeft
      ? css`
          animation-name: ${keyframesFadeOutAndTranslateLeft};
        `
      : ""};
  ${(props) =>
    props.fadein
      ? css`
          animation-name: ${keyframesFadeIn};
        `
      : ""};
  ${(props) =>
    props.fadeinAndTranslateLeft
      ? css`
          animation-name: ${keyframesFadeInAndTranslateLeft};
        `
      : ""};
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
`;

interface RotateCarousel {
  itemsVisible: number;
  itemWidth: number;
  rotateDelay: number;
  children: Array<React.ReactElement>;
}

const RotateCarousel: React.FC<RotateCarousel> = ({
  itemsVisible,
  itemWidth,
  rotateDelay,
  children,
}) => {
  /*const itemsVisible = 3;
  const itemWidth = 264;
  const rotateDelay = 5000;
  const children = [
    <TempDiv>1</TempDiv>,
    <TempDiv>2</TempDiv>,
    <TempDiv>3</TempDiv>,
    <TempDiv>4</TempDiv>,
  ];*/
  const [doTransitionLeft, setDoTransitionLeft] = useState(false);
  const [displayList, setDisplayList] = useState(children);
  //const [ref, entry] = useIntersect({threshold:  Array.from(Array(100).keys(), i => i / 100)})
  //const [loaded, setLoaded] = useState(false);
  //const loaded = true;
  /*if(!loaded && entry.isIntersecting){
    setLoaded(true);
  }*/
  //const [startIndex, setStartIndex] = useState(0);
  const rotateDisplayListLeft = () => {
    const temp = [...displayList.slice(1)];
    temp.push(displayList[0]);
    setDisplayList(temp);
    //setStartIndex((startIndex + 1) % temp.length);
  };

  useEffect(() => {
    console.log("children changed");

    setDisplayList(children);
  }, children);

  useEffect(() => {
    if (doTransitionLeft) {
      const timeout = setTimeout(() => {
        rotateDisplayListLeft();
        setDoTransitionLeft(false);
      }, 1000);
      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setDoTransitionLeft(true);
      }, rotateDelay);
      return () => clearTimeout(timeout);
    }
  }, [doTransitionLeft]);

  return (
    <TrackContainer itemsVisible={itemsVisible} itemWidth={itemWidth}>
      <Track>
        {
          //console.log("---")
        }
        {displayList.map((item, index) => {
          /*const keyIndex = (startIndex + index) % displayList.length;
          console.log(
            `(${startIndex} + ${index}) % ${displayList.length} = ${keyIndex}`
          );*/

          return (
            <Slide
              key={item.key || index}
              visible={index < itemsVisible}
              fadeoutAndTranslateLeft={index === 0 && doTransitionLeft}
              translateLeft={
                index > 0 && index < itemsVisible && doTransitionLeft
              }
              fadeinAndTranslateLeft={
                index === itemsVisible && doTransitionLeft
              }
            >
              {item}
            </Slide>
          );
        })}
      </Track>
    </TrackContainer>
  );
};

export default RotateCarousel;
