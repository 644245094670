import { Promotion } from "../../../backend-api/types";
import { getLangForLocale } from "./supportedLocales";
import { LocaleEditable } from "./types";

export enum ActionKind {
  ShowNewLangDialog = "ShowNewLangDialog",
  AddLocale = "AddLocale",
  UpdateSelectedDetails = "UpdateSelectedDetails",
  UpdateSelectedTitle = "UpdateSelectedTitle",
  UpdateSelectedSummary = "UpdateSelectedSummary",
  SetSelectedLocale = "SetSelectedLocale",
  UpdateSlug = "UpdateSlug",
  UpdatePromotionImage = "UpdatePromotionImage",
  UpdatePromotionEnabled = "UpdatePromotionEnabled",
  DetailsInit = "DetailsInit",
  UpdateSelectedSEOImage = "UpdateSelectedSEOImage",
}

type AddLocaleAction = {
  type: ActionKind.AddLocale;
  payload: LocaleEditable;
};

type DisplayLanguageDialogAction = {
  type: ActionKind.ShowNewLangDialog;
  payload: boolean;
};

type PromotionEnabledAction = {
  type: ActionKind.UpdatePromotionEnabled;
  payload: boolean;
};

type UpdateSelectedDetails = {
  type: ActionKind.UpdateSelectedDetails;
  payload: string;
};

type UpdateSelectedTitle = {
  type: ActionKind.UpdateSelectedTitle;
  payload: string;
};

type UpdateSlugAction = {
  type: ActionKind.UpdateSlug;
  payload: { manualUpdate: boolean; slug: string };
};

type UpdateSelectedSummary = {
  type: ActionKind.UpdateSelectedSummary;
  payload: string;
};

type SetSelectedLocaleAction = {
  type: ActionKind.SetSelectedLocale;
  payload: LocaleEditable;
};

type UpdatePromotionImageAction = {
  type: ActionKind.UpdatePromotionImage;
  payload: string;
};

type DetailsInitAction = {
  type: ActionKind.DetailsInit;
  payload: Promotion;
};

type UpdateSelectedSEOImageAction = {
  type: ActionKind.UpdateSelectedSEOImage;
  payload: string;
};

export type DetailsReducerAction =
  | AddLocaleAction
  | DisplayLanguageDialogAction
  | UpdateSelectedDetails
  | UpdateSelectedTitle
  | UpdateSelectedSummary
  | SetSelectedLocaleAction
  | UpdateSlugAction
  | UpdatePromotionImageAction
  | PromotionEnabledAction
  | DetailsInitAction
  | UpdateSelectedSEOImageAction;

export interface StateInterface {
  showNewLanguageDialog: boolean;
  locales: Array<LocaleEditable>;
  slug: string;
  slugUpdatedManually: boolean;
  promotionImage: string | null;
  promotionEnabled: boolean;
}

export const stateInit = (init: string | undefined | null) => {
  return {
    showNewLanguageDialog: false,
    locales: [],
    slug: "",
    slugUpdatedManually: false,
    promotionImage: null,
    promotionEnabled: true,
  };
};

export const reducer = (
  state: StateInterface,
  action: DetailsReducerAction
): StateInterface => {
  const { type } = action;
  switch (type) {
    case ActionKind.AddLocale: {
      const { payload } = action as AddLocaleAction;
      return {
        ...state,
        locales: [
          ...state.locales.map((item) => ({ ...item, active: false })),
          { ...payload, default: state.locales.length == 0 },
        ],
      };
    }
    case ActionKind.ShowNewLangDialog: {
      const { payload } = action as DisplayLanguageDialogAction;
      return { ...state, showNewLanguageDialog: payload };
    }
    case ActionKind.UpdateSelectedDetails: {
      const { payload } = action as UpdateSelectedDetails;
      return {
        ...state,
        locales: [
          ...state.locales.map((locale) =>
            locale.active ? { ...locale, data: payload } : locale
          ),
        ],
      };
    }
    case ActionKind.UpdateSelectedTitle: {
      const { payload } = action as UpdateSelectedTitle;
      return {
        ...state,
        locales: [
          ...state.locales.map((locale) =>
            locale.active ? { ...locale, title: payload } : locale
          ),
        ],
      };
    }
    case ActionKind.UpdateSelectedSummary: {
      const { payload } = action as UpdateSelectedSummary;
      return {
        ...state,
        locales: [
          ...state.locales.map((locale) =>
            locale.active ? { ...locale, summary: payload } : locale
          ),
        ],
      };
    }
    case ActionKind.SetSelectedLocale: {
      const { payload } = action as SetSelectedLocaleAction;
      return {
        ...state,
        locales: [
          ...state.locales.map((item) =>
            item === payload
              ? { ...item, active: true }
              : { ...item, active: false }
          ),
        ],
      };
    }
    case ActionKind.UpdateSlug: {
      const { payload } = action as UpdateSlugAction;
      return {
        ...state,
        slug: payload.slug,
        slugUpdatedManually: state.slugUpdatedManually || payload.manualUpdate,
      };
    }
    case ActionKind.UpdatePromotionImage: {
      const { payload } = action as UpdatePromotionImageAction;
      return { ...state, promotionImage: payload };
    }
    case ActionKind.UpdatePromotionEnabled: {
      const { payload } = action as PromotionEnabledAction;
      return { ...state, promotionEnabled: payload };
    }
    case ActionKind.DetailsInit: {
      const { payload } = action as DetailsInitAction;
      return {
        showNewLanguageDialog: false,
        slug: payload.slug,
        slugUpdatedManually: true,
        promotionEnabled: payload.enabled,
        promotionImage: payload.image,
        locales: payload.localizations.map((item, index) => ({
          ...item,
          active: index === 0,
          label: getLangForLocale(item.locale),
        })),
      };
    }
    case ActionKind.UpdateSelectedSEOImage: {
      const { payload } = action as UpdateSelectedSEOImageAction;
      return {
        ...state,
        locales: [
          ...state.locales.map((locale) =>
            locale.active ? { ...locale, seoImage: payload } : locale
          ),
        ],
      };
    }
    default:
      throw new Error("Invalid action type");
  }
};
