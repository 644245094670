import React, { useState, useEffect, useMemo } from "react";
import {
  ContentBox,
  Center,
  BgBlock,
  SubSectionHeader,
} from "../../components/StandardComponents";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { MainLayout2 } from "../../components/layouts";
import { ContentItem } from "../../backend-api/types";
import { useQuery } from "react-query";
import { getContentBySlug } from "../../backend-api/user-api";
import { getLocale } from "../../backend-api/contentHelpers";
import { withQueryClient } from "../../utils";
import { graphql } from "gatsby";

interface NewsPageContext {
  slug: string;
  article: ContentItem;
  language: string;
}

const IndexPage: React.FC<{ pageContext: NewsPageContext }> = ({
  pageContext,
}) => {
  const { t } = useTranslation();
  const articleQuery = useQuery(
    ["article", pageContext.slug],
    () => getContentBySlug(pageContext.slug),
    { initialData: pageContext.article, enabled: true }
  );

  const localizedContent = useMemo(
    () =>
      articleQuery.data ? getLocale(articleQuery.data, pageContext.language) : null,
    [articleQuery.data, pageContext.language]
  );

  const getSafeTitle = () => {
    if (articleQuery.data) {
      if (localizedContent) {
        return localizedContent.title;
      }
    }
    return "";
  };

  useEffect(()=>{
    console.log(articleQuery)
  },[articleQuery])

  useEffect(()=>{
    console.log(pageContext)
  },[pageContext])

  useEffect(()=>{
    console.log("HERE HERE HERE")
    console.log(localizedContent)
  },[localizedContent])

  return (
    <>
      <MainLayout2
        pageTitle={getSafeTitle()}
        pageBannerImg={"/assets/general-banner.jpg"}
        pageCrumbMap={[
          { link: "/", name: t("Home") },
          { link: "/news", name: t("News") },
        ]}
      >
        {!localizedContent && (
          <ContentBox>News article is not available.</ContentBox>
        )}
        {localizedContent && (
          <ContentBox>
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{
                __html: localizedContent.content,
              }}
            />
          </ContentBox>
        )}
      </MainLayout2>
    </>
  );
};

export default withQueryClient(IndexPage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
