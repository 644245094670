import React from "react";
import { graphql } from "gatsby";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import GlobalStyle from "../../styles/GlobalStyle";
import {
  ContentBox,
  Center,
  BgBlock,
  SubSectionHeader,
} from "../../components/StandardComponents";

import { MainLayout2 } from "../../components/layouts";
import Card, { CardBox, CardTitleOverlay } from "../../components/card";
import { LalunaDarkColor } from "../../constants";

import { PrivateCarTransportTable } from "../../components/Transportation/PrivateCarTransportTable";

import styled from "styled-components";
import { TransportationDataProvider } from "../../dataProviders/TransportationDataProvider";
import { CarSerivceOptions } from "../../components/Transportation/Types";
import { TourOptions } from "../../dataProviders/ToursDataProvider";
import { ToursDataProvider } from "../../dataProviders/ToursDataProvider";
import { ensureArray } from "../../utils";
import { TourCardDetailsComposite } from "../../components/Tours/TourCardDetails";
import { Modal } from "../../components/modal/Modal";
import { getImage } from "../../../lib/image-resolve";

const TransportOptionsCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 120%;
`;

const IndexPage: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <>
      {console.log(pageContext)}
      <MainLayout2
        pageTitle={t("Transportation & Tours")}
        pageBannerImg={"/assets/general-banner.jpg"}
        pageCrumbMap={[{ link: "/", name: t("Home") }]}
      >
        <ContentBox>
          <Center>
            Under Construction, please contact info@lalunahoian.com for details
            regarding transportation and tours.
          </Center>
        </ContentBox>
        <BgBlock>
          <ContentBox>
            <SubSectionHeader
              title={"Airport Transfer Service"}
              titleColor={"white"}
            >
              {
                "All of us at Laluna Hoi An Riverside Hotel & Spa strive to provide the best possible experience for you while you visit our lovely town of Hoi An and that's why we provide an airport transfer service."
              }
              {
                " Whether you are just landing at our nearby airport in Da nang or preparing to leave for your next adventure, we can provide you safe, reliable, and secure transport to and from the airport."
              }
            </SubSectionHeader>
            <CardBox>
              <Card
                imageUrl={getImage(
                  "/assets/transportation/toyota-vios-e.jpg",
                  "350square"
                )}
                title={"Private Passenger Car"}
                buttonText={t("Book a Transfer")}
                buttonHref={""}
                bottonTextHoverColor={LalunaDarkColor}
              >
                <TransportOptionsCardBody>
                  <div>{"(2 adults, 1 child, and luggage)"}</div>
                  <div>{"$20 USD / Car / Trip*"}</div>
                </TransportOptionsCardBody>
              </Card>
              <Card
                imageUrl={getImage(
                  "/assets/transportation/toyota-fortuner-square.jpg",
                  "350square"
                )}
                title={"Private SUV"}
                buttonText={t("Book a Transfer")}
                buttonHref={""}
                bottonTextHoverColor={LalunaDarkColor}
              >
                <TransportOptionsCardBody>
                  <div>{"(3-4 guests and luggage)"}</div>
                  <div>{"$25 USD / Car / Trip*"}</div>
                </TransportOptionsCardBody>
              </Card>
              <Card
                imageUrl={getImage(
                  "/assets/transportation/hyundai-h350-square.jpg",
                  "350square"
                )}
                title={"Private Passenger Van"}
                buttonText={t("Book a Transfer")}
                buttonHref={""}
                bottonTextHoverColor={LalunaDarkColor}
              >
                <TransportOptionsCardBody>
                  <div>{"(5-10 guests and luggage)"}</div>
                  <div>{"$30 USD / Car / Trip*"}</div>
                </TransportOptionsCardBody>
              </Card>
            </CardBox>
            <Center>
              {"* All prices are include 10% VAT and 5% service charge"}
            </Center>
          </ContentBox>
        </BgBlock>
        <ContentBox>
          <SubSectionHeader
            title={"Private Driver and Transportation"}
            titleColor={"black"}
          >
            {
              "All of us at Laluna Hoi An Riverside Hotel & Spa strive to provide the best possible experience for you while you visit our lovely town of Hoi An and that's why we provide an airport transfer service."
            }
            {
              " Whether you are just landing at our nearby airport in Da nang or preparing to leave for your next adventure, we can provide you safe, reliable, and secure transport to and from the airport."
            }
          </SubSectionHeader>
          <TransportationDataProvider>
            {(privateCarOptions: CarSerivceOptions) => {
              return <PrivateCarTransportTable options={privateCarOptions} />;
            }}
          </TransportationDataProvider>
          <Center>
            {
              "If you would like a quote for a custom itinerary, please email info@lalunahoian.com with the details of your desired itinerary."
            }
          </Center>
        </ContentBox>
        <BgBlock>
          <ContentBox>
            <SubSectionHeader
              title={"Tours"}
              titleColor={"white"}
            ></SubSectionHeader>
            <ToursDataProvider>
              {(tours: TourOptions) => {
                return (
                  <CardBox>
                    {tours.map((tour, index) => {
                      const tourPrices = ensureArray(tour.price);
                      const tourSchedules = ensureArray(tour.tourSchedules);
                      return (
                        <CardTitleOverlay
                          imageUrl={tour.tourImages[0]}
                          title={tour.name}
                          buttonText={"More Details"}
                          buttonHref={`/transportation-and-tours/tour/${tour.id}`}
                        >
                          <TourCardDetailsComposite
                            price={tourPrices[0]}
                            schedules={tourSchedules}
                            minGroup={tour.minGroupSize}
                            maxGroup={tour.maxGroupSize}
                          />
                        </CardTitleOverlay>
                      );
                    })}
                  </CardBox>
                );
              }}
            </ToursDataProvider>
          </ContentBox>
        </BgBlock>
        {pageContext.tourId && <Modal></Modal>}
      </MainLayout2>
    </>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
