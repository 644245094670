import React from "react";
import styled, { css } from "styled-components";
import { Link } from "../StandardComponents";
//import { I18n, I18nTFunction } from "react-i18next";

const ButtonMobileCss = css`
  background-color: #a08036;
  color: white;
  font-family: "Playfair Display", serif;
  font-style: italic;
  border: 1px #1c1c1c solid;
  border-radius: 13px;
  padding: 3px 20px;
  text-shadow: 0px 0px 6px #1c1c1c;
  display: inline-block;
  box-shadow: 2px 2px 3px 0px #1c1c1c;
`;
const ButtonDesktopCss = css`
  ${ButtonMobileCss}
`;

const ButtonLink = styled(Link)`
  @media only screen and (min-width: 660px) {
    ${ButtonDesktopCss}
    & :hover {
      color: ${(props) => props.linkHoverColor};
    }
  }
  @media only screen and (max-width: 660px) {
    ${ButtonMobileCss}
    & :hover {
      color: white /*${(props) => props.linkHoverColor}*/;
    }
  }
`;

const ButtonLinkStandard = styled("a")`
  text-decoration: none ;
  @media only screen and (min-width: 660px) {
    ${ButtonDesktopCss}
    & :hover {
      color: ${(props) => props.linkHoverColor};
    }
  }
  @media only screen and (max-width: 660px) {
    ${ButtonMobileCss}
    & :hover {
      color: white /*${(props) => props.linkHoverColor}*/;
    }
  }
`;

interface ButtonProps {
  href?: string;
  linkHoverColor?: string;
  standardLink?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  href,
  linkHoverColor,
  children,
  standardLink,
}) => {
  if (standardLink) {
    return (
      <ButtonLinkStandard linkHoverColor={linkHoverColor} href={href}>
        {children}
      </ButtonLinkStandard>
    );
  }

  return (
    <ButtonLink to={href} linkHoverColor={linkHoverColor}>
      {children}
    </ButtonLink>
  );
};

export default Button;
