import React, { useMemo } from "react";
import { LocaleEditable } from "./types";
import { TabMenu } from "primereact/tabmenu";
import { PromotionLocalizedView } from "./localizedView";
import styled from "styled-components";

const Separator = styled.div`
  width: 100%;
  height: 5px;
  background-color: #cfcfcf;
  margin: 10px 0;
`;

type ChangeFn = (str: string) => void;
type BlurFn = (locale: LocaleEditable) => void;

interface LocaleTabMenuProps {
  locales: Array<LocaleEditable>;
  setSelectedLocale: (selected: LocaleEditable) => void;
  selectedTitleChange: ChangeFn;
  selectedSummaryChange: ChangeFn;
  selectedDetailsChange: ChangeFn;
  selectedTitleBlur: BlurFn;
  selectedSEOImageChange: ChangeFn;
}

export const LocaleTabMenu: React.FC<LocaleTabMenuProps> = ({
  locales,
  setSelectedLocale,
  selectedTitleChange,
  selectedSummaryChange,
  selectedDetailsChange,
  selectedTitleBlur,
  selectedSEOImageChange,
}) => {
  const getSelectedLocale = () => {
    if (locales && locales.length > 0) {
      const selected = locales.filter((l) => l.active === true);
      if (selected && selected.length > 0) {
        return selected[0];
      }
    }
    return null;
  };

  const selectedLocale = useMemo(() => getSelectedLocale(), [locales]);

  return (
    <>
      <TabMenu
        model={locales}
        activeItem={selectedLocale}
        onTabChange={(evt) => setSelectedLocale(evt.value)}
      ></TabMenu>
      {selectedLocale && (
        <PromotionLocalizedView
          title={selectedLocale.title}
          summary={selectedLocale.summary}
          details={selectedLocale.data}
          titleChange={selectedTitleChange}
          summaryChange={selectedSummaryChange}
          detailsChange={selectedDetailsChange}
          titleBlur={() => selectedTitleBlur(selectedLocale)}
          seoImageChange={selectedSEOImageChange}
          seoImageUrl={selectedLocale.seoImage}
        />
      )}
      <Separator />
    </>
  );
};
