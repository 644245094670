import { Button } from "primereact/button";
import React, { createRef, useEffect, useReducer } from "react";

import styled from "styled-components";
import CropDialog, { CropAreaState } from "./cropDialog";

const Container = styled.div`
  border: 1px solid #dfdfdf;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

interface StyledPlaceHolderProps {
  imageWidth: number;
  imageHeight: number;
}

const PlaceHolder = styled.div<StyledPlaceHolderProps>`
  width: ${(props) => props.imageWidth}px;
  height: ${(props) => props.imageHeight}px;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const ImagePlaceHolder = styled.i.attrs({ className: "pi pi-image" })`
  && {
    font-size: 5em;
  }

  color: grey;
`;

const Toolbar = styled.div`
  border-bottom: 1px solid #dfdfdf;
  padding: 5px;
`;

const StyledImage = styled.img<StyledPlaceHolderProps>`
  width: ${(props) => props.imageWidth}px;
  height: ${(props) => props.imageHeight}px;
`;

enum ActionKind {
  DisplayImage = "DisplayImage",
  CropImage = "CropImage",
}

type ImageToCropAction = {
  type: ActionKind.CropImage;
  payload: File | null;
};

type DisplayImageAction = {
  type: ActionKind.DisplayImage;
  payload: string | null;
};

type Action = ImageToCropAction | DisplayImageAction;

interface StateInterface {
  imageToCrop: File | null;
  displayImageUrl: string | null;
}

const stateInit = (init: string | null | undefined) => {
  return { imageToCrop: null, displayImageUrl: init || null };
};

const reducer = (state: StateInterface, action: Action): StateInterface => {
  const { type } = action;
  switch (type) {
    case ActionKind.CropImage: {
      const { payload } = action as ImageToCropAction;
      return { ...state, imageToCrop: payload };
    }
    case ActionKind.DisplayImage: {
      const { payload } = action as DisplayImageAction;
      return { ...state, displayImageUrl: payload };
    }
    default:
      throw new Error("Invalid action type");
  }
};

type ChangeImageFn = (imgUrl: string) => void;

type ChangeCropFn = (cropArea: CropAreaState, imgUrl: string) => void;

interface ImageUploadProps {
  imageWidth: number;
  imageHeight: number;
  onChangeImage?: ChangeImageFn;
  onChangeCropSelected?: ChangeCropFn;
  initialImageUrl?: string | null;
}

export const ImageUpload: React.FC<ImageUploadProps> = (props) => {
  const [state, dispatch] = useReducer<
    React.Reducer<StateInterface, Action>,
    string | null | undefined
  >(reducer, props.initialImageUrl, stateInit);
  const imageUploadRef = createRef<HTMLInputElement>();

  useEffect(() => {
    dispatch({
      type: ActionKind.DisplayImage,
      payload: props.initialImageUrl || null,
    });
  }, [props.initialImageUrl]);

  return (
    <>
      <Container>
        <Toolbar>
          <Button
            icon="pi pi-images"
            className="p-button-rounded p-button-outlined"
            onClick={() => imageUploadRef?.current?.click()}
          />
        </Toolbar>
        <ImageContainer>
          {!state.displayImageUrl && (
            <PlaceHolder
              imageWidth={props.imageWidth}
              imageHeight={props.imageHeight}
            >
              <ImagePlaceHolder />
            </PlaceHolder>
          )}
          {state.displayImageUrl && (
            <StyledImage
              src={state.displayImageUrl}
              imageWidth={props.imageWidth}
              imageHeight={props.imageHeight}
            />
          )}
        </ImageContainer>
      </Container>
      {state.imageToCrop && (
        <CropDialog
          imagefile={state.imageToCrop}
          onCancel={() => {
            dispatch({ type: ActionKind.CropImage, payload: null });
          }}
          onCanvasCrop={(base64image) => {
            dispatch({ type: ActionKind.CropImage, payload: null });
            dispatch({
              type: ActionKind.DisplayImage,
              payload: base64image,
            });
            props.onChangeImage && props.onChangeImage(base64image);
          }}
          onCropArea={(cropArea, base64Input) => {
            props.onChangeCropSelected &&
              props.onChangeCropSelected(cropArea, base64Input);
          }}
          outputHeight={props.imageHeight}
          outputWidth={props.imageWidth}
          aspect={props.imageWidth / props.imageHeight}
        />
      )}
      <input
        className={"hidden"}
        ref={imageUploadRef}
        type="file"
        onChange={(e) => {
          e.target.files &&
            e.target.files.length >= 1 &&
            dispatch({
              type: ActionKind.CropImage,
              payload: e.target.files[0],
            });
          e.target.value = "";
        }}
        accept=".jpg,.png"
      />
    </>
  );
};
