import React from "react";
import { graphql } from "gatsby";
import { RoomLayout } from "../../../components/layouts";

const IndexPage: React.FC = () => <RoomLayout RoomType={"superior"} />;

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
