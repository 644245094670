import React from "react";
import styled, { css } from "styled-components";
import { getImage } from "../../../lib/image-resolve";
import {
  ContentBox,
  BgBlock,
  FeatureBlock,
  SubSectionHeader,
  VerticalFlex,
  HeaderWithDivider,
  Center,
  TextCenter,
  SubSectionTitle,
} from "../../components/StandardComponents";
import { MenuEntry } from "./MenuEntry";
import { MenuSectionDef } from "./Types";

const FeaturedContentWrapper = styled.div`
  margin: 20px;
`;

interface SectionHeaderProps {
  title?: string;
  titleColor?: string;
  children?: React.ReactNode;
}

export const MenuSectionContainer: React.FC<SectionHeaderProps> = ({
  title,
  children,
  titleColor,
}) => {
  return (
    <VerticalFlex>
      {title && (
        <HeaderWithDivider color={titleColor}>
          <Center>
            <TextCenter>
              <SubSectionTitle>{title}</SubSectionTitle>
            </TextCenter>
          </Center>
        </HeaderWithDivider>
      )}
      {children && <>{children}</>}
    </VerticalFlex>
  );
};

interface MenuSectionInterface {
  section: MenuSectionDef;
  dark: boolean;
}

export const MenuSection: React.FC<MenuSectionInterface> = ({
  section,
  dark,
}) => {
  if (dark) {
    return (
      <BgBlock>
        <FeatureBlock
          contentLeft={false}
          images={section.images.map((image) => getImage(image, "350square"))}
        >
          <FeaturedContentWrapper>
            <MenuSectionContainer title={section.name}>
              {section.items.map((entry, index) => (
                <MenuEntry
                  key={index}
                  entryName={entry.name}
                  entryOptions={entry.options}
                  entryDescription={entry.description}
                ></MenuEntry>
              ))}
            </MenuSectionContainer>
          </FeaturedContentWrapper>
        </FeatureBlock>
      </BgBlock>
    );
  }
  return (
    <ContentBox>
      <FeatureBlock
        contentLeft={true}
        images={section.images.map((image) => getImage(image, "350square"))}
      >
        <FeaturedContentWrapper>
          <MenuSectionContainer title={section.name}>
            {section.items.map((entry, index) => (
              <MenuEntry
                key={index}
                entryName={entry.name}
                entryOptions={entry.options}
                entryDescription={entry.description}
              ></MenuEntry>
            ))}
          </MenuSectionContainer>
        </FeaturedContentWrapper>
      </FeatureBlock>
    </ContentBox>
  );
};
