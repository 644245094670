import { Promotion, PromotionLocalization } from "../../backend-api/types";

export const getLocale = (
  promotion: Promotion,
  locale: string
): PromotionLocalization | null => {
  if (promotion && promotion.localizations) {
    let defaultLocale = null;
    let specifed = null;
    promotion.localizations.forEach((item) => {
      if (item.default) {
        defaultLocale = item;
      }

      if (item.locale === locale) {
        specifed = item;
      }
    });
    return specifed || defaultLocale;
  }
  return null;
};
