import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createContentItem,
  deleteContentItem,
  getContentAll,
  getContentItem,
  updateContentItem,
} from "../../backend-api/admin-api";
import { ContentItem } from "../../backend-api/types";
import { UnauthorizedError } from "../../backend-api/api";

export type SuccessFunc = () => void;
export type ErrorFunc = (error: unknown) => void;
export interface ErrorSuccessOptions {
  onSuccess?: SuccessFunc;
  onError?: ErrorFunc;
}

export const useCreateUpdateDeleteContent = () => {
  const queryClient = useQueryClient();

  const create = useMutation((item: ContentItem) => createContentItem(item), {
    onSuccess: () => {
      queryClient.invalidateQueries("content");
    },
  });
  const update = useMutation(
    (item: ContentItem) =>
      item.id ? updateContentItem(item.id, item) : Promise.resolve(null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("content");
      },
    }
  );

  const deleteMutation = useMutation((id: string) => deleteContentItem(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("content");
    },
  });

  return {
    delete: deleteMutation,
    upsert: (item: ContentItem, options?: ErrorSuccessOptions) => {
      const { onSuccess, onError } = options ?? {};

      if (item.id) {
        update.mutate(item, { onSuccess, onError });
      } else {
        create.mutate(item, { onSuccess, onError });
      }
    },
  };
};

export const useContent = (options?: ErrorSuccessOptions) => {
  const allContent = useQuery("content", getContentAll, {
    onError: options?.onError,
    onSuccess: options?.onSuccess,
    retry: (failureCount, error) => {
      if (error instanceof UnauthorizedError) {
        return false;
      }
      if (failureCount < 3) {
        return true;
      }
      return false;
    },
  });
  const mutations = useCreateUpdateDeleteContent();

  const data = null;

  return {
    delete: mutations.delete.mutate,
    get: {
      data: allContent.data,
    },
  };
};

export const useContentItem = (id: string | null | undefined) => {
  const create = useCreateUpdateDeleteContent();
  const contentItem = useQuery(
    ["content", id],
    () => (id ? getContentItem(id) : null),
    {
      enabled: id !== null && id !== "new",
    }
  );

  return {
    get: {
      data: contentItem.data,
      isError: contentItem.isError,
      isLoading: contentItem.isLoading,
      isSuccess: contentItem.isSuccess,
    },
    upsert: create.upsert,
  };
};
