import React, { ReactElement } from "react";
import { ReactComponentLike } from "prop-types";
import { MenuDefArray } from "../components/PurchaseMenu/Types";

import SpaData from "./SpaMenu.js"

const SpaServicesDataProvider: ReactComponentLike = ({
  children,
}: {
  children: (menuData: MenuDefArray) => ReactElement;
}) => {
  console.log(SpaData)
  const menuData: MenuDefArray = [SpaData]/*[
    {
      name: "Spa",
      sections: [
        {
          name: "Body Treatment",
          images: [
            "/assets/spa/spa-services/body_treatment_section_1_1.jpg",
            "/assets/spa/spa-services/body_treatment_section_1_2.jpg",
          ],
          items: [
            {
              name: "Energy Balance",
              options: [
                { description: "60 Minutes", price: 610000 },
                { description: "75 Minutes", price: 690000 },
                { description: "90 Minutes", price: 760000 },
              ],
              description:
                "A light to medium pressure massage with soft and slow movement.",
            },
            {
              name: "Shiatsu Therapy",
              options: [
                { description: "60 Minutes", price: 610000 },
                { description: "75 Minutes", price: 690000 },
                { description: "90 Minutes", price: 760000 },
              ],
              description:
                "Shiatsu therapy is a medium pressure massage applied with the fingers and palms at the acupuncture points of the body to increase blood circulation.",
            },
            {
              name: "Swedish Massage",
              options: [
                { description: "60 Minutes", price: 610000 },
                { description: "75 Minutes", price: 690000 },
                { description: "90 Minutes", price: 760000 },
              ],
              description:
                "This is a popular massage treatment with kneading and hacking techniques using medium to firm pressure to release stiff muscles and improve circulation.",
            },
          ],
        },
        {
          images: [
            "/assets/spa/spa-services/body_treatment_section_2_1.jpg",
            "/assets/spa/spa-services/body_treatment_section_2_2_.jpg",
          ],
          items: [
            {
              name: "Laluna Signature Therapy",
              options: [
                { description: "60 Minutes", price: 630000 },
                { description: "75 Minutes", price: 710000 },
                { description: "90 Minutes", price: 780000 },
              ],
              description:
                "A combination of deep tissue massage and traditional massage techniques where pressure level is tailored to the individual's needs.",
            },
            {
              name: "Aroma Four Hands Therapy",
              options: [
                { description: "60 Minutes", price: 970000 },
                { description: "75 Minutes", price: 1050000 },
              ],
              description:
                "A harmonious combination of essential oils and European massage techniques where two therapists work to relieve muscle tension.",
            },
            {
              name: "Healing Stone Therapy",
              options: [
                { description: "75 Minutes", price: 750000 },
                { description: "90 Minutes", price: 830000 },
              ],
              description:
                "Heated basalt stones are placed in specific locations of the individual's body to aid in deep tissue and muscle relaxation.",
            },
          ],
        },
        {
          images: [
            "/assets/spa/spa-services/body_treatment_section_3_1.jpg",
            "/assets/spa/spa-services/body_treatment_section_3_2.jpg",
          ],
          items: [
            {
              name: "Hot Herbal Therapy",
              options: [
                { description: "60 Minutes", price: 650000 },
                { description: "75 Minutes", price: 750000 },
                { description: "90 Minutes", price: 830000 },
              ],
              description:
                "The massage therapist with apply heated essential oils to warm the body and expel the pores. The headed essential oils relax the individual's muscles and relieve pain.",
            },
            {
              name: "Child Massage",
              options: [
                { description: "30 Minutes", price: 290000 },
                { description: "45 Minutes", price: 420000 },
                { description: "60 Minutes", price: 520000 },
              ],
              description:
                "A massage treat for your child to relax and release stiff muscles.",
            },
            {
              name: "Pregnancy Massage",
              options: [{ description: "60 Minutes", price: 620000 }],
              description:
                "With this treatment, you can increase relaxation, reduce stress, and improve blood circulation to help with various symptoms caused by pregnancy including insomnia, edema, headache, and high blood pressure.",
            },
          ],
        },
        {
          name: "Express Treatment",
          images: [
            "/assets/spa/spa-services/express_treatment_1.jpg",
            "/assets/spa/spa-services/express_treatment_2.jpg",
            "/assets/spa/spa-services/express_treatment_3.jpg",
            "/assets/spa/spa-services/express_treatment_4.jpg",
          ],
          items: [
            {
              name: "Indian Head & Hands Massage",
              options: [
                { description: "30 Minutes", price: 380000 },
                { description: "45 Minutes", price: 470000 },
              ],
            },
            {
              name: "Back, Neck & Shoulder  Therapy",
              options: [
                { description: "30 Minutes", price: 400000 },
                { description: "60 Minutes", price: 610000 },
              ],
            },
            {
              name: "Foot Reflexology",
              options: [
                { description: "30 Minutes", price: 390000 },
                { description: "45 Minutes", price: 480000 },
              ],
            },
            {
              name: "Foot Scrub & Foot Massage",
              options: [{ description: "60 Minutes", price: 590000 }],
            },
            {
              name: "Eye Relaxing Therapy",
              options: [
                { description: "30 Minutes", price: 490000 },
                { description: "45 Minutes", price: 560000 },
              ],
            },
            {
              name: "Foot Paraffin Wax Therapy",
              options: [
                { description: "50 Minutes", price: 550000 },
                { description: "60 Minutes", price: 590000 },
              ],
            },
            {
              name: "Hand Paraffin Wax Therapy",
              options: [
                { description: "50 Minutes", price: 550000 },
                { description: "60 Minutes", price: 590000 },
              ],
            },
          ],
        },
        {
          name: "Body Exfoliate",
          images: [
            "/assets/spa/spa-services/body_exfoliate_1.jpg",
            "/assets/spa/spa-services/body_exfoliate_2.jpg",
          ],
          items: [
            {
              name: "Aromatic Salt Scrub",
              options: [{ description: "45 Minutes", price: 550000 }],
            },
            {
              name: "Traditional Scrub",
              options: [{ description: "60 Minutes", price: 570000 }],
            },
            {
              name: "Rice & Milk Scrub",
              options: [{ description: "45 Minutes", price: 550000 }],
            },
            {
              name: "Oriental Honey, Milk & Black Sesame Scrub",
              options: [{ description: "45 Minutes", price: 550000 }],
            },
            {
              name: "Green Tea Scrub",
              options: [{ description: "45 Minutes", price: 550000 }],
            },
            {
              name: "Honey & Coffee Scrub",
              options: [{ description: "45 Minutes", price: 550000 }],
            },
          ],
        },
        {
          name: "Facial Options",
          images: [
            "/assets/spa/spa-services/facial_1.jpg",
            "/assets/spa/spa-services/facial_2.jpg",
            "/assets/spa/spa-services/facial_3.jpg",
          ],
          items: [
            {
              name: "Purifying",
              options: [{ description: "60 Minutes", price: 670000 }],
            },
            {
              name: "Revitalizing",
              options: [{ description: "60 Minutes", price: 670000 }],
            },
            {
              name: "Recovery & Anti-ageing",
              options: [{ description: "70 Minutes", price: 710000 }],
            },
            {
              name: "Purifying with Oatmeal Mask",
              options: [{ description: "60 Minutes", price: 670000 }],
            },
            {
              name: "Detoxifying with Green Tea Mask",
              options: [{ description: "60 Minutes", price: 670000 }],
            },
            {
              name: "Sensitive Skin",
              options: [{ description: "70 Minutes", price: 710000 }],
            },
          ],
        },
        {
          name: "Packages",
          images: [
            "/assets/spa/spa-services/packages_section_1_1.jpg",
            "/assets/spa/spa-services/packages_section_1_2.jpg",
            "/assets/spa/spa-services/packages_section_1_3.jpg",
          ],
          items: [
            {
              name: "Laluna Refreshment",
              options: [{ description: "120 Minutes", price: 1080000 }],
              description: ["60 Minute Basic Body Massage", "60 Minute Facial"],
            },
            {
              name: "Uplifting",
              options: [{ description: "195 Minutes", price: 1700000 }],
              description: [
                "45 Minute Rice & Milk Body Scrub",
                "90 Minute Basic Body Massage",
                "60 Minute Facial",
              ],
            },
            {
              name: "Oriental Magic",
              options: [{ description: "180 Minutes", price: 1550000 }],
              description: [
                "60 Minute Traditional Body Scrub",
                "60 Minute Dead Sea Mud Wrap",
                "60 Minute Basic Body Massage",
              ],
            },
            {
              name: "Loving Touch",
              options: [{ description: "165 Minutes", price: 1530000 }],
              description: [
                "45 Minute Body Scub",
                "60 Minute Cucumber & Aloe Skin Treatment",
                "60 Minute Basic Body Massage",
              ],
            },
          ],
        },
        {
          images: [
            "/assets/spa/spa-services/packages_section_2_1.jpg",
            "/assets/spa/spa-services/packages_section_2_2_.jpg",
            "/assets/spa/spa-services/packages_section_2_3.jpg",
          ],
          items: [
            {
              name: "Renew Your Skin",
              options: [{ description: "105 Minutes", price: 1030000 }],
              description: [
                "45 Minute Black Sesame & Milk Scrub",
                "60 Minute Basic Body Massage",
              ],
            },
            {
              name: "Care For Men",
              options: [{ description: "180 Minutes", price: 1600000 }],
              description: [
                "60 Minute Back, Neck & Shoulder Massage",
                "60 Minute Foot Scrub & Foot Massage",
                "60 Minute Facial",
              ],
            },
            {
              name: "Hand Care",
              options: [{ description: "120 Minutes", price: 840000 }],
              description: [
                "60 Minute Hand Paraffin Wax Treatment",
                "60 Minute Laluna Manicure",
              ],
            },
          ],
        },
        {
          images: [
            "/assets/spa/spa-services/packages_section_3_1.jpg",
            "/assets/spa/spa-services/packages_section_3_2.jpg",
          ],
          items: [
            {
              name: "Foot Care",
              options: [{ description: "120 Minutes", price: 840000 }],
              description: [
                "60 Minute Foot Paraffin Wax Treatment",
                "60 Minute Laluna Pedicure",
              ],
            },
            {
              name: "Care For Pregnancy",
              options: [{ description: "150 Minutes", price: 1490000 }],
              description: [
                "30 Minute Eye Relaxing Therapy",
                "60 Minute Vietnamese Herbs & Dead Sea Mud Skin Treatment",
                "60 Minute Pregnancy Massage",
              ],
            },
            {
              name: "Honeymoon Couples Massage",
              options: [{ description: "210 Minutes", price: 3250000 }],
              description: [
                "60 Minute Traditional Body Scrub",
                "60 Minute Basic Body Massage",
                "60 Minute Facial",
                "30 Minute Aroma Sauna & Steam Bath",
              ],
            },
          ],
        },
        {
          name: "Manicure Nail Services",
          images: [
            "/assets/spa/spa-services/manicure_1.jpg",
            "/assets/spa/spa-services/manicure_2.jpg",
            "/assets/spa/spa-services/manicure_3.jpg",
          ],
          items: [
            {
              name: "Cuticle Work Without Nail Color",
              options: [{ description: "20 Minutes", price: 170000 }],
            },
            {
              name: "Nail Lacquer",
              options: [{ description: "20 Minutes", price: 150000 }],
            },
            {
              name: "Basic Manicure",
              options: [{ description: "40 Minutes", price: 250000 }],
            },
            {
              name: "Laluna Manicure",
              options: [{ description: "60 Minutes", price: 370000 }],
            },
            {
              name: "Paraffin Wax Manicure",
              options: [{ description: "60 Minutes", price: 470000 }],
            },
            {
              name: "Manicure for Children",
              options: [{ description: "20 Minutes", price: 110000 }],
            },
          ],
        },
        {
          name: "Pedicure Nail Services",
          images: [
            "/assets/spa/spa-services/pedicure_1.jpg",
            "/assets/spa/spa-services/pedicure_2.jpg",
            "/assets/spa/spa-services/pedicure_3.jpg",
          ],
          items: [
            {
              name: "Cuticle Work Without Nail Color",
              options: [{ description: "20 Minutes", price: 170000 }],
            },
            {
              name: "Nail Lacquer",
              options: [{ description: "20 Minutes", price: 150000 }],
            },
            {
              name: "Basic Pedicure",
              options: [{ description: "40 Minutes", price: 250000 }],
            },
            {
              name: "Laluna Pedicure",
              options: [{ description: "60 Minutes", price: 370000 }],
            },
            {
              name: "Paraffin Wax Pedicure",
              options: [{ description: "60 Minutes", price: 470000 }],
            },
            {
              name: "Shellac Removal",
              options: [{ description: "15-30 Minutes", price: 170000 }],
            },
            {
              name: "Pedicure for Children",
              options: [{ description: "20 Minutes", price: 110000 }],
            },
            {
              name: "Heel Scrub",
              options: [{ description: "40 Minutes", price: 200000 }],
            },
          ],
        },
        {
          name: "Waxing Services",
          images: [
            "/assets/spa/spa-services/waxing_1.jpg",
            "/assets/spa/spa-services/waxing_2.jpg",
          ],
          items: [
            {
              name: "Upper Lip",
              options: [{ description: "15 Minutes", price: 150000 }],
            },
            {
              name: "Arms",
              options: [{ description: "30 Minutes", price: 450000 }],
            },
            {
              name: "Under Arms",
              options: [{ description: "15 Minutes", price: 250000 }],
            },
            {
              name: "Half Legs",
              options: [{ description: "30 Minutes", price: 420000 }],
            },
            {
              name: "Full Legs",
              options: [{ description: "45-60 Minutes", price: 610000 }],
            },
            {
              name: "Back",
              options: [{ description: "45 Minutes", price: 590000 }],
            },
            {
              name: "Chin",
              options: [{ description: "15 Minutes", price: 150000 }],
            },
          ],
        },
      ],
    },
  ];*/

  return children && children(menuData);
};

export default SpaServicesDataProvider;
