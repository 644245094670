import Data from "../../static/asset-manifest.json";

//console.log(Data);

const getImage: (name: string, style?: string) => string = (name, style) => {
  for (let item of Data) {
    if (item.file.toLowerCase() === name.toLowerCase()) {
      let def = null;
      for (let suffix of item.suffix) {
        if (style && suffix.key === style) {
          return `${item.file}${suffix.suffix}`;
        } else if (suffix.key === "default") {
          def = `${item.file}${suffix.suffix}`;
        }
      }

      return def || `${item.file}`;
    }
  }
  return "";
};

export { getImage };
