export const editorConfiguration = (url: string) => ({
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "alignment",
      "indent",
      "outdent",
      "|",
      "imageUpload",
      "insertTable",
      "mediaEmbed",
      "blockQuote",
      "code",
      "codeBlock",
      "imageInsert",
      "undo",
      "redo",
    ],
  },
  language: "en",
  image: {
    styles: ["alignLeft", "alignCenter", "alignRight"],
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageTextAlternative",
      /*"imageStyle:full", "imageStyle:side",*/ "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
      "|",
      "imageResize",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  simpleUpload: {
    uploadUrl: url,

    // Enable the XMLHttpRequest.withCredentials property.
    withCredentials: true,
  },
});
