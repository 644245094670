import React from "react";
import styled from "styled-components";

const ModalContainer = styled.div`
  top: 0;
  position: absolute;
  background-color: red;
  z-index: 9999;
`;

export const Modal: React.FC = () => {
  return <ModalContainer>BE HAPPY!</ModalContainer>;
};
