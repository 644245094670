import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
//import useIntersect from "../../useIntersect";
//import { I18n, I18nTFunction } from "react-i18next";
import { useInView } from "react-intersection-observer";

const ImageContainerMobileCss = css`
  background-color: #1c1c1c;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
const ImageContainerDesktopCss = css`
  ${ImageContainerMobileCss}
`;

const ImageContainer = styled.div<ImageProps>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  //background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");

  background-image: url("${(props) => `${props.image}`}");

  @media only screen and (min-width: 660px) {
    ${ImageContainerDesktopCss}
  }
  @media only screen and (max-width: 660px) {
    ${ImageContainerMobileCss}
  }
`;

interface ImageProps {
  width: Number;
  height: Number;
  image: string;
}

const Image: React.FC<ImageProps> = ({ width, height, image }) => {
  const [displayImage, setDisplayImage] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
  );
  const [loaded, setLoaded] = useState(false);
  //const [ref, entry] = useIntersect({threshold:  [0]})
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const loadImage = () => {
    if (!loaded && image && inView) {
      setLoaded(true);
      setDisplayImage(image);
      //setLoaded(true);
      console.log(`image loaded [${image}]`);
    }
  };

  loadImage();

  useEffect(() => {
    console.log(`card image mounted [${image}]`);
    setLoaded(false);
    return () => console.log(`card image unmounted [${image}]`);
  }, [image]);
  return (
    <ImageContainer
      width={width}
      height={height}
      image={displayImage}
      ref={ref}
    ></ImageContainer>
  );
};

export default Image;
