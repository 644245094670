export interface PromotionLocalization {
  locale: string;
  title: string;
  summary: string;
  data: string;
  default: boolean;
  seoImage: string;
}

export interface Promotion {
  id?: string;
  slug: string;
  localizations: Array<PromotionLocalization>;
  enabled: boolean;
  image: string;
  createdAt?: string;
  updateAt?: string;
}

export enum ContactUsFieldType {
  message = "message",
  datatime = "datetime",
  text = "text",
  textarea = "textarea",
  date = "date",
  checkbox = "checkbox",
  option = "option",
}
export interface ContactUsField {
  type: ContactUsFieldType;
  label: string;
  options?: Array<string>;
  required?: boolean;
}
export interface ContactUsOption {
  label: string;
  value: string;
  fields: Array<ContactUsField>;
}

export type ContactUsOptions = Array<ContactUsOption>;

export interface ContentLocalization {
  title: string;
  slug: string;
  content: string;
  locale: string;
  default: boolean;
  summary: string;
  seoImage: string;
  image: string;
}
export enum ContentItemState {
  draft = "draft",
  published = "published",
  deleted = "deleted",
}

export enum ContentItemType {
  article = "article",
  privateTour = 'private_tour'
}
export interface ContentItem {
  id?: string;
  publishState: ContentItemState;
  publishDate: Readonly<string | null>;
  contentType: ContentItemType;
  localizations: Array<ContentLocalization>;
  createdAt?: string;
  updateAt?: string;
}
