import styled from "styled-components";
import React from "react";
import { PriceOptions } from "./Types";

const RoutePriceTitle = styled.div`
  background-color: ${(props) => props.theme.darkColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  color: white;
  border: 1px solid ${(props) => props.theme.darkColor};

  font-weight: 400;
  padding: 3px 0px;
`;

const RoutePrice = styled.div`
  //background-color: blue;
  padding: 8px 15px;
  font-size: 125%;
  border: 1px solid ${(props) => props.theme.darkColor};
  border-left: none;
  min-width: 80px;
  display: flex;
  justify-content: center;
`;

const RoutePriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:first-child > ${RoutePrice} {
    border-left: 1px solid ${(props) => props.theme.darkColor};
  }
`;
const RoutePrices = styled.div`
  display: flex;
  //background-color: pink;
  padding: 20px;
  justify-content: center;
  align-items: center;
  //flex: 1;
`;

const RoutePriceComposite: React.FC<{ title: string }> = ({
  title,
  children,
}) => {
  return (
    <RoutePriceContainer>
      <RoutePriceTitle>{title}</RoutePriceTitle>
      <RoutePrice>{children}</RoutePrice>
    </RoutePriceContainer>
  );
};

export const PriceDisplay: React.FC<{ prices: PriceOptions }> = ({
  prices,
}) => (
  <RoutePrices>
    {prices &&
      prices.map((priceOption, index) => (
        <RoutePriceComposite key={index} title={priceOption.title}>
          {priceOption.price}
        </RoutePriceComposite>
      ))}
  </RoutePrices>
);
