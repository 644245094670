import React from "react";
import styled, { css } from "styled-components";
import Image from "./image";
import CardTitle from "./cardTitle";
import Button from "./CardButton";
import { rgba } from "polished";
//import { I18n, I18nTFunction } from "react-i18next";

const CardContainerMobileCss = css`
  width: 300px;
  height: 450px;
  border: 1px solid #a08036;
  box-shadow: 7px 7px 12px #1c1c1c;
  background-color: white;
  margin: 15px;
  display: flex;
  flex-direction: column;
  //background-color: blue;
`;
const CardContainerDesktopCss = css`
  ${CardContainerMobileCss}
`;

const CardContainer = styled.div`
  @media only screen and (min-width: 660px) {
    ${CardContainerDesktopCss}
  }
  @media only screen and (max-width: 660px) {
    ${CardContainerMobileCss}
  }
`;

const ContentContainer = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  //background-color: red;
  flex: 1;
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex: 1;
  //background-color: Green;
`;

const ButtonContainer = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
`;

interface CardProps {
  imageUrl: string;
  title?: string;
  buttonHref?: string;
  buttonText?: string;
  bottonTextHoverColor?: string;
}

const Card: React.FC<CardProps> = ({
  imageUrl,
  title,
  buttonHref,
  buttonText,
  bottonTextHoverColor,
  children,
}) => {
  return (
    <CardContainer>
      <Image width={298} height={238} image={imageUrl} />
      <ContentContainer>
        {title && <CardTitle>{title}</CardTitle>}
        <ChildrenContainer>{children}</ChildrenContainer>
        <ButtonContainer>
          {buttonText && (
            <Button href={buttonHref} linkHoverColor={bottonTextHoverColor}>
              {buttonText}
            </Button>
          )}
        </ButtonContainer>
      </ContentContainer>
    </CardContainer>
  );
};

export default Card;

const TitleImageContainer = styled.div`
  position: relative;
`;

const TitleCotainer = styled.div`
  position: absolute;
  bottom: 0px;
  padding: 5px;
  background-color: ${(props) => rgba(props.theme.darkColor, 0.8)};
  color: white;
  width: 100%;
  & > div {
    border-bottom: none;
  }
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardTitleOverlay: React.FC<CardProps> = ({
  imageUrl,
  title,
  buttonHref,
  buttonText,
  bottonTextHoverColor,
  children,
}) => {
  return (
    <CardContainer>
      <TitleImageContainer>
        <Image width={298} height={238} image={imageUrl} />
        {title && (
          <TitleCotainer>
            <CardTitle>{title}</CardTitle>
          </TitleCotainer>
        )}
      </TitleImageContainer>

      <ContentContainer>
        <ChildrenContainer>{children}</ChildrenContainer>
        <ButtonContainer>
          {buttonText && (
            <Button href={buttonHref} linkHoverColor={bottonTextHoverColor}>
              {buttonText}
            </Button>
          )}
        </ButtonContainer>
      </ContentContainer>
    </CardContainer>
  );
};

export const CardBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: black;
`;

export { Button };
