import React, { useState } from "react";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { RouteComponentProps } from "@reach/router";

export interface LoginComponentProps extends RouteComponentProps {
  submit: ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => void;
}

const Login = (props: LoginComponentProps) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submit = () => {
    props.submit({ username, password });
  };

  return (
    <div className="flex flex-col flex-grow h-full bg-gray-800 justify-center items-center">
      <div className="border-solid border border-gray-500 rounded-2xl bg-blue-900 overflow-hidden shadow-2xl">
        <div className="bg-yellow-400 flex justify-center">
          <h3 className="text-xl font-roboto">Admin Login</h3>
        </div>
        <div className="p-10">
          <div className="mt-6 mb-6">
            <span className="p-float-label">
              <InputText
                id={"username"}
                value={username}
                onChange={(e) =>
                  setUsername((e.target as HTMLInputElement).value)
                }
                className="w-48"
              ></InputText>
              <label htmlFor={`username`}>Username</label>
            </span>
          </div>
          <div className="mt-6 mb-6">
            <span className="p-float-label">
              <Password
                id={"password"}
                value={password}
                onChange={(e) =>
                  setPassword((e.target as HTMLInputElement).value)
                }
                feedback={false}
                inputClassName="w-48"
                onKeyDown={(e) => {
                  console.log("keydown");
                  if (e.key === "Enter") {
                    submit();
                  }
                }}
              ></Password>
              <label htmlFor={`password`}>Password</label>
            </span>
          </div>
          <div className="flex justify-center">
            <Button label={"Login"} onClick={submit}></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
