const API_HOST = () => process.env.GATSBY_LALUNA_API_HOST;

const API_PATH = "api/1";

class UnauthorizedError extends Error {
  constructor() {
    super("You are not authorized to access this resource");
    this.name = "UnauthorizedError";
  }
}

class BadRequestError extends Error {
  errorDetails = null;
  constructor(error) {
    super("Bad Request");
    this.errorDetails = error;
    this.name = "BadRequestError";
  }
}

const responseCodeFilter = (res) => {
  if (res.status < 200 || res.status >= 400) {
    switch (res.status) {
      case 401:
        throw new UnauthorizedError();
      case 400:
        throw new BadRequestError(res.json());
      default:
        throw new Error(`There was an error fetching... code [${res.status}]`);
    }
  }
  return res;
};

const apiRequest = async (method, url, body, options) => {
  let fetchFn = null;
  if (typeof window !== "undefined" && window.fetch) {
    fetchFn = window.fetch;
  } else {
    fetchFn = require("node-fetch");
  }
  let bodyString = null;
  if (body) {
    bodyString = JSON.stringify(body);
  }

  let headers = { "Content-Type": "application/json" };
  if (options?.headers) {
    headers = { ...headers, ...options.headers };
  }

  if (API_HOST()) {
    return await (
      await fetchFn(`${API_HOST()}/${url}`, {
        method: method,
        credentials: "include",
        headers,
        body: bodyString,
      }).then(responseCodeFilter)
    ).json();
  } else {
    console.log(
      `Error: API HOST is not specified [${API_HOST()}] ${
        process.env.GATSBY_LALUNA_API_HOST
      }`
    );
    throw new Error("API HOST IS NOT SPECIFIED!!");
  }
};

const getAllResource = async (resource, options) => {
  return await apiRequest("GET", `${API_PATH}/${resource}`, options);
};

const getResource = async (resource, id, options) => {
  return await apiRequest("GET", `${API_PATH}/${resource}/${id}`, options);
};

const createResource = async (resource, body, options) => {
  return await apiRequest("POST", `${API_PATH}/${resource}`, body, options);
};

const updateResource = async (resource, id, body, options) => {
  return await apiRequest(
    "PUT",
    `${API_PATH}/${resource}/${id}`,
    body,
    options
  );
};

const deleteResource = async (resource, id, options) => {
  return await apiRequest("DELETE", `${API_PATH}/${resource}/${id}`, options);
};

const resourceCustomFunction = async (
  method,
  resource,
  func,
  body,
  options
) => {
  return await apiRequest(
    method,
    `${API_PATH}/${resource}/${func}`,
    body,
    options
  );
};

const login = (user, pass) => {
  return apiRequest("POST", `api/1/auth`, {
    username: user,
    password: pass,
  }).then((json) => json.success);
};

//const imageUploadUrl = `${API_HOST()}/${API_PATH}/upload`;

/*module.exports = {
  apiRequest,
  getAllResource,
  getResource,
  createResource,
  updateResource,
  deleteResource,
  resourceCustomFunction,
  imageUploadUrl,
};*/

module.exports = {
  login,
  resourceCustomFunction,
  deleteResource,
  updateResource,
  createResource,
  getResource,
  getAllResource,
  apiRequest,
  responseCodeFilter,
  API_HOST,
  API_PATH,
  BadRequestError,
  UnauthorizedError,
};
