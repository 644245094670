import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";
import { Role, User } from "../../backend-api/admin-api";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Boolean } from "aws-sdk/clients/apigateway";
import { Checkbox, CheckboxProps } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import PasswordChangeDialog from "./passwordChangeDialog";

/*interface UpdateUser {
  username: string | null;
  email: string | null;
  enabled: string | null;
  roles: string | null;
}*/

interface UserEditorProps {
  onSave: (user: User) => void;
  onClose: () => void;
  visible: boolean;
  user: User | null;
}

const UserEditor = (props: UserEditorProps) => {
  const [user, setUser] = useState(props.user);
  const [showSetPassword, setShowSetPassword] = useState(false);

  useEffect(() => {
    if (!props.user) {
      //console.log("setting empty user");
      setUser({
        name: "",
        username: "",
        email: "",
        roles: [],
        enabled: false,
      } as User);
    } else {
      setUser(props.user);
    }
  }, [props.user]);

  const updateUser = (field: string, value: string | Boolean) => {
    //console.log(value);
    let temp = { ...user };
    if (temp && Object.keys(temp).includes(field)) {
      temp = Object.fromEntries(
        Object.entries(temp).map((kv) => {
          if (kv[0] === field) {
            kv[1] = value;
          }
          return kv;
        })
      );
    } else if (temp) {
      temp = Object.fromEntries([...Object.entries(temp), [field, value]]);
    }
    setUser(temp as User);
  };

  //console.log(user);

  const updateUserRoles = (el: CheckboxProps) => {
    const temp = { ...user, roles: [] } as User;
    if (user && user.roles) {
      for (let userRole of user.roles) {
        if (el.value !== userRole) temp.roles.push(userRole);
      }
    }
    if (el.checked) {
      temp.roles.push(el.value);
    }
    setUser(temp);
  };

  const footer = (onSave: () => void, onDiscard: () => void) => (
    <div>
      <Button label="Save" icon="pi pi-check" onClick={onSave} />
      <Button label="Discard" icon="pi pi-times" onClick={onDiscard} />
    </div>
  );

  return (
    <>
      <Dialog
        visible={props.visible}
        className="p-fluid"
        header={props.user ? "Edit User" : "Add User"}
        onHide={props.onClose}
        onShow={() => {
          setUser(props.user);
        }}
        footer={footer(
          () =>
            user &&
            (props.user ? props.onSave(user) : setShowSetPassword(true)),
          props.onClose
        )}
      >
        <div className="p-field">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            value={user?.name || ""}
            onChange={(e) =>
              updateUser("name", (e.target as HTMLInputElement).value)
            }
            autoFocus
          />
        </div>
        <div className="p-field">
          <label htmlFor="username">Username</label>
          <InputText
            id="username"
            value={user?.username || ""}
            onChange={(e) =>
              updateUser("username", (e.target as HTMLInputElement).value)
            }
          />
        </div>
        <div className="p-field">
          <label htmlFor="email">Email</label>
          <InputText
            id="email"
            value={user?.email || ""}
            onChange={(e) =>
              updateUser("email", (e.target as HTMLInputElement).value)
            }
          />
        </div>
        <div className="flex pt-2 pb-2">
          <label htmlFor={"enabled"} className={"pr-2"}>
            Active
          </label>
          <InputSwitch
            id={"enabled"}
            checked={user?.enabled}
            onChange={(e) => updateUser("enabled", e.value)}
          />
        </div>
        <div>
          <label>Roles</label>
          {Object.values(Role).map((role) => (
            <div id={role} className="p-col-12">
              <Checkbox
                inputId="cb1"
                value={role}
                onChange={updateUserRoles}
                checked={user?.roles?.includes(role)}
              ></Checkbox>
              <label htmlFor="cb1" className="p-checkbox-label">
                {role}
              </label>
            </div>
          ))}
        </div>
      </Dialog>
      {showSetPassword && (
        <PasswordChangeDialog
          title={"Set User Password"}
          confirm={false}
          onCancel={() => setShowSetPassword(false)}
          onSave={(password) => {
            const temp: User = { ...user } as User;
            temp.password = password;
            setShowSetPassword(false);
            console.log(temp);
            props.onSave(temp);
          }}
        />
      )}
    </>
  );
};

export default UserEditor;
