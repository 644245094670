import React, { useEffect, useState } from "react";
import { ContentBox, TextCenter } from "../../components/StandardComponents";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import { MainLayout2 } from "../../components/layouts";

import { styled } from "../../styles/ThemeProvider";
import ReactMarkdown from "react-markdown";
import { Promotion } from "../../backend-api/types";
import { getLocale } from "./promotionHelpers";
import { getPromotionBySlug } from "../../backend-api/user-api";
import { graphql } from "gatsby";

import Seo from "../seo";
import { Button } from "../card";
import { encodeObject } from "../../utils";

const MarkDownContentContainer = styled.div`
  line-height: 1.4;
  /*width: 100%;*/
  & strong {
    font-weight: 500;
  }
  /*& h1 {
    font-size: 100%;
  }*/
`;

const IndexPage: React.FC<{
  pageContext: { promotion: Promotion };
  language: string;
  path?: string;
  slug?: string;
}> = (props/*{ pageContext: { promotion }, language, slug }*/) => {
  
  console.log(props)
  const { pageContext: { promotion, language: lang }, language, slug } = props;
  const [loadedPromotion, setLoadedPromotion] = useState<Promotion | null>(
    null
  );
  useEffect(() => {
    (async () => {
      if (promotion?.slug || slug) {
        setLoadedPromotion(await getPromotionBySlug(promotion?.slug || slug));
      }
    })();
  }, [language, lang, slug]);

  const { t } = useTranslation();
  console.log(lang)
  return (
    <>
      <Seo
        lang={language}
        title={`Laluna Hoi An Riverside Hotel & Spa | Promotions - ${
          getLocale(loadedPromotion || promotion, language || lang)?.title || ""
        }`}
        image={
          getLocale(loadedPromotion || promotion, language || lang)?.seoImage || ""
        }
        meta={[
          {
            property: "og:image",
            content: getLocale(loadedPromotion || promotion, language || lang)
              ?.seoImage,
          },
          { property: "og:image:width", content: "1200" },
          { property: "og:image:height", content: "630" },
        ]}
        description={getLocale(loadedPromotion || promotion, language || lang)?.title}
      />
      <MainLayout2
        pageTitle={
          getLocale(loadedPromotion || promotion, language || lang)?.title || ""
        }
        pageBannerImg={"/assets/general-banner.jpg"}
        pageCrumbMap={[
          { link: "/", name: t("Home") },
          { link: "/promotions", name: t("Promotions") },
        ]}
      >
        <ContentBox>
          <div className="ck-content"
            dangerouslySetInnerHTML={{
              __html: getLocale(loadedPromotion || promotion, language || lang)?.data,
            }}
          />
          {/*<ReactMarkdown
            source={getLocale(loadedPromotion || promotion, language)?.data}
            escapeHtml={false}
      />*/}
          <TextCenter>
            <Button
              href={`/contact-us/?encoded=${encodeObject({
                selectedName: "Book a Promotion",
                values: [
                  {
                    key: "Promotion",
                    value: getLocale(loadedPromotion || promotion, "en")?.title,
                  },
                ],
              })}`}
            >
              {t("Book Now")}
            </Button>
          </TextCenter>
        </ContentBox>
      </MainLayout2>
    </>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
