exports.components = {
  "component---src-admin-index-tsx": () => import("./../../../src/admin/index.tsx" /* webpackChunkName: "component---src-admin-index-tsx" */),
  "component---src-components-news-news-page-index-tsx": () => import("./../../../src/components/News/NewsPageIndex.tsx" /* webpackChunkName: "component---src-components-news-news-page-index-tsx" */),
  "component---src-components-news-news-page-layout-tsx": () => import("./../../../src/components/News/NewsPageLayout.tsx" /* webpackChunkName: "component---src-components-news-news-page-layout-tsx" */),
  "component---src-components-promotions-promotion-page-layout-tsx": () => import("./../../../src/components/Promotions/PromotionPageLayout.tsx" /* webpackChunkName: "component---src-components-promotions-promotion-page-layout-tsx" */),
  "component---src-components-promotions-promotions-index-tsx": () => import("./../../../src/components/Promotions/PromotionsIndex.tsx" /* webpackChunkName: "component---src-components-promotions-promotions-index-tsx" */),
  "component---src-components-tours-tour-page-layout-tsx": () => import("./../../../src/components/Tours/TourPageLayout.tsx" /* webpackChunkName: "component---src-components-tours-tour-page-layout-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-restaurant-and-bar-index-tsx": () => import("./../../../src/pages/restaurant-and-bar/index.tsx" /* webpackChunkName: "component---src-pages-restaurant-and-bar-index-tsx" */),
  "component---src-pages-restaurant-and-bar-menu-index-tsx": () => import("./../../../src/pages/restaurant-and-bar/menu/index.tsx" /* webpackChunkName: "component---src-pages-restaurant-and-bar-menu-index-tsx" */),
  "component---src-pages-rooms-deluxe-index-tsx": () => import("./../../../src/pages/rooms/deluxe/index.tsx" /* webpackChunkName: "component---src-pages-rooms-deluxe-index-tsx" */),
  "component---src-pages-rooms-family-index-tsx": () => import("./../../../src/pages/rooms/family/index.tsx" /* webpackChunkName: "component---src-pages-rooms-family-index-tsx" */),
  "component---src-pages-rooms-index-tsx": () => import("./../../../src/pages/rooms/index.tsx" /* webpackChunkName: "component---src-pages-rooms-index-tsx" */),
  "component---src-pages-rooms-luxury-index-tsx": () => import("./../../../src/pages/rooms/luxury/index.tsx" /* webpackChunkName: "component---src-pages-rooms-luxury-index-tsx" */),
  "component---src-pages-rooms-suite-index-tsx": () => import("./../../../src/pages/rooms/suite/index.tsx" /* webpackChunkName: "component---src-pages-rooms-suite-index-tsx" */),
  "component---src-pages-rooms-super-deluxe-index-tsx": () => import("./../../../src/pages/rooms/super-deluxe/index.tsx" /* webpackChunkName: "component---src-pages-rooms-super-deluxe-index-tsx" */),
  "component---src-pages-rooms-superior-index-tsx": () => import("./../../../src/pages/rooms/superior/index.tsx" /* webpackChunkName: "component---src-pages-rooms-superior-index-tsx" */),
  "component---src-pages-spa-index-tsx": () => import("./../../../src/pages/spa/index.tsx" /* webpackChunkName: "component---src-pages-spa-index-tsx" */),
  "component---src-pages-transportation-and-tours-airport-transfer-index-tsx": () => import("./../../../src/pages/transportation-and-tours/airport-transfer/index.tsx" /* webpackChunkName: "component---src-pages-transportation-and-tours-airport-transfer-index-tsx" */),
  "component---src-pages-transportation-and-tours-index-old-tsx": () => import("./../../../src/pages/transportation-and-tours/index_old.tsx" /* webpackChunkName: "component---src-pages-transportation-and-tours-index-old-tsx" */),
  "component---src-pages-transportation-and-tours-index-tsx": () => import("./../../../src/pages/transportation-and-tours/index.tsx" /* webpackChunkName: "component---src-pages-transportation-and-tours-index-tsx" */),
  "component---src-pages-transportation-and-tours-private-car-index-tsx": () => import("./../../../src/pages/transportation-and-tours/private-car/index.tsx" /* webpackChunkName: "component---src-pages-transportation-and-tours-private-car-index-tsx" */),
  "component---src-pages-transportation-and-tours-tours-index-tsx": () => import("./../../../src/pages/transportation-and-tours/tours/index.tsx" /* webpackChunkName: "component---src-pages-transportation-and-tours-tours-index-tsx" */)
}

