import styled from "styled-components";
import React from "react";
import { CarServiceOptionInterface, CarSerivceOptions } from "./Types";
import { DriverItenerary } from "./DriverItenerary";
import { PriceDisplay } from "./PriceDisplay";

/*
<CarServiceTable>
                <CarServiceOption>
                  <RouteDescription>
                    <RouteStart></RouteStart>
                    <RouteStop>
                      <RouteStopOption></RouteStopOption>
                    </RouteStop>
                    <RouteEnd></RouteEnd>
                  </RouteDescription>
                </CarServiceOption>
              </CarServiceTable> 
*/

const CarServiceTable = styled.div`
  margin: 20px;
`;

const TableTitle = styled.div`
  font-family: ${(props) => props.theme.fontSubTitle};
  font-size: 120%;
  border-bottom: 2px solid ${(props) => props.theme.darkColor};
  padding-bottom: 5px;
  text-align: center;
`;

const CarServiceOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  //border-bottom: 1px solid ${(props) => props.theme.darkColor};
  border-bottom: 1px solid #dfdfdf;

  @media only screen and (max-width: 660px) {
    flex-direction: column;
  }
  &:nth-child(even) {
    background-color: #f6f6f6;
  }
  /*&:first-child {
    border-top: 1px solid ${(props) => props.theme.darkColor};
  }*/
`;

export const CarServiceOptionComposite: React.FC<CarServiceOptionInterface> = ({
  start,
  end,
  stops,
  prices,
}) => {
  return (
    <CarServiceOption>
      <DriverItenerary start={start} end={end} stops={stops} />
      <PriceDisplay prices={prices}></PriceDisplay>
    </CarServiceOption>
  );
};

export const PrivateCarTransportTable: React.FC<{
  options: CarSerivceOptions;
}> = ({ options }) => (
  <CarServiceTable>
    <TableTitle>Example Itineraries</TableTitle>
    {options &&
      options.map(({ prices, stops, start, end }, index) => (
        <CarServiceOptionComposite
          start={start}
          stops={stops}
          end={end}
          prices={prices}
        />
      ))}
  </CarServiceTable>
);
