import React from "react";
import { ListBox } from "primereact/listbox";
import { SupportedLocales } from "./supportedLocales";

interface ListBoxChangeParams {
  originalEvent: Event;
  value: any;
  target: {
    name: string;
    id: string;
    value: any;
  };
}

type ListBoxChangeFn = (e: ListBoxChangeParams) => void;

export const LanguageListBox = ({
  onChange,
  value,
}: {
  onChange: ListBoxChangeFn;
  value: string;
}) => (
  <ListBox
    options={SupportedLocales}
    optionLabel={"label"}
    optionValue={"locale"}
    value={value}
    onChange={onChange}
  />
);
