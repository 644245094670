import React from "react";
import baseStyled, {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import {
  LalunaAccentColor,
  LalunaAccentColorLighter,
  LalunaDarkColor,
} from "../constants";

export const Theme: DefaultTheme = {
  components: {
    carousel: {
      backgroundColor: "#1c1c1c",
      borderColor: "#1c1c1c",
      borderRadius: 5,
      borderWidth: 10,
      maxWidth: 900,
      viewingBorderColor: "white",
    },
    layouts: {
      room: {
        amenitiesContainerBorderColor: "#e1e1e1",
        amenitiesTitleFontFamily: '"Playfair Display", Arial, serif',
        amenitiesTitleFontSize: "130%",
        amenitiesTitleFontWeight: "bold",
        amenitiesTitleLineColor: LalunaAccentColor,
      },
    },
    standardComponents: {
      pageTitleFont: '"Great Vibes", Arial, cursive',
      headerDividerColor: LalunaAccentColor,
      pageTitleFontSize: "300%",
      subSectionTitleFont: '"Playfair Display", Arial, serif',
      subSsectionTitleFontSize: "150%",
      bgBlockBackgroundColor: "#1c1c1c",
      bgBlockForegroundColor: "white",
      carousel: {
        backgroundColor: "#1c1c1c",
        borderColor: "#1c1c1c",
        borderRadius: 0,
        borderWidth: 0,
        maxWidth: 350,
      },
    },
    footer: {
      backgroundColorMain: "#1c1c1c",
      backgroundColorBottom: LalunaAccentColor,
    },
  },
  colorOne: LalunaAccentColor,
  colorOneLighter: LalunaAccentColorLighter,
  darkColor: LalunaDarkColor,
  fontSubTitle: '"Playfair Display", Arial, serif',
  fontTitle: '"Great Vibes", Arial, cursive',
};

const ThemeProvider: React.FC<{children: React.ReactNode}> = ({ children }) => (
  <StyledThemeProvider theme={Theme}>{<>{children}</>}</StyledThemeProvider>
);

export default ThemeProvider;

export type ThemeType = typeof Theme;
export const styled = baseStyled 
