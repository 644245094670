import React from "react";
import styled from "styled-components";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import {
  Page,
  ContentBox,
  Center,
  BreadCrumb,
  SectionHeader,
  CrumbArray,
} from "../StandardComponents";
import Nav from "../navbar";
import { LalunaDarkColor, LalunaAccentColor } from "../../constants";
import Footer from "../footer";
import ThemeProvider from "../../styles/ThemeProvider";
import GlobalStyle from "../../styles/GlobalStyle";
import { PageImageBanner } from "../../components/PageBanner";
import { getImage } from "../../../lib/image-resolve";
import Booking from "../booking";

interface LayoutProps {
  children: React.ReactNode
  pageTitle: string;
  pageBannerImg: string;
  pageCrumbMap: CrumbArray;
}

const MainLayout2: React.FC<LayoutProps> = ({
  children,
  pageTitle,
  pageBannerImg,
  pageCrumbMap,
}) => {
  const { t } = useTranslation();
  const BottomNavLinks = [
    { displayName: t("Home"), href: "/" },
    { displayName: t("Rooms & Amenities"), href: "/rooms/" },
    { displayName: t("Restaurant & Bar"), href: "/restaurant-and-bar/" },
    { displayName: t("Spa Services"), href: "/spa/" },
    {
      displayName: t("Transportation & Tours"),
      href: "/transportation-and-tours/",
    },
    { displayName: t("Promotions"), href: "/promotions/" },
    { displayName: t("News"), href: "/news/" },
  ];
  return (
    <ThemeProvider>
      <Page>
        <Nav
          TopNavBackgroundColor={LalunaDarkColor}
          BottomNavBackgroundColor={LalunaAccentColor}
          BottomNavBackgroundColorOpacity={1}
          BottomNavBackgroundColorMobile={LalunaAccentColor}
          BottomNavBackgroundColorOpacityMobile={1}
          BottomNavLinks={BottomNavLinks}
          BottomLinkHoverColor={LalunaDarkColor}
        ></Nav>
        <GlobalStyle />
        <PageImageBanner src={getImage(pageBannerImg)}></PageImageBanner>
        <Booking></Booking>
        <ContentBox>
          <BreadCrumb crumbMap={pageCrumbMap} />
          <SectionHeader title={pageTitle}></SectionHeader>
        </ContentBox>
        {children}
      </Page>
      <Footer></Footer>
    </ThemeProvider>
  );
};

export default MainLayout2;
