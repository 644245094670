export const SupportedLocales = [
  {
    locale: "en",
    label: "English",
  },
  {
    locale: "vi",
    label: "Vietnamese",
  },
  {
    locale: "ko",
    label: "Korean",
  },
];

export const getLangForLocale = (locale: string) =>
  SupportedLocales.find((l) => l.locale === locale)!.label;
