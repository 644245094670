import { Link, navigate } from "gatsby";
import React, { useRef, useState } from "react";
import { RouteComponentProps, Router } from "@reach/router";

import styled from "styled-components";

import PromotionIndex from "./promotions";
import OperationsIndex from "./operations";
import UsersIndex from "./users";

import { getMe, updatePassword, User } from "../backend-api/admin-api";

import { Messages } from "primereact/messages";
import PasswordChangeDialog from "./users/passwordChangeDialog";
import ContentIndex from "./content";
import { useQuery } from "react-query";
import { UnauthorizedError } from "../backend-api/api";

//reset list styles
const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > li > a {
    border-bottom: 1px solid gray;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;

  text-decoration: none;
  && > i {
    font-size: 1.6em;
  }
`;

interface AdminComponentProps extends RouteComponentProps {
  user: User | null;
}

const Admin = ({ user }: AdminComponentProps) => {
  const [userMenu, setUserMenu] = useState(false);
  const messagesRef = useRef(null);

  const [showChangePassword, setShowChangePassword] = useState(false);
  return (
    <div className="h-full" onClick={() => setUserMenu(false)}>
      <Messages
        ref={messagesRef}
        className="w-full absolute flex justify-center mt-14 z-1001"
      ></Messages>

      <div className="h-12 bg-black text-white flex flex-row justify-between pr-5 pl-5">
        <div className="w-1/6 flex justify-center">{/*Left*/}</div>
        <div className="w-1/6 flex justify-center">{/*Center*/}</div>
        <div className="w-1/6 flex justify-center relative">
          <div
            className="bg-yellow-600 h-full font-medium cursor-pointer relative "
            onClick={(e) => {
              setUserMenu(!userMenu);
              e.stopPropagation();
            }}
          >
            <div className="bg-yellow-600 h-full pr-5 pl-5 font-medium flex items-center w-max cursor-pointer z-10 relative ">
              {user?.name}
            </div>
            <div
              className={
                "transition-all duration-500 ease-in-out visible bg-gray-800 absolute left-0 right-0 flex flex-col items-center " +
                `${userMenu ? "top-full" : "-top-2"}`
              }
            >
              <div
                className="cursor-pointer pt-2 pb-2 font-normal text-xs"
                onClick={() => setShowChangePassword(true)}
              >
                <i className="pi pi-lock" style={{ fontSize: "0.8em" }}></i>
                <span className="ml-2">Change Password</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-full">
        <div className="flex bg-gray-600 h-full max-w-min min-w-min">
          <StyledList className="text-white w-full">
            <li>
              <StyledLink
                to="/admin/promotions"
                className="flex flex-col items-center justify-center ml-4 mr-4 pb-4 pt-4 hover:text-yellow-400"
              >
                <i className="pi pi-dollar "></i>
                <span>Promotions</span>
              </StyledLink>
            </li>
            <li>
              <StyledLink
                to="/admin/content"
                className="flex flex-col items-center justify-center ml-4 mr-4 pb-4 pt-4 hover:text-yellow-400"
              >
                <i className="pi pi-pencil"></i>
                <span>Content</span>
              </StyledLink>
            </li>
            <li>
              <StyledLink
                to="/admin/users"
                className="flex flex-col items-center justify-center ml-4 mr-4 pb-4 pt-4 hover:text-yellow-400"
              >
                <i className="pi pi-user-edit"></i>
                <span>Users</span>
              </StyledLink>
            </li>
            <li>
              <StyledLink
                to="/admin/operations"
                className="flex flex-col items-center justify-center ml-4 mr-4 pb-4 pt-4 hover:text-yellow-400"
              >
                <i className="pi pi-sliders-h"></i>
                <span>Operations</span>
              </StyledLink>
            </li>
          </StyledList>
        </div>
        <div className="w-full h-full">
          <Router className="h-full">
            <PromotionIndex path="promotions/*" messages={messagesRef} />
            <OperationsIndex path="operations/*" />
            <ContentIndex path="content/*" messages={messagesRef} />
            <UsersIndex path="users/*" messages={messagesRef} />
            <LandingPath default />
          </Router>
        </div>
      </div>
      {showChangePassword && (
        <PasswordChangeDialog
          title={"Change Password"}
          confirm={true}
          onSave={(password) => {
            setShowChangePassword(false);
            updatePassword(password);
          }}
          onCancel={() => setShowChangePassword(false)}
        />
      )}
    </div>
  );
};

const LandingPath = (props: RouteComponentProps) => {
  return (
    <div
      className={
        "flex justify-center items-center h-full text-5xl text-center font-semibold leading-relaxed"
      }
    >
      Website Administration
    </div>
  );
};

export default Admin;
