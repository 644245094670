import React, { useState, useEffect } from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import GlobalStyle from "../../../styles/GlobalStyle";
import {
  ContentBox,
  BreadCrumb,
  SectionHeader,
  TextCenter,
} from "../../../components/StandardComponents";

import { MainLayout2 } from "../../../components/layouts";
import styled from "styled-components";
import { getImage } from "../../../../lib/image-resolve";
import { PageImageBanner } from "../../../components/PageBanner";

import RestaurantMenuDataProvider from "../../../dataProviders/RestaurantMenuDataProvider";
import { PurchaseMenu } from "../../../components/PurchaseMenu/PurchaseMenu";
import { MenuDefArray } from "../../../components/PurchaseMenu/Types";

const IndexPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <MainLayout2
      pageTitle={t("Restaurant & Bar")}
      pageBannerImg={"/assets/restaurant/restaurant-1-1280x300.jpg"}
      pageCrumbMap={[
        { link: "/", name: t("Home") },
        { link: "/restaurant-and-bar", name: t("Restaurant & Bar") },
      ]}
    >
      <ContentBox>
        <TextCenter>
          {t(
            "Laluna's Moon River restaurant has a excellent view of the Hoai River, we provide a wide variety of local Vietnamese, Asian, and international cuisine options. Weather you're seated inside the restaurant, outside on the front terrace, or next to the pool, we provide a nice relaxing environment, a great view and plenty of dining choices."
          )}
        </TextCenter>
      </ContentBox>
      <RestaurantMenuDataProvider>
        {(MenuData: MenuDefArray) => {
          return <PurchaseMenu menuData={MenuData}></PurchaseMenu>;
        }}
      </RestaurantMenuDataProvider>
    </MainLayout2>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
