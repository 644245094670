import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import GlobalStyle from "../../styles/GlobalStyle";
import {
  ContentBox,
  Center,
  BgBlock,
  SubSectionHeader,
  TextCenter,
} from "../../components/StandardComponents";

import { MainLayout2 } from "../../components/layouts";
import { I18n, I18nTFunction } from "react-i18next";
import Card, { CardBox, CardTitleOverlay } from "../../components/card";
import { LalunaDarkColor } from "../../constants";

import { PrivateCarTransportTable } from "../../components/Transportation/PrivateCarTransportTable";

import styled from "styled-components";
import { TransportationDataProvider } from "../../dataProviders/TransportationDataProvider";
import { CarSerivceOptions } from "../../components/Transportation/Types";
import { TourOptions } from "../../dataProviders/ToursDataProvider";
import { ToursDataProvider } from "../../dataProviders/ToursDataProvider";
import { ensureArray } from "../../utils";
import { TourCardDetailsComposite } from "../../components/Tours/TourCardDetails";
import { Modal } from "../../components/modal/Modal";
import { getImage } from "../../../lib/image-resolve";

const TransportOptionsCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 120%;
`;

const IndexPage: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <>
      {console.log(pageContext)}
      <MainLayout2
        pageTitle={t("Transportation & Tours")}
        pageBannerImg={"/assets/general-banner.jpg"}
        pageCrumbMap={[{ link: "/", name: t("Home") }]}
      >
        <ContentBox>
          <CardBox>
            <Card
              imageUrl={getImage("/assets/da-nang-airport-1.jpg", "350square")}
              title={"Airport Transfer"}
              buttonText={t("More Details")}
              buttonHref={"/transportation-and-tours/airport-transfer"}
              bottonTextHoverColor={LalunaDarkColor}
            >
              <TransportOptionsCardBody>
                <TextCenter>
                  Need a transfer to or from Da Nang International Airport? We
                  can arrange a transfer.
                </TextCenter>
              </TransportOptionsCardBody>
            </Card>
            <Card
              imageUrl={getImage(
                "/assets/transportation/private-car-2.jpg",
                "350square"
              )}
              title={"Private Driver Service"}
              buttonText={t("More Details")}
              buttonHref={"/transportation-and-tours/private-car/"}
              bottonTextHoverColor={LalunaDarkColor}
            >
              <TransportOptionsCardBody>
                <TextCenter>
                  Starting at just $25 USD, we can arrange for you to have a
                  private driver
                </TextCenter>
              </TransportOptionsCardBody>
            </Card>
            <Card
              imageUrl={getImage("/assets/hoi-an-city-1.jpg", "350square")}
              title={"Tours"}
              buttonText={t("More Details")}
              buttonHref={"/transportation-and-tours/tours"}
              bottonTextHoverColor={LalunaDarkColor}
            >
              <TransportOptionsCardBody>
                <TextCenter>
                  {
                    "While your not relaxing, you should be doing something exciting! Checkout our tour options."
                  }
                </TextCenter>
              </TransportOptionsCardBody>
            </Card>
          </CardBox>
        </ContentBox>
      </MainLayout2>
    </>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
