import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import GlobalStyle from "../styles/GlobalStyle";
import {
  Center,
  HeaderWithDivider,
  SubSectionTitle,
  ContentBox,
  TextCenter,
  BgBlock,
  SubSectionHeader,
  DesktopOnly,
  MobileOnly,
  FeatureBlock,
  Link,
} from "../components/StandardComponents";

import { HomeLayout } from "../components/layouts";
import { RotateCarousel } from "../components/carousel";
import { styled } from "../styles/ThemeProvider";
import Image from "../components/card/image";
import CardButton from "../components/card/CardButton";
import { getImage } from "../../lib/image-resolve";
import { getPromotions } from "../backend-api/user-api";
import { Promotion } from "../backend-api/types";
import { getLocale } from "../components/Promotions/promotionHelpers";
import { encodeObject } from "../utils";
import { BuildBookingUrl } from "../components/booking";
import Seo from "../components/seo.js"

const CarouselSlideBox = styled.div`
  width: 274px;
  height: 264px;
  min-width: 274px;
  min-height: 264px;
  //background-color: red;
  display: flex;
  justify-content: center;
`;

const PromotionBox = styled.div`
  width: 264px;
  height: 264px;
  background-color: black;
  color: white;
  overflow: hidden;
  position: relative;
`;

const PromotionLabel = styled.div`
  position: absolute;
  height: 80px;
  bottom: 0px;
  //background-color: red;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  & > div {
    //background-color: blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const PromotionLink = styled(Link)`
  color: white;
  &:hover {
    color: white;
    font-weight: 500;
  }
`;

const ImageLogo = styled.img`
  height: 150px;
  @media only screen and (max-width: 660px) {
    height: 120px;
  }
`;

const FeaturedContentWrapper = styled.div`
  margin: 20px;
`;

const WhiteSpan = styled.span`
  color: white;
`;

const promotionsPresenter = (
  promotions: Array<Promotion>,
  itemsVisible: number
): Array<Promotion> => {
  if (promotions.length < itemsVisible + 1) {
    let temp = [...promotions];
    for (let x = 0; x < itemsVisible + 1 - promotions.length; x++) {
      temp.push(promotions[x % promotions.length]);
    }
    return temp;
  }
  return promotions;
};

const IndexPage: React.FC<{ promotions: Array<Promotion> }> = ({
  promotions,
}) => {
  const { t } = useTranslation();
  const [queryPromotions, setQueryPromotions] = useState<[Promotion] | null>(
    null
  );

  useEffect(() => {
    (async () => {
      setQueryPromotions(await getPromotions());
    })();
  }, []);

  return (
    <>
      <Seo
        title={`We Serve With Heart!`}
      />
      <GlobalStyle />
      <HomeLayout>
        <HeaderWithDivider>
          <Center>
            <ImageLogo src={"/assets/Laluna-Logo-150.png"} />
          </Center>
        </HeaderWithDivider>
        <ContentBox>
          <TextCenter>
            <SubSectionTitle>
              {t("At Laluna Hoi An Riverside Hotel, We Serve With Heart!")}
            </SubSectionTitle>
          </TextCenter>
          <FeaturedContentWrapper>
            <Center>
              <TextCenter>
                {t(
                  "Overlooking the historic Hoi An ancient town, Laluna Hoi An Riverside Hotel brings the charm of Hoi An’s heritage & culture inside our doors. The hotel offers its guests a holiday full of relaxation, an unparalleled level of comfort and dignified service as well as gourmet dining options at the heart of Hoi An ancient town. Book your stay at Laluna Hoi An Riverside Hotel for the ultimate relaxed and personalized luxury. Whatever you’ll be here for, we’ll make your stay in Hoi An a memorable one."
                )}
              </TextCenter>
            </Center>
          </FeaturedContentWrapper>
        </ContentBox>
        <BgBlock>
          <ContentBox>
            <SubSectionHeader
              title={"Book Direct & Save More"}
              titleColor={"white"}
            ></SubSectionHeader>
            <FeaturedContentWrapper>
              <Center>
                <DesktopOnly>
                  {(queryPromotions || promotions) && (
                    <RotateCarousel
                      itemsVisible={3}
                      itemWidth={274}
                      rotateDelay={1000}
                    >
                      {promotionsPresenter(queryPromotions || promotions, 3)
                        .filter((promo) => promo.enabled)
                        .map((promo, index) => (
                          <CarouselSlideBox key={index}>
                            <PromotionBox>
                              <PromotionLink to={`/promotions/${promo.slug}`}>
                                <Image
                                  width={264}
                                  height={264}
                                  image={promo.image}
                                ></Image>
                                <PromotionLabel>
                                  <div>{getLocale(promo, "en")?.title}</div>
                                </PromotionLabel>
                              </PromotionLink>
                            </PromotionBox>
                          </CarouselSlideBox>
                        ))}
                    </RotateCarousel>
                  )}
                </DesktopOnly>
                <MobileOnly>
                  {(queryPromotions || promotions) && (
                    <RotateCarousel
                      itemsVisible={1}
                      itemWidth={274}
                      rotateDelay={1000}
                    >
                      {(queryPromotions || promotions)
                        .filter((promo) => promo.enabled)
                        .map((promo, index) => (
                          <CarouselSlideBox key={index}>
                            <PromotionBox>
                              <PromotionLink to={`/promotions/${promo.slug}`}>
                                <Image
                                  width={264}
                                  height={264}
                                  image={promo.image}
                                ></Image>
                                <PromotionLabel>
                                  <div>{getLocale(promo, "en")?.title}</div>
                                </PromotionLabel>
                              </PromotionLink>
                            </PromotionBox>
                          </CarouselSlideBox>
                        ))}
                    </RotateCarousel>
                  )}
                </MobileOnly>
              </Center>
            </FeaturedContentWrapper>
          </ContentBox>
        </BgBlock>
        <FeatureBlock
          contentLeft={true}
          images={[
            getImage("/assets/rooms/Luxury/Luxury-2.jpg", "350square"),
            getImage(
              "/assets/rooms/Super-Deluxe/Superb-Deluxe-Double-310-1.jpg",
              "350square"
            ),
            getImage(
              "/assets/rooms/Deluxe/Deluxe-Double-317-1.jpg",
              "350square"
            ),
          ]}
        >
          <FeaturedContentWrapper>
            <SubSectionHeader
              title={"Enjoy Our Luxurious Rooms & Superior Amenities"}
            >
              {
                "Laluna Hoi An Riverside Hotel is outfitted in brilliant contemporary style with luxurious amenities and tastefully decorated which affords a glorious view over the Hoai River. The Laluna hotel provides luxurious accommodations with a choice of room types ranging from great value superior rooms to luxurious suites that meet any of your needs or budgets."
              }

              <div className="text-center pt-10 flex justify-center space-x-6">
                <CardButton href={"/rooms"}>Discover More</CardButton>
                <CardButton href={BuildBookingUrl()} standardLink={true}>
                  &nbsp;&nbsp;Book Now&nbsp;&nbsp;
                </CardButton>
              </div>
            </SubSectionHeader>
          </FeaturedContentWrapper>
        </FeatureBlock>
        <BgBlock>
          <FeatureBlock
            contentLeft={false}
            images={[
              getImage(
                "/assets/restaurant/Laluna-Restaurant-table-detail3.jpg",
                "350square"
              ),
              getImage(
                "/assets/restaurant/Laluna-Restaurant-table-detail.jpg",
                "350square"
              ),
              getImage("/assets/restaurant/Laluna-Restaurant.jpg", "350square"),
              getImage(
                "/assets/restaurant/Laluna-Restaurant-buffet-corner.jpg",
                "350square"
              ),
              getImage(
                "/assets/restaurant/Laluna-Restaurant-buffet-corner-vietnamese-cakes.jpg",
                "350square"
              ),
            ]}
          >
            <FeaturedContentWrapper>
              <SubSectionHeader
                titleColor={"white"}
                title={"Enjoy the Best Cuisine From Vietnam"}
              >
                <WhiteSpan>
                  {
                    "Featuring a captivating view of Hoai River and a wide variety of Vietnamese, Asian and International cuisine, paired with a thoughtful selection of wines and beverage choices, the River View Restaurant elevates all-day dining to a new level. Served either indoors or outdoors overlooking Hoai River, the restaurant’s menu offers freshly made menu selections from sunup to sundown. Sample international buffet favorites for breakfast, lunch and dinner or select from the delectable a la carte options. "
                  }
                </WhiteSpan>
                <div className="text-center pt-10 flex justify-center space-x-6">
                  <CardButton href={"/restaurant-and-bar/"}>
                    Discover More
                  </CardButton>
                </div>
              </SubSectionHeader>
            </FeaturedContentWrapper>
          </FeatureBlock>
        </BgBlock>
        <FeatureBlock
          contentLeft={true}
          images={[
            getImage("/assets/spa/Laluna-Spa-Masage2.jpg", "350square"),
            getImage("/assets/spa/MPX_6562.JPG", "350square"),
            getImage("/assets/spa/Laluna-Spa-Foot-room.jpg", "350square"),
            getImage("/assets/spa/Laluna-Spa-Cameo.jpg", "350square"),
            getImage("/assets/spa/Laluna-Spa-Foot-room-cameo.jpg", "350square"),
          ]}
        >
          <FeaturedContentWrapper>
            <SubSectionHeader
              title={"Indulge in Luxurious Spa Tranquility at Laluna Spa"}
            >
              {
                "Laluna Hoi An Riverside Hotel and Spa offers a luxurious spa experience that combines traditional Vietnamese healing practices with modern wellness techniques. This serene spa setting, nestled along the scenic riverside, provides a tranquil retreat from the bustling streets of Hoi An."
              }

              <div className="text-center pt-10 flex justify-center space-x-6">
                <CardButton href={"/spa/"}>Discover More</CardButton>
              </div>
            </SubSectionHeader>
          </FeaturedContentWrapper>
        </FeatureBlock>
        <BgBlock>
          <FeatureBlock
            contentLeft={false}
            images={[
              getImage("/assets/transportation/MPX_6237.jpg", "350square"),
              getImage("/assets/transportation/MPX_6237.jpg", "350square"),
            ]}
          >
            <FeaturedContentWrapper>
              <SubSectionHeader
                title={"Luxury Private Car Transfers for Seamless Travel"}
              >
                {
                  "Experience the epitome of convenience and comfort with LaLuna Hoi An Riverside Hotel & Spa's private car transfer service. Whether you're arriving from the Da Nang  airport or planning a day out exploring the enchanting sights of Da nang and  Hoi An, our fleet of premium vehicles is at your disposal. Enjoy seamless travel with our professional chauffeurs, ensuring timely and stylish arrivals at every destination. With our private transfer service, every journey is an extension of the luxury and serenity you experience at LaLuna"
                }

                <div className="text-center pt-10 flex justify-center space-x-6">
                  <CardButton href={"/transportation-and-tours/"}>
                    Discover More
                  </CardButton>
                </div>
              </SubSectionHeader>
            </FeaturedContentWrapper>
          </FeatureBlock>
        </BgBlock>
        <FeatureBlock
          contentLeft={true}
          images={[
            getImage("/assets/tours/ba-na-bridge-sunset.jpg", "350square"),
            getImage("/assets/tours/basket-boat-1.jpg", "350square"),
            getImage(
              "/assets/transportation/tours/Group-of-cyclists.jpg",
              "350square"
            ),
            getImage("/assets/tours/hue-1.jpg", "350square"),
          ]}
        >
          <FeaturedContentWrapper>
            <SubSectionHeader
              title={"Exclusive Tours Unveiling the Heart of Hoi An"}
            >
              {
                "Embark on an adventure of a lifetime with LaLuna Hoi An Riverside Hotel & Spa's exclusive tour services. We take pride in offering both group and private tours, meticulously crafted to unveil the rich tapestry of Hoi An's culture, history, and breathtaking landscapes. Choose a private tour for a personalized experience tailored to your interests and pace, ensuring an intimate exploration of this UNESCO World Heritage site. Or, join one of our group tours to share the joy of discovery with fellow travelers. With expert local guides, luxurious transport, and insider access to the best spots, Laluna's tour services promise to enrich your stay with unforgettable memories"
              }
              <div className="text-center pt-10 flex justify-center space-x-6">
                <CardButton href={"/transportation-and-tours/tours/"}>
                  Discover More
                </CardButton>
              </div>
            </SubSectionHeader>
          </FeaturedContentWrapper>
        </FeatureBlock>
        <BgBlock>
          <FeatureBlock
            contentLeft={false}
            images={[
              getImage("/assets/hotel/Laluna-Hoian-Overview.jpg", "350square"),
              getImage("/assets/promotions/Promo_1.jpg", "350square"),
              getImage(
                "/assets/hotel/Laluna-Hotel-Overview-Sunrise.jpg",
                "350square"
              ),
            ]}
          >
            <FeaturedContentWrapper>
              <SubSectionHeader
                title={"Centrally Located in the Heart of Hoi An"}
              >
                {
                  "Set on the banks of the iconic Hoai River, our Laluna Hoi An Riverside Hotel gives a sense of serenity. Located in the midst of the historic and cultural Hoi An ancient town, Laluna Hoi An Riverside Hotel puts you at the centre of everything the city has to offer. The hotel is just a 3-minute walk from the famous Japanese Bridge in the heart of the city."
                }
              </SubSectionHeader>
            </FeaturedContentWrapper>
          </FeatureBlock>
        </BgBlock>
      </HomeLayout>
    </>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
