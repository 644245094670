import React, { useEffect, useMemo, useState } from "react";
import { Promotion, PromotionLocalization } from "../../backend-api/types";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import { graphql } from "gatsby";
import {
  ContentBox,
  Center,
  BgBlock,
  TextCenter,
} from "../../components/StandardComponents";

import { MainLayout2 } from "../../components/layouts";
import PromotionsDataProvider, {
  PromoDefArray,
} from "../../dataProviders/PromotionsDataProvider";
import { CardBox, CardTitleOverlay } from "../../components/card";
import { ensureArray } from "../../utils";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { getPromotions } from "../../backend-api/user-api";
import { getLocale } from "./promotionHelpers";
import { String } from "aws-sdk/clients/appstream";
import { Router } from "@reach/router";
import PromotionPageLayout from "./PromotionPageLayout";

const CardContentContainer = styled.div`
  width: 100%;
  & strong {
    font-weight: 500;
  }
  & h1 {
    font-size: 100%;
  }
`;

const PromotionList: React.FC<{
  locale: string;
  promotions: Array<Promotion>;
}> = ({ locale, promotions }) => {
  const [promotionsList, setPromotionsList] = useState<[Promotion] | null>(
    null
  );
  useEffect(() => {
    (async () => {
      setPromotionsList(await getPromotions());
    })();
  }, []);

  return (
    <>
      {promotions && (
        <CardBox>
          {(promotionsList || promotions).map((promotion, index) => {
            return (
              <CardTitleOverlay
                imageUrl={promotion.image}
                title={getLocale(promotion, locale)?.title}
                buttonText={"More Details"}
                buttonHref={`/promotions/${promotion.slug}`}
              >
                <CardContentContainer>
                  <TextCenter>
                    <ReactMarkdown
                      source={getLocale(promotion, locale)?.summary}
                      escapeHtml={false}
                    />
                  </TextCenter>
                </CardContentContainer>
              </CardTitleOverlay>
            );
          })}
        </CardBox>
      )}
    </>
  );
};

const IndexPage: React.FC<{
  pageContext: { promotions: Array<Promotion> };
  language: String;
  path?: string;
}> = (props) => {
  console.log(props.pageContext);

  const { t } = useTranslation();
  return (
    <MainLayout2
      pageTitle={t("Promotions")}
      pageBannerImg={"/assets/general-banner.jpg"}
      pageCrumbMap={[{ link: "/", name: t("Home") }]}
    >
      <ContentBox>
        <Center>
          {t(
            "Book direct with us and save more, below we have our current promotions and packages."
          )}
        </Center>
      </ContentBox>
      <BgBlock>
        <ContentBox>
          <PromotionList
            locale={props.language}
            promotions={props.pageContext.promotions}
          />
        </ContentBox>
      </BgBlock>
    </MainLayout2>
  );
};

const RouterPage: React.FC<{ pageContext: any; language: string }> = ({
  pageContext,
  language,
}) => {
  return (
    <Router basepath="/:lang/promotions">
      <IndexPage path="/" pageContext={pageContext} language={language} />
      <PromotionPageLayout
        path=":slug"
        pageContext={pageContext}
        language={language}
      />
    </Router>
  );
};

export default RouterPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
