import React, { ReactElement } from "react";
import { I18n, I18nTFunction } from "react-i18next";
import { ReactComponentLike } from "prop-types";
import { getTourData, getTour } from "./ToursData";

export interface TourSchedule {
  scheduleName: string;
  start: string;
  end: string;
}

export type TourSchedules = Array<TourSchedule>;

export interface PriceOption {
  price: number;
  currency: string;
  description: string;
  unit: string;
}

export type PriceOptions = Array<PriceOption>;

export interface TourOption {
  id: string;
  name: string;
  price: PriceOption | PriceOptions;
  minGroupSize?: number;
  maxGroupSize?: number;
  tourSchedules: TourSchedule | TourSchedules;
  tourSummary: string | Array<string>;
  tourPolicy?: string | Array<string>;
  tourIncludes?: string | Array<string>;
  tourExcludes?: string | Array<string>;
  tourItinerary: string | Array<string>;
  tourNotes?: string | Array<string>;
  tourImages: Array<string>;
}

export type TourOptions = Array<TourOption>;

/*const ToursList: TourOptions = [
  {
    id: "my-son-sanctuary-luxury-tour",
    name: "My Son Sanctuary Luxury Tour",
    price: [
      {
        price: 850000,
        unit: "Person",
        currency: "VND",
        description: "Adults, Children 10 years and older",
      },
      {
        price: 650000,
        unit: "Child",
        currency: "VND",
        description: "Children 4 to 9 years",
      },
      {
        price: 0,
        unit: "Child",
        currency: "VND",
        description: "Children younger then 4",
      },
    ],
    maxGroupSize: 12,
    tourSchedules: { start: "8 AM", end: "1:30 PM", scheduleName: "" },
    tourSummary: [
      "By following the trail of history, finding the vestiges of the ancients and reliving the memories of a civilization, we would understand more the value of life.",
      "We look forward to bringing you the most enjoyable experiences in your journeys by discovering the biggest center of Hindu temples in the central of Viet Nam named My Son, which was built around the 4th century. This would be a great chance to learn about the cultural diversity of Vietnam through 4000 years.",
      "With the combination of cultural tourism, culinary and sightseeing, we expect you to remember Viet Nam as a friendly, beautiful and diverse culinary culture country. Come and join us!",
    ],
    tourItinerary: [
      "Our guide pick you up at your hotel and depart to My Son Sanctuary – The World Cultural Heritage.",
      "Arrive at My Son and we’ll have 2 hours to discover the site by walking throughout the landscape, learning histories with our local English speaking guide, enjoying the Champa’s traditional performances.",
      "Get back to mini van and move to a local house to learn how to make ricepaper, you will enjoy your own hand-making product later on!",
      "Back to the van and have lunch at another local house, relax with cold herbal tea, nice foods and fresh air.",
      "Take a boat trip to Hoi An with nice and cool breeze on boarding, beautiful river scenery on the biggest river in the Central of vietnam named as Thu Bon River.",
      "Arrive at Hoi An, we will drop you at your hotel. Tour ends!",
    ],
    tourIncludes: [
      "A two ways hotel transfers pick up and drop off (100km round trip distance)",
      "The 150,000 VND entrance fees and 30,000 VND of electric car transfer",
      "Lunch will be served with Vietnamese Traditional foods",
      "Boat trip on Thu Bon River",
      "English speaking guide",
      "A bottle of water",
    ],
    tourExcludes: [
      "Personal expense services not mentioned above",
      "Drinks",
      "Tips and gratuities for guides and drivers",
    ],
    tourNotes: [
      "This tour is sold and operated by an independent tour company.",
      "This tour is not available from January 25th to 27th 2020",
    ],
    tourImages: ["/assets/rooms/Luxury/Luxury-1.jpg"],
  },
];

export const getTourData = () => {
  return ToursList;
}*/

export type GetTourFn = (id: string) => TourOption;

export const ToursDataProvider: ReactComponentLike = ({
  children,
}: {
  children: (tours: TourOptions, getTourFn: GetTourFn) => ReactElement;
}) => {
  return <>{children && children(getTourData(), getTour)}</>;
};
