import { RouteComponentProps } from "@reach/router";
import React, { useState, useEffect } from "react";
import {
  createUser,
  deleteUser,
  getUsers,
  updateUser,
  User,
} from "../../backend-api/admin-api";

import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Tag } from "primereact/tag";
import { UnauthorizedError } from "../../backend-api/api";
import { confirmPopup } from "./confirmPopupWrapper"; // To use confirmPopup method
import UserEditor from "./userEditor";
import PasswordChangeDialog from "./passwordChangeDialog";

interface UsersProps extends RouteComponentProps {
  messages: any;
}

const UsersIndex = (props: UsersProps) => {
  const [users, setUsers] = useState<[User] | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [editVisible, setEditVisible] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const loadUsers = async () => {
    try {
      const response = await getUsers();
      setUsers(response);
    } catch (ex) {
      props.messages.current.show({
        severity: "error",
        summary: "Load Failed:",
        detail: <>&nbsp;{ex.message}</>,
      });
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const saveUser = async (user: User) => {
    try {
      const response = user.id
        ? await updateUser(user.id, user)
        : await createUser(user);
      setEditVisible(false);
      setSelectedUser(null);
      loadUsers();
    } catch (ex) {
      props.messages.current.show({
        severity: "error",
        summary: "Save Failed:",
        detail: <>&nbsp;{ex.message}</>,
      });
    }
  };

  const removeUser = async (user: User) => {
    try {
      user && user.id && (await deleteUser(user.id));
      loadUsers();
    } catch (ex) {
      props.messages.current.show({
        severity: "error",
        summary: "Save Failed:",
        detail: <>&nbsp;{ex.message}</>,
      });
    }
  };

  const TableActionsBody = (rowData: User) => (
    <div className="flex justify-center">
      <div className={"pr-1"}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success"
          onClick={() => {
            setSelectedUser(rowData);
            setEditVisible(true);
          }}
        />
      </div>
      <div className={"pl-1 pr-1"}>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={(event) => {
            //setSelectedUser(rowData);
            //setDeleteConfirmVisible(true);
            confirmPopup({
              target: event.currentTarget,
              message: "Are you sure you want to proceed?",
              header: "Confirmation",
              icon: "pi pi-exclamation-triangle",
              accept: () => removeUser(rowData),
              reject: () => null,
            });
          }}
        />
      </div>
      <div className={"pl-1"}>
        <Button
          icon="pi pi-lock"
          className="p-button-rounded p-button-help"
          onClick={() => {
            setSelectedUser(rowData);
            setChangePassword(true);
          }}
        />
      </div>
    </div>
  );

  const TableActiveBody = (rowData: User) => {
    return (
      <>
        {rowData.enabled ? (
          <Tag
            icon="pi pi-check"
            value={"Active"}
            rounded={true}
            severity={"success"}
          />
        ) : (
          <Tag
            icon="pi pi-times"
            value={"Disabled"}
            rounded={true}
            severity={"danger"}
          />
        )}
      </>
    );
  };

  const TableRolesBody = (rowData: User) => (
    <>
      {rowData?.roles?.length > 0 ? (
        <>
          {rowData.roles.map((item, i) => (
            <Chip key={`${rowData.username}-${item}-${i}`} label={item} />
          ))}
        </>
      ) : (
        <>None</>
      )}
    </>
  );

  return (
    <div className="container p-10">
      <Button
        icon={"pi pi-plus"}
        label={"Add User"}
        onClick={() => {
          setSelectedUser(null);
          setEditVisible(true);
        }}
      />
      {users && (
        <DataTable value={users}>
          <Column field="name" header={"Name"}></Column>
          <Column field="username" header={"Username"}></Column>
          <Column field="email" header={"Email"}></Column>
          <Column field="roles" header={"Roles"} body={TableRolesBody}></Column>
          <Column
            field="enabled"
            header={"Active"}
            body={TableActiveBody}
          ></Column>
          <Column body={TableActionsBody} />
        </DataTable>
      )}
      <UserEditor
        visible={editVisible}
        onSave={saveUser}
        onClose={() => setEditVisible(false)}
        user={selectedUser}
      />
      {changePassword && (
        <PasswordChangeDialog
          title={`Set Password: ${selectedUser?.name}`}
          onCancel={() => setChangePassword(false)}
          onSave={(password) => {
            setChangePassword(false);
            const temp = selectedUser as User;
            temp.password = password;
            saveUser(temp);
          }}
        />
      )}
    </div>
  );
};

export default UsersIndex;
