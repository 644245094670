import React, { useEffect, useMemo, useReducer } from "react";
import { RouteComponentProps } from "@reach/router";
import slugify from "slugify";

import { InputText } from "primereact/inputtext";

import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";

import { BadRequestError } from "../../../backend-api/api";
import {
  ContentLocalization,
  ContentItem,
  ContentItemState,
  ContentItemType,
} from "../../../backend-api/types";
import { ImageUpload } from "../../components/imageUpload";
import { SelectLanguageDialog } from "./selectLanguageDialog";

import { LocaleEditable } from "./types";
import { LocaleTabMenu } from "./localeTabMenu";
import useContentDetails from "./contentDetailsHook";
import { useContentItem } from "../../hooks/contentHooks";
import { Dropdown } from "primereact/dropdown";
import { LocalizedView } from "./localizedView";
import { contentEditorUploadUrl } from "../../../backend-api/admin-api";
import { getContentTypeDisplayValue } from "../contentTypes";

interface ContentItemComponentProps extends RouteComponentProps {
  id?: string;
  messages: any;
}

const Details = (props: ContentItemComponentProps) => {
  const [state, { activeLocale, ...fns }] = useContentDetails();
  const content = useContentItem(props.id);

  const isNew = () => props.id === "new";

  const localeToPromoLocale = (locale: LocaleEditable): ContentLocalization => {
    return {
      content: locale.content,
      locale: locale.locale,
      summary: locale.summary,
      title: locale.title,
      default: locale.default,
      seoImage: locale.seoImage,
      image: locale.image,
      slug: locale.slug,
    };
  };

  const doSaveOrUpdate = async (debounced = false) => {
    if (!debounced) {
      //allow onBlurs to process
      setTimeout(doSaveOrUpdate, 100, true);
      return;
    }

    const localizations: Array<ContentLocalization> = [];

    for (let l of state.locales) {
      localizations.push(localeToPromoLocale(l));
    }

    const toSave: ContentItem = {
      localizations,
      contentType: state.contentType,
      publishState: state.publishState,
      publishDate: null,
    };

    console.log(toSave);

    if (!isNew() && props.id) {
      toSave.id = props.id;
    }

    content.upsert(toSave, {
      onSuccess: () => {
        props.messages.current.show({
          severity: "success",
          summary: "Save Successful",
        });

        props.navigate && props.navigate("../");
      },
      onError: (err) => {
        if (err instanceof Error) {
          //console.log(err);
          props.messages.current.show({
            severity: "error",
            summary: "Save failed!",
            detail: <>&nbsp;{err.message}</>,
          });
        } else {
          throw err;
        }
      },
    });
  };

  useEffect(() => {
    if (isNew() && state.locales.length === 0) {
      fns.dispatchShowNewLanguageDialog(true);
    } else {
      if (props.id && content.get.isSuccess && content.get.data) {
        fns.dispatchInit(content.get.data);
      }
    }
  }, [props.id, content.get.isSuccess, content.get.data]);

  return (
    <>
      <div className={"flex justify-end "}>
        <Button
          icon={"pi pi-save"}
          label={"Save"}
          onClick={() => doSaveOrUpdate()}
        />
      </div>
      <div className={"flex"}>
        <label className={"pr-2 text-xl"}>Content Type: </label>
        <Dropdown
          options={Object.values(ContentItemType).map((v) => ({
            value: v,
            label: getContentTypeDisplayValue(v),
          }))}
          value={state.contentType}
          onChange={(evt) => fns.dispatchContentType(evt.value)}
        />
      </div>
      <div className={"flex text-l items-center"}>
        <label className={"pr-2 text-xl"}>Content Published: </label>
        <InputSwitch
          checked={state.publishState === ContentItemState.published}
          onChange={() =>
            fns.dispatchContentState(
              state.publishState === ContentItemState.published
                ? ContentItemState.draft
                : ContentItemState.published
            )
          }
        />
      </div>
      <LocaleTabMenu
        locales={state.locales}
        setSelectedLocale={fns.dispatchSetSelectedLocale}
      >
        {activeLocale && (
          <LocalizedView
            title={activeLocale.title}
            titleChange={fns.dispatchSeletedTitle}
            summary={activeLocale.summary}
            summaryChange={fns.dispatchSeletedSummary}
            seoImageUrl={activeLocale.seoImage}
            seoImageChange={fns.dispatchSEOImageChange}
            imageUrl={activeLocale.image}
            imageChange={fns.dispatchUpdateSelectedImage}
            details={activeLocale.content}
            detailsChange={fns.dispatchSeletedDetails}
            slug={activeLocale.slug}
            slugChange={(slug) => fns.dispatchUpdateSelectedSlug(slug, true)}
            titleBlur={() =>
              !activeLocale.slugUpdatedManually &&
              fns.checkSlugAndDispatchUpdate(
                activeLocale.title,
                false,
                content.get.data?.id
              )
            }
            slugBlur={(slug) =>
              fns.checkSlugAndDispatchUpdate(slug, false, content.get.data?.id)
            }
            editorUploadUrl={contentEditorUploadUrl}
          ></LocalizedView>
        )}
      </LocaleTabMenu>
      <Button
        icon={"pi pi-plus"}
        label={"New Translation"}
        onClick={() => {
          fns.dispatchShowNewLanguageDialog(true);
        }}
      />
      {state.showNewLanguageDialog && (
        <SelectLanguageDialog
          onDone={(value: string) => {
            fns.dispatchAddNewLocale(value);
            fns.dispatchShowNewLanguageDialog(false);
          }}
          onCancel={() => fns.dispatchShowNewLanguageDialog(false)}
        />
      )}
    </>
  );
};

export default Details;
