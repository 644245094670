import React, { ImgHTMLAttributes, useState, useEffect } from "react";
import styled from "styled-components";
//import useIntersect from "../useIntersect"
import { useInView } from "react-intersection-observer";

interface OuterContainerProps {
  paddingBottom: Number;
  backgroundColor: string;
}

const OuterContainer = styled.div<OuterContainerProps>`
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  padding-bottom: ${(props) => `${props.paddingBottom}%`};
  overflow: hidden;
`;

const InnerContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;

interface RationContainerProps {
  backgroundColor: string;
  heightRatio: number;
  widthRatio: number;
}

const RatioContainer: React.FC<RationContainerProps> = ({
  backgroundColor,
  heightRatio,
  widthRatio,
  children,
}) => {
  const getPadding = () => {
    return 100 * (heightRatio / widthRatio);
  };
  return (
    <OuterContainer
      backgroundColor={backgroundColor}
      paddingBottom={getPadding()}
    >
      <InnerContainer>{children}</InnerContainer>
    </OuterContainer>
  );
};

export default RatioContainer;

const RatioContainerImageStyle = styled.img`
  /*Need this to make image fill container to bottom*/
  display: block;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  border: none;
`;

const RatioContainerImage: React.FC<ImgHTMLAttributes<any>> = (props) => {
  const [image, setImage] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
  );

  const [srcSetImage, setSrcSet] = useState("");

  const { src, srcSet, onLoad, ...filteredProps } = props;
  const [loaded, setLoaded] = useState(false);
  //const [ref, entry] = useIntersect({threshold: [0] })

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const loadImage = () => {
    if (!loaded && src && inView) {
      setLoaded(true);
      setSrcSet(srcSet || "");
      setImage(src);
      //setLoaded(true);
      console.log(`image loaded [${src}]`);
    }
  };
  loadImage();
  useEffect(() => {
    setLoaded(false);
  }, [src, srcSet]);

  //console.log(entry);
  /*const [loaded,setLoaded] = useState(false);

  

  const loadImage = () => {
    if (src) {
      setImage(src);
      setLoaded(true);
      console.log(`image loaded [${src}]`);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!loaded) {
        console.log("timeout");
        loadImage();
      }
    }, 500);
    return () => clearTimeout(timeout);
  });*/

  return (
    <RatioContainerImageStyle
      src={image}
      srcSet={srcSetImage}
      {...filteredProps}
      ref={ref}
    ></RatioContainerImageStyle>
  );
};

export { RatioContainerImage };
