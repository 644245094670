import React from "react";
import NewsPageLayout from "./NewsPageLayout";


interface DynamicProps {
  slug?: string;
  lang?: string;
}

const IndexPage: React.FC<DynamicProps> = ({
  slug,
  lang,
}) => (
  <>
    <NewsPageLayout
      pageContext={{
        slug,
        language: lang ,
      }}
    />
  </>
);

export default IndexPage;
