import React from "react";
import { ListBox, ListBoxChangeEvent } from "primereact/listbox";
import { SupportedLocales } from "../../helpers/supportedLocales";

type ListBoxChangeFn = (e: ListBoxChangeEvent) => void;

export const LanguageListBox = ({
  onChange,
  value,
}: {
  onChange: ListBoxChangeFn;
  value: string;
}) => (
  <ListBox
    options={SupportedLocales}
    optionLabel={"label"}
    optionValue={"locale"}
    value={value}
    onChange={onChange}
  />
);
