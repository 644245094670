import React from "react";
import styled, { css } from "styled-components";

const TableStyle = styled.div`
  & > :nth-child(even) {
    background-color: #efefef;
  }
`;

const StyledTableRow = styled.div`
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  :last-child {
    border-bottom: none;
  }
`;

const StyledTableCell = styled.div`
  display: flex;
  flex: 1;
  padding: 15px 20px;
  border-right: 1px solid #e1e1e1;
  :last-child {
    border-right: none;
  }
`;

interface ListTableProps {
  children?: Array<String>;
}

const Table: React.FC<ListTableProps> = ({ children }) => {
  const getChildrenRows = () => {
    if (children) {
      const rows: Array<Array<JSX.Element>> = [];
      let row: Array<JSX.Element> = [];
      children.forEach((element, index) => {
        const WrappedElement = <StyledTableCell>{element}</StyledTableCell>;

        if (index % 2 || index === children.length - 1) {
          row.push(WrappedElement);

          if (index % 2 === 0 && index === children.length - 1) {
            row.push(<StyledTableCell />);
          }

          rows.push(row);
          //clear row
          row = [];
        } else {
          row.push(WrappedElement);
        }
      });

      return rows;
    }
    return [[<></>]];
  };

  return (
    <TableStyle>
      {children &&
        getChildrenRows().map((row, index) => (
          <StyledTableRow key={index}>{row}</StyledTableRow>
        ))}
    </TableStyle>
  );
};

export default Table;
