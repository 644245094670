import styled from "styled-components";
import { RouteDescriptionCompositeInterface } from "./Types";
import React from "react";

const RouteDescription = styled.div``;

const RouteStopBase = styled.div`
  display: flex;
  border-left: 2px dotted grey;
  margin-left: 10px;
  margin-bottom: 2px;
  margin-top: 2px;
  padding-left: 10px;
  position: relative;
  min-height: 12px;
`;

const RouteStop = styled(RouteStopBase)`
  ::before {
    content: "";
    position: absolute;
    border: 1px solid ${(props) => props.theme.darkColor};
    border-radius: 4px;
    width: 8px;
    height: 8px;
    top: 5px;
    left: -6px;
    background-color: ${(props) => props.theme.colorOneLighter};
  }
`;

const RouteStopOption = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;
const RouteStopOptionSeparator = styled(RouteStopOption)`
  font-weight: 500;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  align-items: center;
`;

const RouteStart = styled.div`
  display: flex;
  position: relative;
  margin-left: 22px;
  ::before {
    content: "";
    width: 10px;
    border-bottom: 2px solid black;
    position: absolute;
    top: 9px;
    left: -16px;
  }
`;
const RouteEnd = styled.div`
  display: flex;
  position: relative;
  margin-left: 22px;
  ::before {
    content: "";
    width: 10px;
    border-bottom: 2px solid black;
    position: absolute;
    top: 9px;
    left: -16px;
  }
`;

const RouteEndMessage = styled.div`
  display: flex;
  margin-right: 4px;
  flex-shrink: 0;
`;

export const DriverItenerary: React.FC<RouteDescriptionCompositeInterface> = ({
  start,
  end,
  stops,
}) => {
  const startArray = Array.isArray(start) ? start : [start];
  const endArray = Array.isArray(end) ? end : [end];
  const stopsArray = stops
    ? stops.map((item) => (Array.isArray(item) ? item : [item]))
    : [];
  return (
    <RouteDescription>
      <RouteStart>
        {startArray.map((option, index) => (
          <>
            <RouteStopOption key={option}>{option}</RouteStopOption>
            {index < startArray.length - 1 && startArray.length > 1 && (
              <RouteStopOptionSeparator>or</RouteStopOptionSeparator>
            )}
          </>
        ))}
      </RouteStart>
      {stopsArray.length === 0 ? (
        <RouteStopBase></RouteStopBase>
      ) : (
        stopsArray.map((stop, index) => (
          <RouteStop key={`stop-${index}`}>
            {stop.map((option, index) => (
              <>
                <RouteStopOption key={option}>{option}</RouteStopOption>
                {index < stop.length - 1 && stop.length > 1 && (
                  <RouteStopOptionSeparator>or</RouteStopOptionSeparator>
                )}
              </>
            ))}
          </RouteStop>
        ))
      )}
      <RouteEnd>
        <RouteEndMessage>
          {endArray.length === 1 && endArray[0] === start
            ? "Return to"
            : "Drop off at"}
        </RouteEndMessage>
        {endArray.map((option, index) => (
          <>
            <RouteStopOption key={option}>{option}</RouteStopOption>
            {index < endArray.length - 1 && endArray.length > 1 && (
              <RouteStopOptionSeparator>or</RouteStopOptionSeparator>
            )}{" "}
          </>
        ))}
      </RouteEnd>
    </RouteDescription>
  );
};
