import React, { useState } from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import styled, { css } from "styled-components";
import DatePicker from "./datepicker";
import InputText from "./textbox";
import StyledButton from "./button";
import moment from "moment";

const BookingContainerDesktopCss = css`
  position: relative;
  z-index: 1000;
  margin-top: -50px;
  height: 50px;
  width: 700px;
  background-color: rgba(255, 255, 255, 0.4);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const BookingContainerMobileCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &&&& > * {
    margin: 5px;

    & :hover {
      border-color: white;
    }

    & > input :hover {
      border-color: white;
    }
  }
  background-color: #1c1c1c;
`;

const BookingContainer = styled.div`
  @media only screen and (min-width: 720px) {
    ${BookingContainerDesktopCss}
  }
  @media only screen and (max-width: 720px) {
    ${BookingContainerMobileCss}
  }
`;

export const BuildBookingUrl = (
  adults: number | null,
  children: number | null,
  checkin: string | null,
  checkout: string | null,
  promotionCode: string | null = 'DIRECTSAVE',
) => {
  const url = new URL('https://app-apac.thebookingbutton.com/properties/lalunahoianriversidehoteldirect')
  if(checkin) url.searchParams.append('check_in_date', checkin)
  if(checkout) url.searchParams.append('check_out_date', checkout)
  if(adults) url.searchParams.append('number_adults', adults.toString())
  if(children) url.searchParams.append('number_children', children.toString())
  if(promotionCode) url.searchParams.append('promotion_code', promotionCode)
  return url.href
};

const Booking: React.FC = () => {
  const { t } = useTranslation();
  const [inDate, setInDate] = useState();
  const [outDate, setOutDate] = useState();
  const [adults, setAdults] = useState();
  const [children, setChildren] = useState();

  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  const RedirectToBooking = () => {
    const inDateMoment = moment(inDate);
    const outDateMoment = moment(outDate);
    openInNewTab(
      BuildBookingUrl(
        adults || 1,
        children || 0,
        inDateMoment.format("DD-MM-YYYY"),
        outDateMoment.format("DD-MM-YYYY")
      )
    );
  };

  return (
    <BookingContainer>
      <DatePicker
        placeholder={t("Check-in")}
        onChange={(e) => setInDate(e.target.value)}
        value={inDate}
      ></DatePicker>
      <DatePicker
        placeholder={t("Check-out")}
        onChange={(e) => setOutDate(e.target.value)}
        value={outDate}
      ></DatePicker>
      <InputText
        placeholder={t("Adults")}
        image={"/assets/user-regular-datepicker.svg"}
        onChange={(e) => setAdults(e.target.value)}
        value={adults}
      ></InputText>
      <InputText
        placeholder={t("Children")}
        image={"/assets/child-solid-datepicker.svg"}
        onChange={(e) => setChildren(e.target.value)}
        value={children}
      ></InputText>
      <StyledButton text={t("Book Now")} onClick={() => RedirectToBooking()} />
    </BookingContainer>
  );
};

export default Booking;
