import React from "react";
//import styled from "styled-components";
import { Link as UnstyledLink } from "gatsby-plugin-react-i18next";
import { ChevronLeftIcon } from "./icons";
import { styled } from "../styles/ThemeProvider";
import { ImageFadeCarouselWithFilmStrip } from "./carousel";
import { withTheme, ThemeProps } from "styled-components";

export const Link = styled(UnstyledLink)`
  text-decoration: none;
`;

export const StandardLink = styled.a`
  text-decoration: none;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /*background-color: pink;*/
`;

const StyledPage = styled.div`
  flex: 1;
`;

export const Page: React.FC<{children: React.ReactNode}> = ({ children }) => (
  <StyledContainer>
    <StyledPage>{children}</StyledPage>
  </StyledContainer>
);

export const ContentBox = styled.div`
  display: flex;
  max-width: 1024px;
  width: 100%;
  //background-color: red;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  padding: 5px;
  //adjust later for spacing
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const BgBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${(props) =>
    props.theme.components.standardComponents.bgBlockBackgroundColor};
  color: ${(props) =>
    props.theme.components.standardComponents.bgBlockForegroundColor};
`;
export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeaderWithDivider = styled(ContentBox)`
  border-bottom: 2px solid
    ${(props) => props.theme.components.standardComponents.headerDividerColor};
  margin-top: 5px;
  margin-bottom: 5px;
  ${(props) => props.color && `color: ${props.color};`}
`;

export const MobileOnly = styled.div`
  display: none;
  visibility: hidden;
  @media only screen and (max-width: 660px) {
    display: unset;
    visibility: unset;
  }
`;

export const DesktopOnly = styled.div`
  display: none;
  visibility: hidden;
  @media only screen and (min-width: 660px) {
    display: unset;
    visibility: unset;
  }
`;

export const PageTitle = styled.span`
  font-family: ${(props) =>
    props.theme.components.standardComponents.pageTitleFont};
  font-size: ${(props) =>
    props.theme.components.standardComponents.pageTitleFontSize};
`;

export const SubSectionTitle = styled.span`
  font-family: ${(props) =>
    props.theme.components.standardComponents.subSectionTitleFont};
  font-size: ${(props) =>
    props.theme.components.standardComponents.subSsectionTitleFontSize};
  /* font-weight: bold; */
`;

interface SectionHeaderProps {
  title: string;
  titleColor?: string;
}

export const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextCenter = styled.span`
  text-align: center;
`;

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  children,
}) => {
  return (
    <VerticalFlex>
      <HeaderWithDivider>
        <Center>
          <PageTitle>{title}</PageTitle>
        </Center>
      </HeaderWithDivider>
      {children && (
        <Center>
          <TextCenter>{children}</TextCenter>
        </Center>
      )}
    </VerticalFlex>
  );
};

export const SubSectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  children,
  titleColor,
}) => {
  return (
    <VerticalFlex>
      <HeaderWithDivider color={titleColor}>
        <Center>
          <TextCenter>
            <SubSectionTitle>{title}</SubSectionTitle>
          </TextCenter>
        </Center>
      </HeaderWithDivider>
      {children && (
        <Center>
          <TextCenter>{children}</TextCenter>
        </Center>
      )}
    </VerticalFlex>
  );
};

const BreadCrumbStyle = styled.div`
  & > a {
    color: grey;
    margin-right: 5px;
    & > img {
      margin-right: 3px;
    }
  }
`;

export interface CrumbInterface {
  link: string;
  name: string;
}

export type CrumbArray = Array<CrumbInterface>;

export const BreadCrumb: React.FC<{
  crumbMap: CrumbArray;
}> = ({ crumbMap }) => {
  return (
    <BreadCrumbStyle>
      {crumbMap &&
        crumbMap.map((item, index) => (
          <Link key={index} to={item.link}>
            <ChevronLeftIcon Width={7} Filter={"invert(1) brightness(65%)"} />
            {item.name}
          </Link>
        ))}
    </BreadCrumbStyle>
  );
};

const FeatureBlockStyle = styled.div<{ contentLeft: boolean }>`
  display: flex;
  @media only screen and (max-width: 660px) {
    flex-direction: ${(props) =>
      !props.contentLeft ? "column" : "column-reverse"};
  }
`;
const FeatureBlockCarouselContainer = styled.div`
  align-self: center;
  width: 350px;
  @media only screen and (max-width: 660px) {
    //width: 250px;
  }
`;

const FeatureBlockContentContainer = styled.div`
  //background-color: blue;
  flex: 1;
`;
interface FeatureBlockProps {
  contentLeft?: boolean;
  images: Array<string>;
}
export const FeatureBlock: React.FC<FeatureBlockProps | ThemeProps<any>> =
  withTheme(({ theme, contentLeft = false, images, children }) => {
    const MergeTheme = {
      components: {
        ...theme.components,
        carousel: theme.components.standardComponents.carousel,
      },
    };
    //console.log(images);
    return (
      <ContentBox>
        <FeatureBlockStyle contentLeft={contentLeft}>
          {contentLeft && (
            <>
              <FeatureBlockContentContainer>
                {children}
              </FeatureBlockContentContainer>
              <FeatureBlockCarouselContainer>
                <ImageFadeCarouselWithFilmStrip
                  theme={MergeTheme}
                  widthRatio={1}
                  heightRatio={1}
                  images={images}
                  transitionTime={1}
                  imageTimeout={3}
                  noFilmStrip={true}
                ></ImageFadeCarouselWithFilmStrip>
              </FeatureBlockCarouselContainer>
            </>
          )}
          {!contentLeft && (
            <>
              <FeatureBlockCarouselContainer>
                <ImageFadeCarouselWithFilmStrip
                  theme={MergeTheme}
                  widthRatio={1}
                  heightRatio={1}
                  images={images}
                  transitionTime={1}
                  imageTimeout={3}
                  noFilmStrip={true}
                ></ImageFadeCarouselWithFilmStrip>
              </FeatureBlockCarouselContainer>
              <FeatureBlockContentContainer>
                {children}
              </FeatureBlockContentContainer>
            </>
          )}
        </FeatureBlockStyle>
      </ContentBox>
    );
  });
