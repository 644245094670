import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import {
  Page,
  ContentBox,
  Center,
  SubSectionHeader,
  SectionHeader,
} from "../StandardComponents";
import Nav from "../navbar";
import {
  LalunaDarkColor,
  LalunaOverlayColor,
  NavbarOverlayColorOpacity,
  LalunaAccentColorLighter,
  LalunaAccentColor,
} from "../../constants";
import Booking, { BuildBookingUrl } from "../booking";
import Footer from "../footer";
import ThemeProvider from "../../styles/ThemeProvider";
import RatioContainer, {
  RatioContainerImage,
} from "../../components/RatioContainer";
import { getImage } from "../../../lib/image-resolve";

const NegativeMarginContainer = styled.div`
  margin-top: -35px;
`;

/*const CoverContainer = styled.div`
  position: relative;
  margin-top: -35px;
  background-color: #1c1c1c;
  width: 100%;
  //height: 56.25%;
  padding-bottom: 56.25%;

  & > div {
    position: absolute;
    top: 0;
    width: 100%;

    & > img {
      //Need this to make image fill container to bottom
      display: block;
      max-width: 100%;
      max-height: 100%;
      min-width: 100%;
      min-height: 100%;
      border: none;
    }
  }
`;*/

const HomeLayout: React.FC = ({ children }) => {
  /*const [cover, setCover] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
  );
  const [loaded, setLoaded] = useState(false);
  const onCoverLoad = () => {
    setCover("/assets/cover1_16x9.jpg");
    setLoaded(true);
    console.log("loaded cover");
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!loaded) {
        console.log("timeout");
        onCoverLoad();
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, []);
*/

  const { t } = useTranslation();

  const BottomNavLinks = [
    { displayName: t("Rooms & Amenities"), href: "/rooms/" },
    {
      displayName: t("Restaurant & Bar"),
      href: "/restaurant-and-bar/",
    },
    { displayName: t("Spa Services"), href: "/spa/" },
    {
      displayName: t("Transportation & Tours"),
      href: "/transportation-and-tours/",
    },
    { displayName: t("Promotions"), href: "/promotions/" },
    { displayName: t("News"), href: "/news/" },
  ];

  return (
    <ThemeProvider>
      <>
        <Page>
          <Nav
            TopNavBackgroundColor={LalunaDarkColor}
            BottomNavBackgroundColor={LalunaOverlayColor}
            BottomNavBackgroundColorOpacity={NavbarOverlayColorOpacity}
            BottomNavLinks={BottomNavLinks}
            BottomLinkHoverColor={LalunaAccentColorLighter}
            BottomNavBackgroundColorMobile={LalunaAccentColor}
            BottomNavBackgroundColorOpacityMobile={1}
          ></Nav>
          {/*<CoverContainer>
                  <div>
                    <img onLoad={onCoverLoad} src={cover} />
                  </div>
                </CoverContainer>*/}
          <NegativeMarginContainer>
            <RatioContainer
              widthRatio={16}
              heightRatio={9}
              backgroundColor={"#1c1c1c"}
            >
              <RatioContainerImage
                src={getImage("/assets/cover2_16x9.jpg")}
                srcSet={`${getImage(
                  "/assets/cover2_16x9.jpg",
                  "500wide"
                )} 500w, ${getImage(
                  "/assets/cover2_16x9.jpg",
                  "900wide"
                )} 900w, ${getImage(
                  "/assets/cover2_16x9.jpg",
                  "1200wide"
                )} 1200w, ${getImage(
                  "/assets/cover2_16x9.jpg",
                  "1700wide"
                )} 1700w, ${getImage(
                  "/assets/cover2_16x9.jpg",
                  "1900wide"
                )} 1900w, ${getImage(
                  "/assets/cover2_16x9.jpg",
                  "default"
                )} 1901w`}
                sizes={
                  "(min-width: 1900px) 1900px, (min-width: 1700px) 1700px, (min-width: 1200px) 1200px, (min-width: 900px) 900px, (min-width: 500px) 500px, 100vw"
                }
              ></RatioContainerImage>
              <div className="absolute top-0 min-w-full min-h-full flex flex-col justify-center">
                <div className="self-center bg-gray-800 bg-opacity-75 p-5 rounded-lg">
                  <SubSectionHeader title="Exclusive Deals" titleColor="white">
                    <div className="text-white text-2xl">
                      book direct and receive 10% discount
                    </div>
                    <div className="m-5">
                      <button
                        className="font-playfair text-xl italic bg-accent text-white py-2 px-4 border-accent-light rounded-md shadow-md hover:bg-accent-light focus:outline-none focus:ring-2 focus:ring-accent-light focus:ring-opacity-75"
                        onClick={() => {
                          window
                            .open(
                              BuildBookingUrl(
                                null,
                                null,
                                null,
                                null,
                                "DIRECTSAVE"
                              ),
                              "_blank"
                            )
                            .focus();

                          if (window.gtag) {
                            window.gtag("event", "button_click", {
                              button_name: "main-booking-cta",
                              debug: true,
                            });
                          }
                        }}
                      >
                        Book Now!
                      </button>
                    </div>
                  </SubSectionHeader>
                </div>
              </div>
            </RatioContainer>
          </NegativeMarginContainer>
          <Booking></Booking>
          {children}
        </Page>
        <Footer></Footer>
      </>
    </ThemeProvider>
  );
};

export default HomeLayout;
