import React from "react";
import styled from "styled-components";
import {
  Link as UnstyledLink,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import {
  FacebookIcon,
  TripadvisorIcon,
  InstagramIcon,
  TwitterIcon,
  MapMarkedIcon,
  EnvelopeIcon,
  HeadsetIcon,
  WhatsAppIcon,
} from "../icons";
//import FreshChat from "../Freshchat";
import { BuildBookingUrl } from "../booking";
import { TawkToMessenger } from "../TawkTo";
import SocialLinks from "../SocialLinks";

const Link = styled(UnstyledLink)`
  text-decoration: none;
`;

const StandardLink = styled.a`
  text-decoration: none;
`;

const SocialLinkWrapper: React.FC<{
  href: string;
  Icon: React.FC;
  standardLink?: boolean;
}> = ({ href, Icon, standardLink }) => {
  return (
    <>
      {standardLink ? (
        <StandardLink href={href}>
          <Icon />
        </StandardLink>
      ) : (
        <Link to={href}>
          <Icon />
        </Link>
      )}
    </>
  );
};

const SocialIconContainer = styled.div`
  display: flex;
  margin-right: 20px;
  & > a {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;

    & :first-child {
      margin-left: 0px;
    }

    & :last-child {
      margin-right: 0px;
    }
  }
`;

const ActionLabel = styled.span`
  color: white;
  //font-size: 13px;
  font-size: 85%;
  margin-left: 10px;
  @media only screen and (max-width: 1000px) {
    font-size: 75%;
  }

  @media only screen and (max-width: 800px) {
    font-size: 65%;
  }

  @media only screen and (max-width: 768px) {
    font-size: 55%;
  }
`;

const ActionContainerStyle = styled.span`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const ActionContainer: React.FC<{
  href?: string;
  standardLink?: boolean;
  action?: () => void;
}> = ({ children, href, standardLink, action }) => {
  if (action) {
    return (
      <Button onClick={action}>
        <ActionContainerStyle>{children}</ActionContainerStyle>
      </Button>
    );
  } else if (standardLink) {
    return (
      <StandardLink href={href}>
        <ActionContainerStyle>{children}</ActionContainerStyle>
      </StandardLink>
    );
  } else {
    return (
      <Link to={href}>
        <ActionContainerStyle>{children}</ActionContainerStyle>
      </Link>
    );
  }
};

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: auto;
  & > a {
    margin-left: 10px;
    margin-right: 10px;

    & :first-child {
      margin-left: 0px;
    }

    & :last-child {
      margin-right: 0px;
    }
  }
`;

const LogoPath = "/assets/Laluna-Logo-150x150.png";

interface Props {
  backColor: string;
}

const TopNavContainer = styled.div<Props>`
  background-color: ${(props) => props.backColor};
  height: 70px;
  display: flex;
  z-index: 20;
`;

const LogoImageContainer = styled.div`
  display: flex;
  //background-color: pink;
  height: 100%;
  align-items: center;
  padding: 10px;
`;

const BookNowButton = styled.button`
  background: ${(props) => props.theme.colorOne};
  color: white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding-left: 20px;
  padding-right: 20px;
  //margin-right: 30px ;
  font-weight: 500;
  font-size: 1.2em;
  font-family: ${(props) => props.theme.fontSubTitle};
  border: 2px solid #1c1c1c;
  :hover {
    background: ${(props) => props.theme.colorOneLighter};
  }
`;

const TopNav: React.FC<Props> = ({ backColor }) => {
  const { t } = useTranslation();
  return (
    <TopNavContainer backColor={backColor}>
      {/*<FreshChat></FreshChat>*/}
      <TawkToMessenger />
      <Link to={"/"}>
        <LogoImageContainer>
          <img src={LogoPath} alt="logo" height="48" width="48" />
        </LogoImageContainer>
      </Link>
      <ActionsContainer>
        <ActionContainer
          standardLink={true}
          href="https://maps.google.com/maps?ll=15.876447,108.321697&z=16&t=m&hl=en&gl=US&mapclient=embed&q=12%20Nguy%E1%BB%85n%20Du%20Ph%C6%B0%E1%BB%9Dng%20C%E1%BA%A9m%20Ph%E1%BB%95%20H%E1%BB%99i%20An%20Qu%E1%BA%A3ng%20Nam%2C%20Vietnam"
        >
          <MapMarkedIcon />
          <ActionLabel>{t("Map Us")}</ActionLabel>
        </ActionContainer>
        <ActionContainer href="/contact-us/">
          <EnvelopeIcon />
          <ActionLabel>{t("Contact Us")}</ActionLabel>
        </ActionContainer>
        {/*<ActionContainer action={() => window.fcWidget.open()}>
          <HeadsetIcon />
          <ActionLabel>{t("Chat With Us")}</ActionLabel>
  </ActionContainer>*/}
      </ActionsContainer>
      <SocialLinks />
      <BookNowButton onClick={() => (window.location.href = BuildBookingUrl())}>
        Book Now
      </BookNowButton>
    </TopNavContainer>
  );
};

export default TopNav;
