import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Message } from "primereact/message";

interface PasswordChangeProps {
  onSave: (password: string) => void;
  onCancel: () => void;
  confirm?: boolean;
  title: string;
}

const PasswordChangeDialog = (props: PasswordChangeProps) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [failedValidation, setFailedValidation] = useState(false);

  const footer = (onSave: () => void, onDiscard: () => void) => (
    <div>
      <Button label="Save" icon="pi pi-check" onClick={onSave} />
      <Button label="Discard" icon="pi pi-times" onClick={onDiscard} />
    </div>
  );

  const validate = (checkPassword?: string) => {
    const valid = checkPassword
      ? password === checkPassword
      : password === confirmPassword;
    if (password !== "" && confirmPassword !== "") {
      setFailedValidation(!valid);
    } else {
      return false;
    }
    return valid;
  };

  const saveAndValidate = () => {
    if (props.confirm && validate()) {
      props.onSave(password);
    } else if (!props.confirm) {
      props.onSave(password);
    }
  };

  return (
    <Dialog
      header={props.title}
      footer={footer(saveAndValidate, props.onCancel)}
      visible={true}
      modal
      onHide={props.onCancel}
    >
      <span className="p-float-label mt-6">
        <Password
          id={"password"}
          value={password}
          onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
          feedback={false}
          className="w-56"
          onBlur={() => validate()}
        ></Password>
        <label htmlFor={`password`}>New Password</label>
      </span>
      {props.confirm && (
        <>
          <span className="p-float-label mt-6">
            <Password
              id={"confirmPassword"}
              value={confirmPassword}
              onChange={(e) => {
                const newValue = (e.target as HTMLInputElement).value;
                setConfirmPassword(newValue);
                if (failedValidation) validate(newValue);
              }}
              feedback={false}
              className={failedValidation ? "w-56 p-invalid" : "w-56"}
              onBlur={() => validate()}
            ></Password>
            <label htmlFor={`confirmPassword`}>confirm Password</label>
          </span>
          {failedValidation && (
            <Message severity="error" text="Passwords do not match"></Message>
          )}
        </>
      )}
    </Dialog>
  );
};

export default PasswordChangeDialog;
