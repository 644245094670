import React, { ReactElement } from "react";
import { ReactComponentLike } from "prop-types";
import { MenuDefArray } from "../components/PurchaseMenu/Types";

const RestaurantMenuDataProvider: ReactComponentLike = ({
  children,
}: {
  children: (menuData: MenuDefArray) => ReactElement;
}) => {
  const menuData: MenuDefArray = [
    {
      name: "Vietnamese",
      /*img: "/assets/restaurant/pho-125x125.jpg",*/
      sections: [
        {
          name: "Starters",
          images: [
            "/assets/restaurant/restaurant-menu/vietnamese/starter_1.jpg",
            "/assets/restaurant/restaurant-menu/vietnamese/starter_2.jpg",
            "/assets/restaurant/restaurant-menu/vietnamese/starter_3.jpg",
          ],
          items: [
            {
              name: "Laluna style grilled pork in bamboo tube",
              options: [{ price: 185000 }],
              description:
                "Pork, pickled vegetables, Vietnamese herbs, rice paper served with our chef’s special dressing",
            },
            {
              name: "Fresh summer rolls",
              options: [{ price: 95000 }],
              description:
                "Pork char-siu, shrimp, Vietnamese pickled vegetables and Tra Que herbs all wrapped in rice paper",
            },
            {
              name: "Banana flower salad with prawns",
              options: [{ price: 105000 }],
              description:
                "Banana blossoms, prawns, carrots, onions, mint leaves, dried shallots and peanuts",
            },
            {
              name: "Minced beef wrapped with wild betel leave",
              options: [{ price: 105000 }],
              description:
                "Minced beef, wild betel leave served with peanut sauce",
            },
            {
              name: "Hoi An crispy spring rolls",
              options: [{ price: 95000 }],
              description:
                "Shrimp, pork, ear mushroom, vermicelli, carrots, taro, Vietnamese herbs all wrapped in rice paper and fried",
            },
            {
              name: "Chicken skewers",
              options: [{ price: 185000 }],
              description:
                "Chicken, Vietnamese pickled vegetables and herbs served with peanut sauce",
            },
          ],
        },
        {
          name: "Soup Selection",
          images: [
            "/assets/restaurant/restaurant-menu/vietnamese/soup_1.jpg",
            "/assets/restaurant/restaurant-menu/vietnamese/soup_2.jpg",
          ],
          items: [
            {
              name: "Wonton and mushroom soup",
              options: [{ price: 95000 }],
              description: "Wontons, mushroom, bok-choy",
            },
            {
              name: "Chicken and baby corn soup",
              options: [{ price: 95000 }],
            },
            {
              name: "Seafood and mushroom soup",
              options: [{ price: 105000 }],
            },
          ],
        },
        {
          name: "Main Courses",
          images: [
            "/assets/restaurant/restaurant-menu/vietnamese/main_course_section_1_1.jpg",
            "/assets/restaurant/restaurant-menu/vietnamese/main_course_section_1_2.jpg",
            "/assets/restaurant/restaurant-menu/vietnamese/main_course_section_1_3.jpg",
          ],
          items: [
            {
              name: "Vietnamese style chicken wings",
              options: [{ price: 155000 }],
              description:
                "Chicken wings, lemongrass, chili, garlic, fish sauce and lettuce",
            },
            {
              name: "Stir-fried beef noodles",
              options: [{ price: 185000 }],
              description:
                "Beef tenderloin, noodles, carrots, onions, bok-choy and bean sprouts",
            },
            {
              name: "Vietnamese style barbecue baby back ribs",
              options: [{ price: 215000 }],
              description:
                "Pork ribs and barbecue sauce served with steamed rice",
            },
            {
              name: "Seabass grilled in banana leaf",
              options: [{ price: 215000 }],
              description:
                "Seabass served with stir-fried bok-choy and steamed rice",
            },
            {
              name: "Tiger prawns with satée sauce",
              options: [{ price: 205000 }],
              description:
                "Prawn, lemongrass, chili, onions, spring onions and satée sauce served steamed rice",
            },
            {
              name: "Fish in clay pot",
              options: [{ price: 185000 }],
              description:
                "Mackerel fish, galangal and caramel served steamed rice",
            },
          ],
        },
        {
          images: [
            "/assets/restaurant/restaurant-menu/vietnamese/main_course_section_2_1.jpg",
            "/assets/restaurant/restaurant-menu/vietnamese/main_course_section_2_2.jpg",
          ],
          items: [
            {
              name: "Shrip and pork in clay pot",
              options: [{ price: 185000 }],
              description: "River shrimp and pork served with steamed rice",
            },
            {
              name: "Fried Rice",
              options: [
                { description: "Vegetable", price: 100000 },
                { description: "Egg", price: 90000 },
                { description: "Chicken", price: 115000 },
                { description: "Pork", price: 115000 },
                { description: "Prawns", price: 145000 },
              ],
              /*description:
                "Traditional vietnamese fried rice with your choice of protein",*/
            },
            {
              name: "Indian curry with steamed rice",
              options: [
                { description: "Chicken", price: 155000 },
                { description: "Vegetable", price: 115000 },
                { description: "Prawn", price: 185000 },
              ],
            },
          ],
        },
        {
          name: "Side Dishes",
          images: [
            "/assets/restaurant/restaurant-menu/vietnamese/side_1.jpg",
            "/assets/restaurant/restaurant-menu/vietnamese/side_2.jpg",
          ],
          items: [
            {
              name: "Wok-fried water spinach and garlic",
              options: [{ price: 75000 }],
            },
            {
              name: "Stir-fried mushroom & mixed vegetables",
              options: [{ price: 75000 }],
            },
            {
              name: "Steamed rice",
              options: [{ price: 35000 }],
            },
            {
              name: "Congee",
              options: [
                { description: "Plain", price: 40000 },
                { description: "Vegetable", price: 50000 },
                { description: "Pork", price: 70000 },
                { description: "Chicken", price: 70000 },
                { description: "Beef", price: 90000 },
                { description: "Prawn", price: 90000 },
              ],
            },
          ],
        },
        {
          name: "Hoi An Street Food",
          images: [
            "/assets/restaurant/restaurant-menu/vietnamese/hoi_an_street_1.jpg",
            "/assets/restaurant/restaurant-menu/vietnamese/hoi_an_street_2.jpg",
            "/assets/restaurant/restaurant-menu/vietnamese/hoi_an_street_3.jpg",
          ],
          items: [
            {
              name: "White rose",
              options: [{ price: 85000 }],
            },
            {
              name: "Crispy wonton",
              options: [{ price: 95000 }],
              description:
                "Fried wontons filled with shrimp and served sweet & sour sauce",
            },
            {
              name: "Pho",
              options: [
                { description: "Beef", price: 95000 },
                { description: "Chicken", price: 85000 },
              ],
            },
            {
              name: "Cao Lau",
              options: [{ price: 85000 }],
              description: "Pork char-siu, Tra Que herbs and Cao Lau noodles",
            },
            {
              name: "Quang noodles",
              options: [{ price: 95000 }],
              description:
                "Shrimp, pork, quail eggs, noodles and Tra Que herbs",
            },
          ],
        },
      ],
    },
    {
      name: "Western",
      sections: [
        {
          name: "Starters",
          images: [
            "/assets/restaurant/restaurant-menu/western/starter_1.jpg",
            "/assets/restaurant/restaurant-menu/western/starter_2.jpg",
            "/assets/restaurant/restaurant-menu/western/starter_3.jpg",
          ],
          items: [
            {
              name: "Laluna prawn salad",
              options: [{ price: 165000 }],
              description:
                "Tiger prawn flambé with cognac, quail egg, tomato salsa, lettuce, cocktail sauce",
            },
            {
              name: "Caesar salad",
              options: [
                { description: " ", price: 100000 },
                { description: "Add Chicken", price: 135000 },
                { description: "Add Prawn", price: 165000 },
              ],
              /*description: "Roman lettuce, Caesar sauce, bacon, croutons",*/
            },
            {
              name: "Greek salad",
              options: [{ price: 105000 }],
              description:
                "Capsicum, onions, zucchini, feta cheese, lettuce, balsamic dressing",
            },
            {
              name: "Mixed garden salad",
              options: [{ price: 75000 }],
            },
            {
              name: "Supreme Nachos",
              options: [{ price: 215000 }],
              description:
                "Minced beef, white bean, jalapeno chili, Doritos chips covered with melted cheese",
            },
            {
              name: "Calamari rings",
              options: [{ price: 135000 }],
              description: "Served plum chili sauce and lime",
            },
            {
              name: "Fried prawns",
              options: [{ price: 145000 }],
              description: "Deep fried crispy prawn served cocktail sauce",
            },
          ],
        },

        {
          name: "Soup",
          images: [
            "/assets/restaurant/restaurant-menu/western/soup_1.jpg",
            "/assets/restaurant/restaurant-menu/western/soup_2.jpg",
          ],
          items: [
            {
              name: "Pumpkin soup",
              options: [{ price: 95000 }],
              description: "Pumpkin soup served with garlic bread",
            },
            {
              name: "Seafood bisque soup",
              options: [{ price: 155000 }],
              description:
                "Prawn, fish, calamari, scallops served with croutons",
            },
            {
              name: "Green pea soup",
              options: [{ price: 95000 }],
              description: "Served with sour cream and garlic bread",
            },
          ],
        },
        {
          name: "Main Course",
          images: [
            "/assets/restaurant/restaurant-menu/western/main_course_section_1_1.jpg",
            "/assets/restaurant/restaurant-menu/western/main_course_section_1_2.jpg",
            "/assets/restaurant/restaurant-menu/western/main_course_section_1_3.jpg",
            "/assets/restaurant/restaurant-menu/western/main_course_section_1_4.jpg",
          ],
          items: [
            {
              name: "Black Angus beef tenderloin",
              options: [{ price: 375000 }],
              description:
                "Australia beef tenderloin, Stir-fried water spinach, vegetables, served with a choice red wine sauce or pepper sauce.",
            },
            {
              name: "Salmon Laksa",
              options: [{ price: 235000 }],
              description:
                "Salmon fillet, noodles, bok-choy, bean sprout, Laksa sauce",
            },
            {
              name: "Teriyaki Duck breast",
              options: [{ price: 215000 }],
              description:
                "Duck, roasted pumpkin, vegetables served with teriyaki sauce",
            },
            {
              name: "Oregano Chicken breast",
              options: [{ price: 215000 }],
              description:
                "Mashed potatoes, Vietnamese pickled vegetable served mushroom gravy",
            },
          ],
        },
        {
          images: [
            "/assets/restaurant/restaurant-menu/western/main_course_section_2_1.jpg",
            "/assets/restaurant/restaurant-menu/western/main_course_section_2_2.jpg",
            "/assets/restaurant/restaurant-menu/western/main_course_section_2_3.jpg",
          ],
          items: [
            {
              name: "Lamb racks",
              options: [{ price: 435000 }],
              description:
                "Lamb racks, eggplant confit, sautéed mix vegetables served with pepper sauce or rosemary gravy",
            },
            {
              name: "Pork schnitzel",
              options: [{ price: 155000 }],
              description:
                "Pork, egg, bread crumble served with mixed salad and onion gravy",
            },
            {
              name: "Fish and chips",
              options: [{ price: 165000 }],
              description:
                "Seabass with a side of french fries served with tartar sauce & lime",
            },
            {
              name: "Chicken tortilla",
              options: [{ price: 165000 }],
              description:
                "Tortilla, chicken, lemongrass, chili served french fries",
            },
            {
              name: "Mixed grill",
              options: [{ price: 185000 }],
              description: "Sausage, egg, tomato, french fries, bacon",
            },
          ],
        },
        {
          name: "Side Dishes",
          images: [
            "/assets/restaurant/restaurant-menu/western/side_1.jpg",
            "/assets/restaurant/restaurant-menu/western/side_2.jpg",
            "/assets/restaurant/restaurant-menu/western/side_3.jpg",
          ],
          items: [
            {
              name: "French fries",
              options: [{ price: 55000 }],
            },
            {
              name: "Potato wedges",
              options: [{ price: 55000 }],
            },
            {
              name: "Steamed vegetables",
              options: [{ price: 75000 }],
            },
            {
              name: "Mashed potatoes",
              options: [{ price: 75000 }],
            },
            {
              name: "Onion rings",
              options: [{ price: 55000 }],
            },
          ],
        },
        {
          name: "Sandwiches",
          images: [
            "/assets/restaurant/restaurant-menu/western/sandwich_1.jpg",
            "/assets/restaurant/restaurant-menu/western/sandwich_2.jpg",
          ],
          items: [
            {
              name: "Ham and cheese sandwich",
              options: [{ price: 105000 }],
              description: "Ham, cheese, white toast served with french fries",
            },
            {
              name: "Club sandwich",
              options: [{ price: 195000 }],
              description:
                "Chicken, bacon, egg, cheese, lettuce, tomatoes served with french fries",
            },
            {
              name: "Steak sandwich",
              options: [{ price: 235000 }],
              description:
                "Panini bread, beef tenderloin, caramelized onion, tomato, lettuce served French fried and mixed salad",
            },
            {
              name: "Aussie burger",
              options: [{ price: 205000 }],
              description:
                "Beef, onions, egg, pickled cucumber, lettuce serve french fries and mixed salad",
            },
          ],
        },
        {
          name: "Italian Pasta",
          images: [
            "/assets/restaurant/restaurant-menu/western/pasta_1.jpg",
            "/assets/restaurant/restaurant-menu/western/pasta_2.jpg",
          ],
          items: [
            {
              name: "Bolognaise",
              options: [{ price: 195000 }],
              description:
                "Minced beef, carrots, red wine, celery, onions, garlic, choice of spaghetti or penne",
            },
            {
              name: "Carbonara",
              options: [{ price: 195000 }],
              description:
                "Bacon, onions, mushroom, whipping cream, egg yolk, choice of spaghetti or penne",
            },
            {
              name: "Tomato with Italian basil",
              options: [{ price: 125000 }],
              description: "Choice of spaghetti or penne",
            },
            {
              name: "Arrabbiata",
              options: [{ price: 135000 }],
              description:
                "Tomato sauce, cherry tomato, chili oil, onions, choice of spaghetti or penne",
            },
            {
              name: "Marinara",
              options: [{ price: 225000 }],
              description:
                "Tomato sauce, onions, seafood, chili sauce, white wine, choice of spaghetti or penne",
            },
          ],
        },
        {
          name: "Pizza",
          images: [
            "/assets/restaurant/restaurant-menu/western/pizza_1.jpg",
            "/assets/restaurant/restaurant-menu/western/pizza_2.jpg",
          ],
          items: [
            {
              name: "Margherita",
              options: [{ price: 125000 }],
              description:
                "Tomato sauce, fresh tomatoes, Italian basil, mozzarella cheese",
            },
            {
              name: "Hawaiian",
              options: [{ price: 145000 }],
              description:
                "Ham, pineapple, tomatoes, corn, mozzarella cheese, tomato sauce",
            },
            {
              name: "Acqua",
              options: [{ price: 225000 }],
              description:
                "Spinach, ham, onions, mushroom, buffalo cheese, mozzarella cheese, tomato sauce",
            },
            {
              name: "Caponata",
              options: [{ price: 155000 }],
              description:
                "Mixed vegetables, black olives, tomato sauce, mozzarella cheese",
            },
            {
              name: "Diavola",
              options: [{ price: 175000 }],
              description: "Salami, tomato sauce, mozzarella cheese, olive oil",
            },
            {
              name: "Ocean",
              options: [{ price: 245000 }],
              description:
                "Seafood, ginger, basil, chili, tomato sauce, mozzarella cheese",
            },
          ],
        },
      ],
    },
    {
      name: "Dessert",
      sections: [
        {
          images: [
            "/assets/restaurant/restaurant-menu/dessert/dessert_1.jpg",
            "/assets/restaurant/restaurant-menu/dessert/dessert_2.jpg",
            "/assets/restaurant/restaurant-menu/dessert/dessert_3.jpg",
          ],
          items: [
            { name: "Seasonal Fruit", options: [{ price: 75000 }] },
            { name: "Banana Flambée", options: [{ price: 95000 }] },
            { name: "Crème Bruleé", options: [{ price: 85000 }] },
            {
              name: "Crépe",
              options: [
                { description: "Pineapple", price: 75000 },
                { description: "Banana", price: 75000 },
              ],
            },
            {
              name: "Fruit salad",
              options: [{ price: 75000 }],
            },
            {
              name: "Ice Cream",
              options: [{ description: "Per Scoop", price: 45000 }],
              description: "Choice of vanilla, chocolate and strawberry",
            },
          ],
        },
      ],
    },
  ];
  return children && children(menuData);
};

export default RestaurantMenuDataProvider;
