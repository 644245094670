import React, { useEffect, useReducer } from "react";
import { RouteComponentProps } from "@reach/router";
import slugify from "slugify";

import { InputText } from "primereact/inputtext";

import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";

import {
  createPromotion,
  updatePromotion,
  checkSlugPromotion,
  getPromotion,
} from "../../../backend-api/admin-api";
import { BadRequestError } from "../../../backend-api/api";
import { PromotionLocalization, Promotion } from "../../../backend-api/types";
import { ImageUpload } from "../../components/imageUpload";
import { getLangForLocale } from "./supportedLocales";
import { SelectLanguageDialog } from "./selectLanguageDialog";
import {
  reducer,
  stateInit,
  StateInterface,
  DetailsReducerAction,
  ActionKind,
} from "./detailsReducer";

import { LocaleEditable } from "./types";
import { LocaleTabMenu } from "./localeTabMenu";
import { Boolean } from "aws-sdk/clients/cloudsearch";

interface PromotionComponentProps extends RouteComponentProps {
  id?: string;
  messages: any;
}

const Details = (props: PromotionComponentProps) => {
  const [state, dispatch] = useReducer<
    React.Reducer<StateInterface, DetailsReducerAction>,
    string | undefined | null
  >(reducer, null, stateInit);

  const isNew = () => props.id === "new";
  const dispatchShowNewLanguageDialog = (show: boolean) =>
    dispatch({ type: ActionKind.ShowNewLangDialog, payload: show });
  const dispatchAddNewLocale = (locale: string) =>
    dispatch({
      type: ActionKind.AddLocale,
      payload: {
        locale,
        label: getLangForLocale(locale),
        title: "",
        active: true,
        summary: "",
        data: "",
        default: false,
        seoImage: "",
      },
    });

  const dispatchSetSelectedLocale = (localeEditable: LocaleEditable) =>
    dispatch({ type: ActionKind.SetSelectedLocale, payload: localeEditable });

  const dispatchSeletedDetails = (details: string) =>
    dispatch({
      type: ActionKind.UpdateSelectedDetails,
      payload: details,
    });
  const dispatchSeletedSummary = (summary: string) =>
    dispatch({
      type: ActionKind.UpdateSelectedSummary,
      payload: summary,
    });

  const dispatchSeletedTitle = (title: string) =>
    dispatch({
      type: ActionKind.UpdateSelectedTitle,
      payload: title,
    });

  const dispatchUpdateSlug = (slug: string, manual: Boolean) =>
    dispatch({
      type: ActionKind.UpdateSlug,
      payload: { slug, manualUpdate: manual },
    });

  const dispatchUpdatePromotionImage = (url: string) =>
    dispatch({ type: ActionKind.UpdatePromotionImage, payload: url });

  const dispatchPromotionEnabled = (enabled: boolean) =>
    dispatch({ type: ActionKind.UpdatePromotionEnabled, payload: enabled });

  const dispatchSEOImageChange = (url: string) =>
    dispatch({ type: ActionKind.UpdateSelectedSEOImage, payload: url });

  const checkSlugAndDispatchUpdate = async (slug: string, manual: boolean) => {
    const toSet = await (await checkSlugPromotion(slugify(slug))).slug;
    dispatchUpdateSlug(toSet, manual);
  };

  const localeToPromoLocale = (
    locale: LocaleEditable
  ): PromotionLocalization => {
    return {
      data: locale.data,
      locale: locale.locale,
      summary: locale.summary,
      title: locale.title,
      default: locale.default,
      seoImage: locale.seoImage,
    };
  };

  const doSaveOrUpdate = async (debounced = false) => {
    if (!debounced) {
      //allow onBlurs to process
      setTimeout(doSaveOrUpdate, 100, true);
      return;
    }

    const localizations = [] as Array<PromotionLocalization>;
    for (let l of state.locales) {
      localizations.push(localeToPromoLocale(l));
    }

    const toSave = {
      slug: state.slug,
      localizations,
      enabled: state.promotionEnabled,
      image: state.promotionImage,
    } as Promotion;

    console.log(toSave);
    try {
      if (!isNew() && props.id) {
        toSave["id"] = props.id;
        await updatePromotion(props.id, toSave);
      } else {
        await createPromotion(toSave);
      }
    } catch (err) {
      if (err instanceof BadRequestError) {
        //console.log(err);
        props.messages.current.show({
          severity: "error",
          summary: "Save failed!",
        });
        return;
      }
      throw err;
    }

    props.messages.current.show({
      severity: "success",
      summary: "Save Successful",
    });
    props.navigate && props.navigate("../");
  };

  useEffect(() => {
    if (isNew() && state.locales.length === 0) {
      dispatchShowNewLanguageDialog(true);
    } else {
      (async () => {
        if (props.id) {
          const result = await getPromotion(props.id);
          dispatch({ type: ActionKind.DetailsInit, payload: result });
        }
      })();
    }
  }, [props.id]);

  return (
    <>
      <div className={"flex justify-end "}>
        <Button
          icon={"pi pi-save"}
          label={"Save"}
          onClick={() => doSaveOrUpdate()}
        />
      </div>
      <div className={"flex text-l items-center"}>
        <label className={"pr-2 text-xl"}>Promotion Active: </label>
        <InputSwitch
          checked={state.promotionEnabled}
          onChange={() => dispatchPromotionEnabled(!state.promotionEnabled)}
        />
      </div>
      <LocaleTabMenu
        locales={state.locales}
        selectedDetailsChange={dispatchSeletedDetails}
        selectedTitleChange={dispatchSeletedTitle}
        selectedSummaryChange={dispatchSeletedSummary}
        setSelectedLocale={dispatchSetSelectedLocale}
        selectedTitleBlur={(selected) =>
          !state.slugUpdatedManually &&
          state.locales[0] === selected &&
          checkSlugAndDispatchUpdate(selected.title, false)
        }
        selectedSEOImageChange={dispatchSEOImageChange}
      />
      <Button
        icon={"pi pi-plus"}
        label={"New Translation"}
        onClick={() => {
          dispatchShowNewLanguageDialog(true);
        }}
      />
      <div className={"mt-2 mb-2"}>
        <label htmlFor="promotion image" className={"pr-2 text-xl"}>
          Promotion Image
        </label>
        <ImageUpload
          imageHeight={238}
          imageWidth={298}
          initialImageUrl={state.promotionImage}
          onChangeImage={dispatchUpdatePromotionImage}
        />
      </div>
      <div className={"p-2 pl-0"}>
        <label htmlFor="slug" className={"pr-2 text-xl"}>
          Slug
        </label>
        <InputText
          id="slug"
          value={state.slug}
          onChange={(e) => {
            dispatchUpdateSlug((e.target as HTMLInputElement).value, true);
          }}
          onBlur={(e) =>
            checkSlugAndDispatchUpdate(
              (e.target as HTMLInputElement).value,
              false
            )
          }
        />
      </div>
      {state.showNewLanguageDialog && (
        <SelectLanguageDialog
          onDone={(value: string) => {
            dispatchAddNewLocale(value);
            dispatchShowNewLanguageDialog(false);
          }}
          onCancel={() => dispatchShowNewLanguageDialog(false)}
        />
      )}
    </>
  );
};

export default Details;
