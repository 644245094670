import { Promotion, ContactUsOptions, ContentItem } from "./types";
import { createResource, getAllResource, resourceCustomFunction } from "./api";

export const getPromotions = async () => {
  return getAllResource<[Promotion]>("promotions");
};

export const getPromotionBySlug = async (slug: string) => {
  return resourceCustomFunction<null, Promotion>(
    "GET",
    "promotions",
    `slug/${slug}`,
    null
  );
};

export const getContactUsForm = async () => {
  return getAllResource<ContactUsOptions>("contact-us");
};

export const submitContactUsForm = async (
  payload: any,
  recaptchaKey: string
) => {
  return createResource<any>("contact-us", payload, {
    headers: { RECAPTCHA: recaptchaKey },
  });
};

export const getContent = async () => {
  return getAllResource<Array<ContentItem>>("content")
}

export const getContentBySlug = async (slug: string) => {
  return resourceCustomFunction<null, ContentItem>(
    "GET",
    "content",
    `slug/${slug}`,
    null
  );
};