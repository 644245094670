import React from "react";
import styled from "styled-components";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { Page, ContentBox, Center } from "../StandardComponents";
import Nav from "../navbar";
import { LalunaDarkColor, LalunaAccentColor } from "../../constants";
import Footer from "../footer";
import ThemeProvider from "../../styles/ThemeProvider";

const MainLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const BottomNavLinks = [
    { displayName: t("Home"), href: "/" },
    { displayName: t("Rooms & Amenities"), href: "/rooms/" },
    { displayName: t("Restaurant & Bar"), href: "/restaurant-and-bar/" },
    { displayName: t("Spa Services"), href: "/spa/" },
    {
      displayName: t("Transportation & Tours"),
      href: "/transportation-and-tours/",
    },
    { displayName: t("Promotions"), href: "/promotions/" },
    { displayName: t("News"), href: "/news/" },
  ];

  return (
    <ThemeProvider>
      <Page>
        <Nav
          TopNavBackgroundColor={LalunaDarkColor}
          BottomNavBackgroundColor={LalunaAccentColor}
          BottomNavBackgroundColorOpacity={1}
          BottomNavLinks={BottomNavLinks}
          BottomLinkHoverColor={LalunaDarkColor}
          BottomNavBackgroundColorMobile={LalunaAccentColor}
          BottomNavBackgroundColorOpacityMobile={1}
        ></Nav>
        {children}
      </Page>
      <Footer></Footer>
    </ThemeProvider>
  );
};

export default MainLayout;
