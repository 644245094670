import React from "react";
import { css } from "styled-components";
import CardButton from "../card/CardButton";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import {
  ContentBox,
  BreadCrumb,
  SectionHeader,
  SubSectionHeader,
} from "../StandardComponents";
import RoomDataProvider, {
  RoomDefArray,
  GetRoomFn,
} from "../../dataProviders/RoomDataProvider";
import GlobalStyle from "../../styles/GlobalStyle";
import { MainLayout } from ".";
import { TableList } from "../../components/table";
import {
  ImageFadeCarouselWithFilmStrip,
  RotateCarousel,
} from "../../components/carousel";
import { styled } from "../../styles/ThemeProvider";
import { BuildBookingUrl } from "../booking";

const Spacer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const AmenitiesContainer = styled.div`
  border: 1px solid
    ${(props) =>
      props.theme.components.layouts.room.amenitiesContainerBorderColor};
  display: flex;
  flex-direction: column;
`;

const AmenitiesTitle = styled.div`
  align-self: center;
  font-family: ${(props) =>
    props.theme.components.layouts.room.amenitiesTitleFontFamily};
  font-weight: ${(props) =>
    props.theme.components.layouts.room.amenitiesTitleFontWeight};
  font-size: ${(props) =>
    props.theme.components.layouts.room.amenitiesTitleFontSize};
  border-bottom: 1px solid
    ${(props) => props.theme.components.layouts.room.amenitiesTitleLineColor};
  margin-bottom: 20px;
  margin-top: 10px;
`;

interface RoomLayoutProps {
  RoomType:
    | "luxury"
    | "suite"
    | "family suite"
    | "super deluxe"
    | "deluxe"
    | "superior";
}

const RoomLayout: React.FC<RoomLayoutProps> = ({ RoomType }) => {
  const { t } = useTranslation();
  return (
    <RoomDataProvider>
      {(_roomData: RoomDefArray, getRoom: GetRoomFn) => {
        const Room = getRoom(RoomType);
        return (
          <>
            <GlobalStyle />
            <MainLayout>
              <ContentBox>
                <BreadCrumb
                  crumbMap={[
                    { link: "/", name: t("Home") },
                    { link: "/rooms", name: t("Rooms & Amenities") },
                  ]}
                />
                <SectionHeader title={Room.roomName}></SectionHeader>
                <ImageFadeCarouselWithFilmStrip
                  widthRatio={16}
                  heightRatio={10}
                  images={Room.images}
                  transitionTime={1}
                  imageTimeout={2}
                ></ImageFadeCarouselWithFilmStrip>
                <Spacer>
                  <SubSectionHeader title={Room.caption}>
                    {Room.longDescription}
                  </SubSectionHeader>
                </Spacer>
                <AmenitiesContainer>
                  <AmenitiesTitle>Room Amenities</AmenitiesTitle>
                  <TableList>{Room.amenities}</TableList>
                </AmenitiesContainer>
                <div className="mt-10 flex justify-center">
                  <CardButton href={BuildBookingUrl()} standardLink={true}>
                    Book Now
                  </CardButton>
                </div>
              </ContentBox>
            </MainLayout>
          </>
        );
      }}
    </RoomDataProvider>
  );
};

export default RoomLayout;
