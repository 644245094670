import { ContentItem, ContentItemType, ContentLocalization } from "./types";

export const getLocale = (
  contentItem: ContentItem,
  locale: string
): ContentLocalization | null => {
  if (contentItem && contentItem.localizations) {
    let defaultLocale = null;
    let specifed = null;
    contentItem.localizations.forEach((item) => {
      if (item.default) {
        defaultLocale = item;
      }

      if (item.locale === locale) {
        specifed = item;
      }
    });
    return specifed || defaultLocale;
  }
  return null;
};

export const filterContentType = (type: ContentItemType) => (content: ContentItem) => content.contentType === type
