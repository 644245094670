import React, { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const Buffer = require("buffer").Buffer;

export function ensureArray<T>(possibleArray: T | Array<T>): Array<T> {
  if (!Array.isArray(possibleArray)) {
    return [possibleArray];
  }
  return possibleArray;
}

//this or the above are the same
/*export const ensureArray = <T extends unknown>(possibleArray: T | Array<T>) : Array<T> => {
    if(!Array.isArray(possibleArray))
    {
        return [possibleArray];
    }
    return possibleArray;
}*/

export function encodeObject(object: any): string {
  return encodeURIComponent(
    Buffer.from(JSON.stringify(object)).toString("base64")
  );
  //return btoa(JSON.stringify(object));
}

export function decodeObject(data: string): any {
  return JSON.parse(Buffer.from(decodeURIComponent(data), "base64").toString());
  //return JSON.parse(atob(data));
}

export function getEncodedData(): any {
  if (window && window.location) {
    var search = window.location.search;
    search = search.substring(1);
    const kv = search.split("&");
    const found = kv
      .map((x) => x.split("="))
      .find((x) => x[0].toLowerCase() === "encoded");
    if (found) return decodeObject(found[1]);
  }
  return null;
}

export function withQueryClient<T extends object>(WrappedComponet: React.ComponentType<T>) {
  return (props: T) => {
    const queryClient = new QueryClient();
    return (
      <QueryClientProvider client={queryClient}>
        <WrappedComponet {...props}/>
      </QueryClientProvider>
    );
  };
}
