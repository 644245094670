import { useMutation, useQuery, useQueryClient } from "react-query";
import { deletePromotion, getPromotions } from "../../backend-api/admin-api";

export const usePromotions = () => {
  const queryClient = useQueryClient();

  const { data, isError, isLoading, refetch } = useQuery(
    "promotions",
    getPromotions
  );

  const { mutate: deletePromotionMutation } = useMutation(
    (id: string) => {
      return deletePromotion(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("promotions");
      },
    }
  );

  return {
    delete: deletePromotionMutation,
    get: {
      data,
      refetch,
      isError,
      isLoading,
    },
  };
};
