import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageListBox } from "./languageListBox";

export const SelectLanguageDialog = ({
  onCancel,
  onDone,
}: {
  onCancel: () => void;
  onDone: (locale: string) => void;
}) => {
  const [locale, setLocale] = useState("en");

  const footer = (onSave: () => void) => (
    <div>
      <Button label="Ok" icon="pi pi-check" onClick={onSave} />
    </div>
  );

  return (
    <>
      <Dialog
        visible={true}
        header={"Select Language"}
        footer={footer(() => locale && onDone(locale))}
        style={{ width: "25vw" }}
        onHide={onCancel}
      >
        <LanguageListBox value={locale} onChange={(e) => setLocale(e.value)} />
      </Dialog>
    </>
  );
};
