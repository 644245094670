import React from "react";
import CustomEditor from "@candq/ckeditor5-build";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfiguration } from "./config";
import "./styles.css";

interface EditorProps {
  url: string;
  data: string;
  dataUpdated: (data: string) => void;
}

const Editor = ({ url, dataUpdated, data }: EditorProps) => {
  return (
    <div className={"editor-classic"}>
      <CKEditor
        editor={CustomEditor}
        config={editorConfiguration(url)}
        data={data}
        onReady={(editor: any) => {
          // You can store the "editor" and use when it is needed.
          //console.log("Editor is ready to use!", editor);
        }}
        onChange={(event: HTMLElementEventMap, editor: any) => {
          const data = editor.getData();
          console.log({ event, editor, data });
          if (dataUpdated) {
            dataUpdated(data);
          }
        }}
        onBlur={(event: HTMLElementEventMap, editor: any) => {
          /*console.log("Blur.", editor);*/
        }}
        onFocus={(event: HTMLElementEventMap, editor: any) => {
          /*console.log("Focus.", editor);*/
        }}
      />
    </div>
  );
};

export default Editor;
