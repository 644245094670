import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Cropper from "react-easy-crop";

export interface CropAreaState {
  x: number;
  y: number;
  width: number;
  height: number;
}

export type cropCancelFn = () => void;
export type cropCanvasFn = (base64cropped: string) => void;
export type cropAreaFn = (
  cropArea: CropAreaState,
  base64InputImage: string
) => void;

export interface CropDialogBasicProps {
  onCancel: cropCancelFn;
  imagefile: File;
  aspect: number;
}

export interface CropDialogCanvasCropProps {
  onCanvasCrop: cropCanvasFn;
  outputWidth: number;
  outputHeight: number;
}

export interface CropDialogCropAreaProps {
  onCropArea: cropAreaFn;
}

export type CombinedCropDialogCropAreaProps = CropDialogCropAreaProps &
  CropDialogBasicProps;
export type CombinedCropDialogCanvasCropProps = CropDialogCanvasCropProps &
  CropDialogBasicProps;
export type AllPossibleProps = CropDialogCanvasCropProps &
  CropDialogCropAreaProps &
  CropDialogBasicProps;
export type CropDialogProps =
  | CombinedCropDialogCropAreaProps
  | CombinedCropDialogCanvasCropProps
  | AllPossibleProps;

type CropDialogType = React.FC<CropDialogProps>;

type footerOkFn = () => void;

const dialogFooter = (onOk: footerOkFn) => (
  <div>
    <Button label="Ok" icon="pi pi-check" onClick={() => onOk()} />
  </div>
);

export const fileToDataUrl = (file: File) =>
  new Promise(
    (resolve: (value: string | ArrayBuffer | null) => void, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    }
  );

export const browserCanvasCrop = (
  imgSrc: string,
  cropAreaPixels: CropAreaState,
  width: number,
  height: number
) => {
  let imageStore = new Image();
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");

  return new Promise<string>((resolve) => {
    imageStore.onload = () => {
      canvas.width = width;
      canvas.height = height;

      ctx?.drawImage(
        imageStore,
        cropAreaPixels.x,
        cropAreaPixels.y,
        cropAreaPixels.width,
        cropAreaPixels.height,
        0,
        0,
        width,
        height
      );
      resolve(canvas.toDataURL());
    };

    imageStore.src = imgSrc;
  });
};

const CropDialog: CropDialogType = ({
  aspect,
  onCancel,
  imagefile,
  ...otherProps
}) => {
  const [base64Image, setBase64Image] = useState<string>();
  const [cropState, setCropState] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropAreaPixels, setCropAreaPixels] = useState<CropAreaState>();

  useEffect(() => {
    (async () => {
      const result = await fileToDataUrl(imagefile);
      if (result && typeof result === "string") {
        setBase64Image(result);
      }
    })();
  }, [imagefile]);

  const cropDone = async () => {
    const canvasCropProps = otherProps as CropDialogCanvasCropProps;
    const cropAreaProps = otherProps as CropDialogCropAreaProps;

    if (
      canvasCropProps &&
      canvasCropProps.onCanvasCrop &&
      canvasCropProps.outputHeight &&
      canvasCropProps.outputWidth &&
      cropAreaPixels &&
      base64Image
    ) {
      const result = await browserCanvasCrop(
        base64Image,
        cropAreaPixels,
        canvasCropProps.outputWidth,
        canvasCropProps.outputHeight
      );
      setTimeout(() => canvasCropProps.onCanvasCrop(result), 0);
    }

    if (
      cropAreaProps &&
      cropAreaProps.onCropArea &&
      cropAreaPixels &&
      base64Image
    ) {
      setTimeout(() => cropAreaProps.onCropArea(cropAreaPixels, base64Image), 0);
    }
  };

  return (
    <>
      <Dialog
        visible={true}
        header={"Crop Your Image"}
        footer={dialogFooter(cropDone)}
        style={{ width: "50vw" }}
        onHide={onCancel}
      >
        <div className={"relative"} style={{ height: "60vh" }}>
          {base64Image && (
            <Cropper
              restrictPosition={false}
              zoomSpeed={0.3}
              minZoom={0.8}
              image={base64Image}
              zoom={zoom}
              crop={cropState}
              onCropChange={(crop) => setCropState(crop)}
              aspect={aspect}
              onZoomChange={(z) => setZoom(z)}
              onCropComplete={(_croppedArea, croppedAreaPixels) =>
                setCropAreaPixels(croppedAreaPixels)
              }
            />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CropDialog;
