import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";
import { graphql } from "gatsby";
import {
  ContentBox,
  DesktopOnly,
  MobileOnly,
} from "../../components/StandardComponents";

import { MainLayout2 } from "../../components/layouts";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import Image from "../../components/card/image";
import { styled } from "../../styles/ThemeProvider";
import moment from "moment";
import NewsPageLayoutDynamic from "../../components/News/NewsPageLayoutDynamic";
import { ContentItem, ContentItemType } from "../../backend-api/types";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { getContent } from "../../backend-api/user-api";
import { filterContentType, getLocale } from "../../backend-api/contentHelpers";

const DateDisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
  width: 80px;
  height: 80px;
`;

const MonthStyled = styled.div`
  background-color: ${(props) => props.theme.colorOneLighter};
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 2px 0px;
  font-size: 80%;
`;
const DayStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 2px 0px;
  font-size: 140%;
`;
const YearStyled = styled.div`
  background-color: ${(props) => props.theme.colorOneLighter};
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 2px 0px;
  font-size: 80%;
`;
const DateDisplayComposite: React.FC<{ date: Date }> = ({ date }) => {
  const dateMoment = moment(date);
  return (
    <DateDisplayWrapper>
      <MonthStyled>{dateMoment.format("MMMM")}</MonthStyled>
      <DayStyled>{dateMoment.format("D")}</DayStyled>
      <YearStyled>{dateMoment.format("YYYY")}</YearStyled>
    </DateDisplayWrapper>
  );
};

const NewsItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid ${(props) => props.theme.darkColor};
  padding: 10px;
`;

const NewsDateTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NewsDateTitleInnerWrapper = styled.div`
  display: flex;
`;

const TitleCaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const TitleStyle = styled.div`
  & a {
    color: ${(props) => props.theme.colorOne};
    font-weight: 500;
    font-size: 140%;
  }
`;

interface NewsPageContext {
  articles?: Array<ContentItem>;
  language: string;
}

const IndexPage: React.FC<{ pageContext: NewsPageContext; path: string }> = (
  props
) => {
  const { t } = useTranslation();

  const articlesQuery = useQuery("articles", getContent, {
    initialData: props.pageContext.articles,
    retry: false,
    enabled: true,
  });

  useEffect(() => {
    console.log(props);
  }, []);

  useEffect(() => {
    console.log(articlesQuery);
  }, [articlesQuery]);

  return (
    <MainLayout2
      pageTitle={t("News")}
      pageBannerImg={"/assets/general-banner.jpg"}
      pageCrumbMap={[{ link: "/", name: t("Home") }]}
    >
      <ContentBox>
        {articlesQuery.isSuccess &&
          articlesQuery.data
            .filter(filterContentType(ContentItemType.article))
            .map((news, index) => (
              <>
                <DesktopOnly>
                  <NewsItemWrapper
                    key={`${getLocale(news, props.pageContext.language)
                      ?.slug}-${index}`}
                  >
                    <NewsDateTitleWrapper>
                      <NewsDateTitleInnerWrapper>
                        <DateDisplayComposite
                          date={new Date(news.publishDate)}
                        ></DateDisplayComposite>
                        <TitleCaptionWrapper>
                          <TitleStyle>
                            <Link
                              to={`/news/${getLocale(
                                news,
                                props.pageContext.language
                              )?.slug}`}
                            >
                              {
                                getLocale(news, props.pageContext.language)
                                  ?.title
                              }
                            </Link>
                          </TitleStyle>
                          <div>
                            {
                              getLocale(news, props.pageContext.language)
                                ?.summary
                            }
                          </div>
                        </TitleCaptionWrapper>
                      </NewsDateTitleInnerWrapper>
                    </NewsDateTitleWrapper>
                    <div>
                      <Image
                        width={200}
                        height={200}
                        image={
                          getLocale(news, props.pageContext.language)?.image
                        }
                      />
                    </div>
                  </NewsItemWrapper>
                </DesktopOnly>
                <MobileOnly>
                  <NewsItemWrapper
                    key={`${getLocale(news, props.pageContext.language)
                      ?.slug}-${index}`}
                  >
                    <div className="flex flex-1 flex-col">
                    <div className="flex justify-center">
                      <Image
                        width={200}
                        height={200}
                        image={
                          getLocale(news, props.pageContext.language)?.image
                        }
                      />
                    </div>
                    
                    <NewsDateTitleWrapper>
                      <NewsDateTitleInnerWrapper>
                        <DateDisplayComposite
                          date={new Date(news.publishDate)}
                        ></DateDisplayComposite>
                        <TitleCaptionWrapper>
                          <TitleStyle>
                            <Link
                              to={`/news/${getLocale(
                                news,
                                props.pageContext.language
                              )?.slug}`}
                            >
                              {
                                getLocale(news, props.pageContext.language)
                                  ?.title
                              }
                            </Link>
                          </TitleStyle>
                          <div>
                            {
                              getLocale(news, props.pageContext.language)
                                ?.summary
                            }
                          </div>
                        </TitleCaptionWrapper>
                      </NewsDateTitleInnerWrapper>
                    </NewsDateTitleWrapper>
                    </div>
                  </NewsItemWrapper>
                </MobileOnly>
              </>
            ))}
      </ContentBox>
    </MainLayout2>
  );
};

const RouterPage: React.FC<{ pageContext: NewsPageContext }> = ({
  pageContext,
}) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router basepath="/:lang/news">
        <IndexPage path="/" pageContext={pageContext} />
        <NewsPageLayoutDynamic path=":slug" />
      </Router>
    </QueryClientProvider>
  );
};

export default RouterPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
