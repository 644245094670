import React, { useEffect, useState } from "react";

import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { RouteComponentProps } from "@reach/router";
import { ContentItem, ContentItemState, ContentLocalization } from "../../backend-api/types";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { useContent } from "../hooks/contentHooks";
import { MessagesProps } from "../types";
import { navigate } from "gatsby";
import { UnauthorizedError } from "../../backend-api/api";
import { getContentTypeDisplayValue } from "./contentTypes";

const ContentList = (props: RouteComponentProps & MessagesProps) => {
  const content = useContent({
    onError: (ex) => {
      if (ex instanceof Error) {
        props.messages.current.show({
          severity: "error",
          summary: "Load Failed:",
          detail: <>&nbsp;{ex.message}</>,
        });
      }

      if (ex instanceof UnauthorizedError) {
        setTimeout(() => {
          navigate("/admin/login");
        }, 200);
      }
    },
  });

  const tableTitleExtractor = (rowData: ContentItem) => {
    if (!rowData.localizations || rowData.localizations.length === 0) {
      return "NO DATA";
    }

    let defaultLocal = rowData.localizations.find(
      (locale: ContentLocalization) => locale.default === true
    );
    if (!defaultLocal) {
      defaultLocal = rowData.localizations[0];
    }

    return defaultLocal.title;
  };

  const tableSlugExtractor = (rowData: ContentItem) => {
    if (!rowData.localizations || rowData.localizations.length === 0) {
      return "NO DATA";
    }

    let defaultLocal = rowData.localizations.find(
      (locale: ContentLocalization) => locale.default === true
    );
    if (!defaultLocal) {
      defaultLocal = rowData.localizations[0];
    }

    return defaultLocal.slug;
  };

  const tableActiveBody = (rowData: ContentItem) => {
    return (
      <>
        {rowData.publishState == ContentItemState.draft && (
          <Tag
            icon="pi pi-check"
            value={"Draft"}
            rounded={true}
            severity={"warning"}
          />
        )}
        {rowData.publishState == ContentItemState.published && (
          <Tag
            icon="pi pi-times"
            value={"Published"}
            rounded={true}
            severity={"success"}
          />
        )}
      </>
    );
  };

  const tableActionsBody = (rowData: ContentItem) => (
    <div className="flex justify-center">
      <div className={"pr-1"}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success"
          onClick={() => {
            rowData.id && props.navigate && props.navigate(rowData.id);
          }}
        />
      </div>
      <div className={"pl-1 pr-1"}>
        <ConfirmPopup />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={(event) => {
            confirmPopup({
              target: event.currentTarget,
              message: "Are you sure you want to proceed?",
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                rowData.id && content.delete(rowData.id);
              },
              reject: () => null,
            });
          }}
        />
      </div>
    </div>
  );

  const tableContentType = (rowData: ContentItem) => {
    return getContentTypeDisplayValue(rowData.contentType)
  }

  return (
    <>
      <Button
        icon={"pi pi-plus"}
        label={"New Content Item"}
        onClick={() => {
          props.navigate && props.navigate("new");
        }}
      />
      {content.get.data && (
        <DataTable value={content.get.data}>
          <Column header={"Type"} body={tableContentType} ></Column>
          <Column header={"Title"} body={tableTitleExtractor}></Column>
          <Column header={"Slug"} body={tableSlugExtractor}></Column>
          <Column header={"State"} body={tableActiveBody}></Column>
          <Column body={tableActionsBody} />
        </DataTable>
      )}
    </>
  );
};

export default ContentList;
