import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import { graphql } from "gatsby";
import GlobalStyle from "../../../styles/GlobalStyle";
import {
  ContentBox,
  Center,
  BgBlock,
  SubSectionHeader,
} from "../../../components/StandardComponents";

import { MainLayout2 } from "../../../components/layouts";

import Card, { CardBox, CardTitleOverlay } from "../../../components/card";
import { LalunaDarkColor } from "../../../constants";

import { PrivateCarTransportTable } from "../../../components/Transportation/PrivateCarTransportTable";

import styled from "styled-components";
import { TransportationDataProvider } from "../../../dataProviders/TransportationDataProvider";
import { CarSerivceOptions } from "../../../components/Transportation/Types";
import { TourOptions } from "../../../dataProviders/ToursDataProvider";
import { ToursDataProvider } from "../../../dataProviders/ToursDataProvider";
import { ensureArray } from "../../../utils";
import { TourCardDetailsComposite } from "../../../components/Tours/TourCardDetails";
import { Modal } from "../../../components/modal/Modal";
import { getImage } from "../../../../lib/image-resolve";

const TransportOptionsCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 120%;
`;

const IndexPage: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <>
      {console.log(pageContext)}
      <MainLayout2
        pageTitle={t("Tours")}
        pageBannerImg={"/assets/general-banner.jpg"}
        pageCrumbMap={[
          { link: "/", name: t("Home") },
          {
            link: "/transportation-and-tours",
            name: t("Transportation & Tours"),
          },
        ]}
      >
        <ContentBox>
          <ToursDataProvider>
            {(tours: TourOptions) => {
              return (
                <CardBox>
                  {tours.map((tour, index) => {
                    const tourPrices = ensureArray(tour.price);
                    const tourSchedules = ensureArray(tour.tourSchedules);
                    return (
                      <CardTitleOverlay
                        imageUrl={tour.tourImages[0]}
                        title={tour.name}
                        buttonText={"More Details"}
                        buttonHref={`/transportation-and-tours/tour/${tour.id}`}
                      >
                        <TourCardDetailsComposite
                          price={tourPrices[0]}
                          schedules={tourSchedules}
                          minGroup={tour.minGroupSize}
                          maxGroup={tour.maxGroupSize}
                        />
                      </CardTitleOverlay>
                    );
                  })}
                </CardBox>
              );
            }}
          </ToursDataProvider>
        </ContentBox>
      </MainLayout2>
    </>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
