import React, { useMemo } from "react";
import { LocaleEditable } from "./types";
import { TabMenu } from "primereact/tabmenu";
import { LocalizedView } from "./localizedView";
import styled from "styled-components";

const Separator = styled.div`
  width: 100%;
  height: 5px;
  background-color: #cfcfcf;
  margin: 10px 0;
`;

type ChangeFn = (str: string) => void;
type BlurFn = (locale: LocaleEditable) => void;

interface LocaleTabMenuProps extends React.PropsWithChildren {
  locales: Array<LocaleEditable>;
  setSelectedLocale: (selected: LocaleEditable) => void;
  /*selectedTitleChange: ChangeFn;
  selectedSummaryChange: ChangeFn;
  selectedDetailsChange: ChangeFn;
  selectedTitleBlur: BlurFn;
  selectedSEOImageChange: ChangeFn;*/
}

export const LocaleTabMenu: React.FC<LocaleTabMenuProps> = ({
  locales,
  children,
  setSelectedLocale,
  /*selectedTitleChange,
  selectedSummaryChange,
  selectedDetailsChange,
  selectedTitleBlur,
  selectedSEOImageChange,*/
}) => {
  
  const selectedLocaleIndex = useMemo(() => {
    return locales.findIndex((item) => item.active === true)
  }, [locales])

  const selectedLocale = locales[selectedLocaleIndex];

  return (
    <>
      <TabMenu
        model={locales}
        activeIndex={selectedLocaleIndex}
        onTabChange={(evt) => setSelectedLocale(locales[evt.index])}
      ></TabMenu>
      {selectedLocale && (children
        /*<LocalizedView
          title={selectedLocale.title}
          summary={selectedLocale.summary}
          details={selectedLocale.data}
          titleChange={selectedTitleChange}
          summaryChange={selectedSummaryChange}
          detailsChange={selectedDetailsChange}
          titleBlur={() => selectedTitleBlur(selectedLocale)}
          seoImageChange={selectedSEOImageChange}
          seoImageUrl={selectedLocale.seoImage}
        />*/
      )}
      <Separator />
    </>
  );
};
