import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { TextCenter } from "../../../components/StandardComponents";
import LoadableEditor from "../../components/editor/loadableEditor";
import { ImageUpload } from "../../components/imageUpload";

const CardContentContainer = styled.div`
  width: 100%;
  & strong {
    font-weight: 500;
  }
  & h1 {
    font-size: 100%;
  }
`;

interface LocalizatedViewProps {
  title: string;
  slug: string;
  titleChange: (newTitle: string) => void;
  slugChange: (newSlug: string) => void;
  titleBlur: () => void;
  slugBlur: (newSlug: string) => void;
  summary: string;
  summaryChange: (newSummary: string) => void;
  details: string;
  detailsChange: (newDetails: string) => void;
  seoImageUrl: string;
  seoImageChange: (newImaeg: string) => void;
  imageUrl: string;
  imageChange: (newImaeg: string) => void;
  editorUploadUrl: string
}

export const LocalizedView = (props: LocalizatedViewProps) => {
  return (
    <>
      <div className="flex space-x-3">
        <div className="pt-2 pb-2">
          <label htmlFor="title" className={"pr-2 text-xl"}>
            Title
          </label>
          <InputText
            id="title"
            value={props.title}
            onChange={(e) =>
              props.titleChange &&
              props.titleChange(e.target.value)
            }
            autoFocus
            onBlur={() => props.titleBlur && props.titleBlur()}
          />
        </div>
        <div className={"p-2 pl-0"}>
          <label htmlFor="slug" className={"pr-2 text-xl"}>
            Slug
          </label>
          <InputText
            id="slug"
            value={props.slug}
            onChange={(e) => {
              props.slugChange && props.slugChange(e.target.value);
            }}
            onBlur={(e) => props.slugBlur && props.slugBlur(e.target.value)}
          />
        </div>
      </div>
      <div className={"pt-2 pb-2"}>
        <div className={"flex"}>
          <div className="mr-2">
            <label className={"text-xl block"}>Summary</label>
            <InputTextarea
              value={props.summary}
              onChange={(e) =>
                props.summaryChange &&
                props.summaryChange(e.target.value)
              }
              autoResize={true}
              rows={10}
              cols={40}
            />
          </div>
          <div className="ml-2">
            <label className={"text-xl block"}>Summary Preview</label>
            <CardContentContainer>
              <TextCenter>
                <ReactMarkdown source={props.summary} />
              </TextCenter>
            </CardContentContainer>
          </div>
        </div>
      </div>
      <div className={"pt-2 pb-2"}>
        <label className={"text-xl"}>Details</label>
        <LoadableEditor
          url={props.editorUploadUrl}
          data={props.details}
          dataUpdated={(data) =>
            props.detailsChange && props.detailsChange(data)
          }
        ></LoadableEditor>
      </div>
      <div className={"mt-2 mb-2"}>
        <label htmlFor="image" className={"pr-2 text-xl"}>
          Localized Content Image (used within the site)
        </label>
        <ImageUpload
          imageHeight={238}
          imageWidth={298}
          initialImageUrl={props.imageUrl}
          onChangeImage={props.imageChange}
        />
      </div>
      <div className={"pt-2 pb-2"}>
        <label htmlFor="content seo image" className={"pr-2 text-xl"}>
          Localized SEO Image (Google / Facebook / Twitter)
        </label>
        <ImageUpload
          imageHeight={630}
          imageWidth={1200}
          initialImageUrl={props.seoImageUrl}
          onChangeImage={props.seoImageChange}
        />
      </div>
    </>
  );
};
