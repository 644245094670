import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { rgba } from "polished";
import { Link, StandardLink } from "../StandardComponents";
import {
  FacebookIcon,
  TwitterIcon,
  TripadvisorIcon,
  InstagramIcon,
  MapMarkedIcon,
  EnvelopeIcon,
  HeadsetIcon,
} from "../icons";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import {
  FacebookButton,
  InstagramButton,
  SocialLinkWrapper,
  TripAdvisorButton,
  TwitterButton,
  WhatsAppButton,
} from "../SocialLinks";

const SlideFromLeft = keyframes`
  from {
    top: -160vh;
  }

  to {
    top: 50px;
  }
`;

const SlideBackLeft = keyframes`
  from {
    top: 50px;
  }

  to {
    top: -160vh;
  }
`;

export interface NavLink {
  displayName: string;
  href: string;
}

interface ContainerProps {
  backColor: string;
  backColorOpacity?: number;
  expanded: boolean;
  doTransition: boolean;
}

interface BottomNavLinkStyleProps {
  linkHoverColor: string;
}

interface Props {
  backColor: string;
  backColorOpacity?: number;
  linkHoverColor: string;
  bottomNavLinks: Array<NavLink>;
  expanded: boolean;
  doTransition: boolean;
  resetDoTransition: Function;
  resetExpanded: Function;
}

const BottomNavContainer = styled.div<ContainerProps>`
  background-color: ${(props) =>
    rgba(props.backColor, props.backColorOpacity || 1)};

  display: flex;
  z-index: -1;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: fixed;
  border: #1c1c1c solid 1px;
  align-items: stretch;
  justify-content: stretch;
  //top: 0px;
  ${(props) => (props.expanded ? "top: 50px" : "top: -160vh")};
  ${(props) =>
    props.doTransition
      ? props.expanded
        ? css`
            animation: ${SlideFromLeft} 0.5s ease-in-out 1;
          `
        : css`
            animation: ${SlideBackLeft} 0.5s ease-in-out 1;
          `
      : ""};
`;

const BottomNavLinkStyle = styled.div<BottomNavLinkStyleProps>`
  display: flex;
  font-family: "Playfair Display", serif;
  & > a {
    color: white;
    text-shadow: 1px 1px 6px ${(props) => rgba("#000000", 1)};
  }
  & > a :visited {
    color: white;
  }

  & > a :hover {
    color: ${(props) => props.linkHoverColor};
  }

  font-size: 120%;
  border: none;
  padding: none;
  margin: none;
  border-bottom: 1px solid #1c1c1c;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: stretch;

  & :last-child {
    border-bottom: none;
  }
`;

const ActionLabel = styled.span`
  color: white;
  //font-size: 13px;
  font-size: 85%;
  margin-left: 10px;
`;

const ActionContainerStyle = styled.span`
  display: flex;
  align-items: center;
`;
const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
const ActionContainer: React.FC<{
  href?: string;
  standardLink?: boolean;
  action?: () => void;
}> = ({ children, href, standardLink, action }) => {
  if (action) {
    return (
      <Button onClick={action}>
        <ActionContainerStyle>{children}</ActionContainerStyle>
      </Button>
    );
  } else if (standardLink) {
    return (
      <StandardLink href={href}>
        <ActionContainerStyle>{children}</ActionContainerStyle>
      </StandardLink>
    );
  } else {
    return (
      <Link to={href}>
        <ActionContainerStyle>{children}</ActionContainerStyle>
      </Link>
    );
  }
};

const ActionsContainer = styled.div`
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  //margin-bottom: 10px;
  background-color: #1c1c1c;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  & > a {
    margin-top: 5px;
    margin-bottom: 5px;

    & :first-child {
      margin-top: 0px;
    }

    & :last-child {
      margin-bottom: 0px;
    }
  }
`;

const SocialSectionContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #1c1c1c;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 120%;
`;

const SocialIconContainerMobile = styled.div`
  display: flex;
  margin-right: 20px;
  & > a {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;

    & :first-child {
      margin-left: 0px;
    }

    & :last-child {
      margin-right: 0px;
    }
  }
`;

const BottomNav: React.FC<Props> = ({
  backColor,
  backColorOpacity,
  bottomNavLinks,
  linkHoverColor,
  expanded,
  doTransition,
  resetDoTransition,
  resetExpanded,
}) => {
  const eventHandler = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  //const [initial, setInitial] = useState(true);

  const StyledFullWidthLink = styled(Link)`
    width: 100%;
  `;

  useEffect(() => {
    const timeout = setTimeout(() => resetDoTransition(), 500);
    return () => clearTimeout(timeout);
  }, [doTransition]);

  const { t } = useTranslation();

  return (
    <BottomNavContainer
      backColor={backColor}
      backColorOpacity={backColorOpacity}
      expanded={expanded}
      onClick={eventHandler}
      doTransition={doTransition}
    >
      {bottomNavLinks.map((link, index) => {
        //console.log(link);
        return (
          <BottomNavLinkStyle key={index} linkHoverColor={linkHoverColor}>
            <StyledFullWidthLink to={link.href} onClick={() => resetExpanded()}>
              {link.displayName}
            </StyledFullWidthLink>
          </BottomNavLinkStyle>
        );
      })}

      <>
        <ActionsContainer>
          <ActionContainer
            standardLink={true}
            href="https://maps.google.com/maps?ll=15.876447,108.321697&z=16&t=m&hl=en&gl=US&mapclient=embed&q=12%20Nguy%E1%BB%85n%20Du%20Ph%C6%B0%E1%BB%9Dng%20C%E1%BA%A9m%20Ph%E1%BB%95%20H%E1%BB%99i%20An%20Qu%E1%BA%A3ng%20Nam%2C%20Vietnam"
          >
            <MapMarkedIcon />
            <ActionLabel>{t("Map Us")}</ActionLabel>
          </ActionContainer>
          <ActionContainer href="/contact-us/">
            <EnvelopeIcon />
            <ActionLabel>{t("Contact Us")}</ActionLabel>
          </ActionContainer>
          {/*<ActionContainer action={() => window.fcWidget.open()}>
            <HeadsetIcon />
            <ActionLabel>{t("Chat With Us")}</ActionLabel>
    </ActionContainer>*/}
        </ActionsContainer>
        <SocialSectionContainer>
          <div>{t("Follow Us")}</div>
          <SocialIconContainerMobile>
            <FacebookButton />
            <TwitterButton />
            <TripAdvisorButton />
            <InstagramButton />
            <WhatsAppButton />
          </SocialIconContainerMobile>
        </SocialSectionContainer>
      </>
    </BottomNavContainer>
  );
};

export default BottomNav;
