import {
  getAllResource,
  createResource,
  updateResource,
  deleteResource,
  getResource,
  resourceCustomFunction,
  API_HOST,
  API_PATH,
} from "./api";
import { ContentItem, Promotion } from "./types";

export enum Role {
  admin = "admin",
  editor = "editor",
  promoter = "promoter",
  operator = "operator",
  users = "users",
}

export interface User {
  id?: string;
  name: string;
  email: string;
  username: string;
  password?: string;
  roles: Array<Role>;
  enabled: boolean;
}

export const getMe = async () => {
  return getAllResource<User>("me");
};

export const updatePassword = async (password: string) => {
  return resourceCustomFunction("POST", "me", "change-password", {
    password,
  }) as Promise<any>;
};

export const getUsers = async () => {
  return getAllResource<[User]>("users");
};

export const createUser = async (user: User) => {
  return createResource("users", user);
};

export const updateUser = async (id: string, user: User) => {
  return updateResource("users", id, user);
};

export const deleteUser = async (id: string) => {
  return deleteResource("users", id);
};

export const getPromotion = async (id: string) => {
  return getResource<Promotion>("admin/promotions", id);
};

export const getPromotions = async () => {
  return getAllResource<[Promotion]>("admin/promotions");
};

export const createPromotion = async (promotion: Promotion) => {
  return createResource("admin/promotions", promotion);
};

export const updatePromotion = async (id: string, promotion: Promotion) => {
  return updateResource("admin/promotions", id, promotion);
};

export const deletePromotion = async (id: string) => {
  return deleteResource("admin/promotions", id);
};

export const checkSlugPromotion = async (slug: string) => {
  return resourceCustomFunction("POST", "admin/promotions", "check-slug", {
    slug,
  }) as Promise<{ slug: string }>;
};

export const promotionEditorUploadUrl = `${API_HOST()}/${API_PATH}/admin/editor-upload/promotions`;

export const getContentItem = async (id: string) => {
  return getResource<ContentItem>("admin/content", id);
};

export const getContentAll = async () => {
  return getAllResource<[ContentItem]>("admin/content");
};

export const createContentItem = async (content: ContentItem) => {
  return createResource("admin/content", content);
};

export const updateContentItem = async (id: string, content: ContentItem) => {
  return updateResource("admin/content", id, content);
};

export const deleteContentItem = async (id: string) => {
  return deleteResource("admin/content", id);
};

export const checkSlugContent = async (slug: string, id?: string) => {
  return resourceCustomFunction("POST", "admin/content", "check-slug", {
    slug,
    id
  }) as Promise<{ slug: string }>;
};

export const contentEditorUploadUrl = `${API_HOST()}/${API_PATH}/admin/editor-upload/content`;

//--
