import React from "react";
import styled from "styled-components";
import { rgba } from "polished";

import { Calendar as UnstyledCalendar } from "primereact/calendar";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const Calendar: React.FC<{ className?: string; placeholder?: string }> = ({
  className,
  ...rest
}) => <UnstyledCalendar inputClassName={className} {...rest} />;

const StyledDatePicker = styled(Calendar)`
  &&& {
    font-family: "Roboto", sans-serif;
    width: 120px;
    height: 30px;
    background: url(/assets/calendar-alt-regular-datepicker.svg) no-repeat
      scroll 95px 4px;
    background-color: ${(props) => rgba("#1c1c1c", 0.7)};
    background-size: 20px 20px;
    color: white;
    border-radius: 4px;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bbbbbb;
      opacity: 1; /* Firefox */
      font-size: 11px;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bbbbbb;
      font-size: 11px;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bbbbbb;
      font-size: 11px;
    }
  }
`;

export default StyledDatePicker;
