import React from "react";
import { graphql } from "gatsby";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import GlobalStyle from "../../styles/GlobalStyle";
import {
  ContentBox,
  Center,
  PageTitle,
  SectionHeader,
  BreadCrumb,
  BgBlock,
  TextCenter,
} from "../../components/StandardComponents";

import { MainLayout, MainLayout2 } from "../../components/layouts";
import Card, { CardBox } from "../../components/card";
import { LalunaDarkColor, LalunaAccentColor } from "../../constants";
import { CardTable } from "../../components/table";
import styled from "styled-components";
import RoomDataProvider, {
  RoomDefArray,
} from "../../dataProviders/RoomDataProvider";
import { PageImageBanner } from "../../components/PageBanner";
import { getImage } from "../../../lib/image-resolve";

const TableContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 5px 20px;
`;

const IndexPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <RoomDataProvider>
      {(roomData: RoomDefArray) => (
        <MainLayout2
          pageTitle={t("Room Types")}
          pageBannerImg={"/assets/rooms/rooms-banner.jpg"}
          pageCrumbMap={[{ link: "/", name: t("Home") }]}
        >
          <ContentBox>
            <TextCenter>
              {t(
                "Laluna Hoi An Riverside Hotel is outfitted in brilliant contemporary style with luxurious amenities and tastefully decorated which affords a glorious view over the Hoai River. The Laluna hotel provides luxurious accommodations with a choice of room types ranging from great value superior rooms to luxurious suites that meet any of your needs or budgets."
              )}
            </TextCenter>
          </ContentBox>
          <BgBlock>
            <ContentBox>
              <CardBox>
                {roomData.map((room, roomIndex) => (
                  <Card
                    key={`room-${room.roomName}-${roomIndex}`}
                    imageUrl={room.imageUrl}
                    title={room.roomName}
                    buttonText={t("More Details")}
                    buttonHref={room.link}
                    bottonTextHoverColor={LalunaDarkColor}
                  >
                    <TableContainer>
                      <CardTable>
                        {[
                          [t("Occupancy"), room.occupancy],
                          [t("Size"), room.size],
                          [t("Available Views"), room.views],
                        ]}
                      </CardTable>
                    </TableContainer>
                  </Card>
                ))}
              </CardBox>
            </ContentBox>
          </BgBlock>
        </MainLayout2>
      )}
    </RoomDataProvider>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;
*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
