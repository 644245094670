import React from "react";
import styled, { css } from "styled-components";

const TitleContainerMobileCss = css`
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 130%;
  border-bottom: 1px solid #a08036;
`;

const TitleContainerDesktopCss = css`
  ${TitleContainerMobileCss}
`;

const TitleContainer = styled.div`
  display: flex;
  //background-color: red;
  justify-content: center;
  text-align: center;
  @media only screen and (min-width: 660px) {
    ${TitleContainerDesktopCss}
  }
  @media only screen and (max-width: 660px) {
    ${TitleContainerMobileCss}
  }
`;

const CardTitle: React.FC = ({ children }) => {
  return <TitleContainer>{children}</TitleContainer>;
};

export default CardTitle;
