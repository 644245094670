import React, { useState, useEffect } from "react";
import ImageFadeCarouselWithFilmStrip from "./ImageFadeCarouselWithFilmStrip";
import RotateCarousel from "./RotateCarousel";

export { ImageFadeCarouselWithFilmStrip, RotateCarousel };
/*import { css, keyframes, withTheme, ThemeProps } from "styled-components";
import RatioContainer, {
  RatioContainerImage,
} from "../../components/RatioContainer";
import { styled } from "../../styles/ThemeProvider";

const OuterImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageContainer = styled.div`
  background-color: ${props => props.theme.components.carousel.backgroundColor};
  ${props =>
    `border: ${props.theme.components.carousel.borderWidth}px solid ${props.theme.components.carousel.borderColor}`};
  ${props =>
    `border-radius: ${props.theme.components.carousel.borderRadius}px`};
  ${props => `max-width: ${props.theme.components.carousel.maxWidth}px`};
  width: 100%;
`;

const keyframesFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const keyframesFadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Container = styled.div`
  //position: relative;
  display: grid;
`;

interface ChildWrapperProps {
  visible: boolean;
  forceZ: boolean;
  fadeout?: boolean;
  fadein?: boolean;
  transitionTime: number;
}

const ChildWrapper = styled.div<ChildWrapperProps>`
  //position: absolute;
  grid-column: 1;
  grid-row: 1;
  opacity: ${props => (props.visible ? "1" : "0")};
  z-index: ${props => (props.forceZ ? "1" : "0")};

  ${props =>
    props.fadeout
      ? css`
          animation-name: ${keyframesFadeOut};
        `
      : ""};
  ${props =>
    props.fadein
      ? css`
          animation-name: ${keyframesFadeIn};
        `
      : ""};
  animation-duration: ${props => `${props.transitionTime}s`};
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
`;

const FilmStripContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

interface FilmStripItemProps {
  viewing: boolean;
  viewingBorderColor: string;
}

const FilmStripItem = styled.div<FilmStripItemProps>`
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  ${props => props.viewing && `border: 1px solid ${props.viewingBorderColor}`};
  :first-child {
    margin-left: 0px;
  }

  :last-child {
    margin-right: 0px;
  }
`;

interface CarouselProps {
  images: Array<string>;
  widthRatio: number;
  heightRatio: number;
  transitionTime: number;
  imageTimeout: number;
}

const Carousel: React.FC<CarouselProps & ThemeProps<any>> = ({
  images,
  widthRatio,
  heightRatio,
  transitionTime,
  imageTimeout,
  theme,
}) => {
  const [viewing, setViewing] = useState(0);
  const [next, setNext] = useState(1);
  const [doFade, setDoFade] = useState(false);

  useEffect(() => {
    if (doFade) {
      const timeout = setTimeout(() => {
        setDoFade(false);
        setViewing(next);
        if (next === images.length - 1) {
          setNext(0);
        } else {
          setNext(next + 1);
        }
      }, transitionTime * 1000);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      const timeout = setTimeout(() => {
        setDoFade(true);
      }, imageTimeout * 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  });

  return (
    <OuterImageContainer>
      <ImageContainer>
        <Container>
          {images.map((image, index) => (
            <ChildWrapper
              key={index}
              fadein={index === next && doFade}
              visible={index === viewing}
              forceZ={viewing === 0 || (index === next && viewing > next)}
              transitionTime={transitionTime}
            >
              <RatioContainer
                widthRatio={widthRatio}
                heightRatio={heightRatio}
                backgroundColor={theme.components.carousel.backgroundColor}
              >
                <RatioContainerImage src={image}></RatioContainerImage>
              </RatioContainer>
            </ChildWrapper>
          ))}
        </Container>

        <FilmStripContainer>
          {images.map((image, index) => (
            <FilmStripItem
              key={index}
              viewing={doFade ? index === next : index === viewing}
              onClick={() => {
                setDoFade(true);
                setNext(index);
              }}
              viewingBorderColor={theme.components.carousel.viewingBorderColor}
            >
              <RatioContainer
                widthRatio={widthRatio}
                heightRatio={heightRatio}
                backgroundColor={theme.components.carousel.backgroundColor}
              >
                <RatioContainerImage src={image}></RatioContainerImage>
              </RatioContainer>
            </FilmStripItem>
          ))}
        </FilmStripContainer>
      </ImageContainer>
    </OuterImageContainer>
  );
};

export default withTheme(Carousel);*/
