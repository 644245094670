import {
  InstagramIcon,
  TripadvisorIcon,
  TwitterIcon,
  FacebookIcon,
  WhatsAppIcon,
} from "../icons";
import { styled } from "../../styles/ThemeProvider";
import React from "react";
import { Link as UnstyledLink } from "gatsby-plugin-react-i18next";

const Link = styled(UnstyledLink)`
  text-decoration: none;
`;

const StandardLink = styled.a`
  text-decoration: none;
`;

export const SocialLinkWrapper: React.FC<{
  href: string;
  Icon: React.FC;
  standardLink?: boolean;
}> = ({ href, Icon, standardLink }) => {
  return (
    <>
      {standardLink ? (
        <StandardLink href={href} target="_blank" rel="noopener noreferrer">
          <Icon />
        </StandardLink>
      ) : (
        <Link to={href}>
          <Icon />
        </Link>
      )}
    </>
  );
};

const SocialIconContainer = styled.div`
  display: flex;
  //margin-right: 20px;
  & > a {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;

    & :first-child {
      margin-left: 0px;
    }

    & :last-child {
      margin-right: 0px;
    }
  }
`;

export const InstagramButton = () => (
  <SocialLinkWrapper
    href="https://www.instagram.com/lalunahoianriversidehotel/"
    Icon={InstagramIcon}
    standardLink={true}
  />
);

export const WhatsAppButton = () => (
  <SocialLinkWrapper
    href="https://wa.me/84905625199"
    Icon={WhatsAppIcon}
    standardLink={true}
  />
);

export const TripAdvisorButton = () => (
  <SocialLinkWrapper
    href="https://www.tripadvisor.com/Hotel_Review-g298082-d13921547-Reviews-Laluna_Hoi_An_Riverside_Hotel_Spa-Hoi_An_Quang_Nam_Province.html?m=19905"
    Icon={TripadvisorIcon}
    standardLink={true}
  />
);

export const TwitterButton = () => (
  <SocialLinkWrapper
    href="https://twitter.com/lalunahoianriv2"
    Icon={TwitterIcon}
    standardLink={true}
  />
);

export const FacebookButton = () => (
  <SocialLinkWrapper
    href="https://www.facebook.com/lalunariversidehoian/"
    Icon={FacebookIcon}
    standardLink={true}
  />
);

const SocialLinks = () => {
  return (
    <SocialIconContainer>
      <FacebookButton />
      <TwitterButton />
      <TripAdvisorButton />
      <InstagramButton />
      <WhatsAppButton />
    </SocialIconContainer>
  );
};

export default SocialLinks;
