import { RouteComponentProps, Router } from "@reach/router";
import React from "react";
import { PromotionDetails } from "./details/";
import PromotionsList from "./list";

interface PromotionsIndexRoute extends RouteComponentProps {
  messages: any;
}

const PromotionIndex = (props: PromotionsIndexRoute) => (
  <>
    <div className={"container p-10"}>
      <Router>
        <PromotionDetails path=":id" messages={props.messages} />
        <PromotionsList default />
      </Router>
    </div>
  </>
);

export default PromotionIndex;
