import { useMemo, useReducer } from "react";
import {
  ActionKind,
  DetailsReducerAction,
  StateInterface,
  reducer,
  stateInit,
} from "./detailsReducer";
import { getLangForLocale } from "../../helpers/supportedLocales";
import { LocaleEditable } from "./types";
import { ContentItem, ContentItemState, ContentItemType } from "../../../backend-api/types";
import slugify from "slugify";
import { checkSlugContent } from "../../../backend-api/admin-api";

const useContentDetails = () => {
  const [state, dispatch] = useReducer<
    React.Reducer<StateInterface, DetailsReducerAction>,
    string | undefined | null
  >(reducer, null, stateInit);

  const dispatchShowNewLanguageDialog = (show: boolean) =>
    dispatch({ type: ActionKind.ShowNewLangDialog, payload: show });
  const dispatchAddNewLocale = (locale: string) =>
    dispatch({
      type: ActionKind.AddLocale,
      payload: {
        locale,
        label: getLangForLocale(locale),
        title: "",
        active: true,
        summary: "",
        content: "",
        default: false,
        seoImage: "",
        image: "",
        slug: "",
        slugUpdatedManually: false,
      },
    });

  const dispatchSetSelectedLocale = (localeEditable: LocaleEditable) =>
    dispatch({ type: ActionKind.SetSelectedLocale, payload: localeEditable });

  const dispatchSeletedDetails = (details: string) =>
    dispatch({
      type: ActionKind.UpdateSelectedDetails,
      payload: details,
    });
  const dispatchSeletedSummary = (summary: string) =>
    dispatch({
      type: ActionKind.UpdateSelectedSummary,
      payload: summary,
    });

  const dispatchSeletedTitle = (title: string) =>
    dispatch({
      type: ActionKind.UpdateSelectedTitle,
      payload: title,
    });

  const dispatchUpdateSelectedSlug = (slug: string, manual: boolean) =>
    dispatch({
      type: ActionKind.UpdateSlug,
      payload: { slug, manualUpdate: manual },
    });

  const dispatchUpdateSelectedImage = (url: string) =>
    dispatch({ type: ActionKind.UpdateContentImage, payload: url });

  const dispatchContentState = (enabled: ContentItemState) =>
    dispatch({ type: ActionKind.UpdateContentState, payload: enabled });

  const dispatchContentType = (type: ContentItemType) =>
    dispatch({ type: ActionKind.UpdateContentType, payload: type });

  const dispatchSEOImageChange = (url: string) =>
    dispatch({ type: ActionKind.UpdateSelectedSEOImage, payload: url });

  const dispatchInit = (result: ContentItem) =>
    dispatch({ type: ActionKind.DetailsInit, payload: result });

  const activeLocale = useMemo(() => state.locales.find(l => l.active), [state.locales]);

  const checkSlugAndDispatchUpdate = async (slug: string, manual: boolean, id?: string) => {
    dispatchUpdateSelectedSlug(((await checkSlugContent(slugify(slug), id)).slug), manual);
  };

  type Tuple = [
    typeof state,
    {
      dispatchShowNewLanguageDialog: typeof dispatchShowNewLanguageDialog;
      dispatchAddNewLocale: typeof dispatchAddNewLocale;
      dispatchSetSelectedLocale: typeof dispatchSetSelectedLocale;
      dispatchSeletedDetails: typeof dispatchSeletedDetails;
      dispatchSeletedSummary: typeof dispatchSeletedSummary;
      dispatchSeletedTitle: typeof dispatchSeletedTitle;
      dispatchUpdateSelectedSlug: typeof dispatchUpdateSelectedSlug;
      dispatchUpdateSelectedImage: typeof dispatchUpdateSelectedImage;
      dispatchContentState: typeof dispatchContentState;
      dispatchSEOImageChange: typeof dispatchSEOImageChange;
      dispatchInit: typeof dispatchInit;
      dispatchContentType: typeof dispatchContentType;
      activeLocale: typeof activeLocale;
      checkSlugAndDispatchUpdate: typeof checkSlugAndDispatchUpdate;
    },
  ];

  const toRet: Tuple = [
    state,
    {
      dispatchShowNewLanguageDialog,
      dispatchAddNewLocale,
      dispatchSetSelectedLocale,
      dispatchSeletedDetails,
      dispatchSeletedSummary,
      dispatchSeletedTitle,
      dispatchUpdateSelectedSlug,
      dispatchUpdateSelectedImage,
      dispatchContentState,
      dispatchSEOImageChange,
      dispatchContentType,
      dispatchInit,
      activeLocale,
      checkSlugAndDispatchUpdate
    },
  ];
  return toRet;
};

export default useContentDetails;
