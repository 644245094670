import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import {
  ContentBox,
  Center,
  BgBlock,
  SubSectionHeader,
  SubSectionTitle,
  TextCenter,
} from "../../components/StandardComponents";

import { MainLayout2 } from "../../components/layouts";
import {
  ToursDataProvider,
  TourOptions,
  GetTourFn,
} from "../../dataProviders/ToursDataProvider";
import { encodeObject, ensureArray } from "../../utils";
import { NumericFormat } from "react-number-format";
import { Button } from "../card";
import styled from "styled-components";

const TourImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  justify-items: center;
  & > img {
    max-width: 100%;
  }
`

const IndexPage: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <>
      <ToursDataProvider>
        {(tours: TourOptions, getTour: GetTourFn) => {
          const tour = getTour(pageContext.tourId);
          return (
            <MainLayout2
              pageTitle={tour.name}
              pageBannerImg={"/assets/general-banner.jpg"}
              pageCrumbMap={[
                { link: "/", name: t("Home") },
                {
                  link: "/transportation-and-tours",
                  name: t("Transportation & Tours"),
                },
              ]}
            >
              <ContentBox>
                <TourImageContainer>
                {ensureArray(tour.tourImages).map(image=> 
                  
                    <img src={image} ></img>
                  )}
                </TourImageContainer>
              </ContentBox>
              <ContentBox>
                <Center>
                  <TextCenter>
                    {ensureArray(tour.tourSummary).join(" ")}
                  </TextCenter>
                </Center>
              </ContentBox>
              <ContentBox>
                <SubSectionTitle>
                  {ensureArray(tour.tourSchedules).length > 1
                    ? "Schedules"
                    : "Schedule"}
                </SubSectionTitle>
                {ensureArray(tour.tourSchedules).length > 1 && (
                  <ul>
                    {ensureArray(tour.tourSchedules).map((item, index) => (
                      <li>
                        {item.scheduleName}:
                        {` ${ensureArray(tour.tourSchedules)[0].start} to  ${
                          ensureArray(tour.tourSchedules)[0].end
                        }`}
                      </li>
                    ))}
                  </ul>
                )}
                {ensureArray(tour.tourSchedules).length == 1 && (
                  <ul>
                    <li>
                      {`${ensureArray(tour.tourSchedules)[0].start} to  ${
                        ensureArray(tour.tourSchedules)[0].end
                      }`}
                    </li>
                  </ul>
                )}
                <SubSectionTitle>Price</SubSectionTitle>
                <ul>
                  {ensureArray(tour.price).map((item, index) => (
                    <li>
                      {item.price > 0 ? (
                        <>
                          <NumericFormat
                            value={item.price}
                            displayType={"text"}
                            thousandSeparator={true}
                          ></NumericFormat>
                          {` ${item.currency}/${item.unit} ${
                            item.description ? `for ${item.description}` : ""
                          }`}
                        </>
                      ) : (
                        `Free for ${item.description}`
                      )}
                    </li>
                  ))}
                </ul>

                {tour.tourItinerary && (
                  <>
                    <SubSectionTitle>Itinerary</SubSectionTitle>
                    <ul>
                      {ensureArray(tour.tourItinerary).map((item, index) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </>
                )}
                {tour.tourIncludes && tour.tourIncludes.length > 0 && (
                  <>
                    <SubSectionTitle>Tour Includes</SubSectionTitle>
                    <ul>
                      {ensureArray(tour.tourIncludes).map((item, index) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </>
                )}
                {tour.tourExcludes && tour.tourExcludes.length > 0 && (
                  <>
                    <SubSectionTitle>Tour Excludes</SubSectionTitle>
                    <ul>
                      {ensureArray(tour.tourExcludes).map((item, index) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </>
                )}

                <SubSectionTitle>Additional Information</SubSectionTitle>
                <ul>
                  {ensureArray(tour.tourNotes).map((item, index) => (
                    <>{item && <li>{item}</li>}</>
                  ))}
                  {tour.minGroupSize && (
                    <li>Minimum Group Size of {tour.minGroupSize} People</li>
                  )}
                  {tour.maxGroupSize && (
                    <li>Maximum Group Size of {tour.maxGroupSize} People</li>
                  )}
                </ul>
                {tour.tourPolicy && (
                  <>
                    <SubSectionTitle>Tour Policies</SubSectionTitle>
                    <ul>
                      {ensureArray(tour.tourPolicy).map((item, index) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </>
                )}
                <TextCenter>
                  <Button
                    href={`/contact-us/?encoded=${encodeObject({
                      selectedName: "Book a Tour",
                      values: [
                        {
                          key: "Tour Name",
                          value: tour.name,
                        },
                      ],
                    })}`}
                  >
                    {t("Book Now")}
                  </Button>
                </TextCenter>
              </ContentBox>
            </MainLayout2>
          );
        }}
      </ToursDataProvider>
    </>
  );
};

export default IndexPage;
