import slugify from "slugify";
import {
  ContentItem,
  ContentItemState,
  ContentItemType,
} from "../../../backend-api/types";
import { getLangForLocale } from "../../helpers/supportedLocales";
import { LocaleEditable } from "./types";

export enum ActionKind {
  ShowNewLangDialog = "ShowNewLangDialog",
  AddLocale = "AddLocale",
  UpdateSelectedDetails = "UpdateSelectedDetails",
  UpdateSelectedTitle = "UpdateSelectedTitle",
  UpdateSelectedSummary = "UpdateSelectedSummary",
  SetSelectedLocale = "SetSelectedLocale",
  UpdateSlug = "UpdateSlug",
  UpdateContentImage = "UpdateContentImage",
  UpdateContentState = "UpdateContentState",
  DetailsInit = "DetailsInit",
  UpdateSelectedSEOImage = "UpdateSelectedSEOImage",
  UpdateContentType = "UpdateContentType",
}

type AddLocaleAction = {
  type: ActionKind.AddLocale;
  payload: LocaleEditable;
};

type UpdateContentTypeAction = {
  type: ActionKind.UpdateContentType;
  payload: ContentItemType;
};

type DisplayLanguageDialogAction = {
  type: ActionKind.ShowNewLangDialog;
  payload: boolean;
};

type UpdateContentStateAction = {
  type: ActionKind.UpdateContentState;
  payload: ContentItemState;
};

type UpdateSelectedDetails = {
  type: ActionKind.UpdateSelectedDetails;
  payload: string;
};

type UpdateSelectedTitle = {
  type: ActionKind.UpdateSelectedTitle;
  payload: string;
};

type UpdateSlugAction = {
  type: ActionKind.UpdateSlug;
  payload: { manualUpdate: boolean; slug: string };
};

type UpdateSelectedSummary = {
  type: ActionKind.UpdateSelectedSummary;
  payload: string;
};

type SetSelectedLocaleAction = {
  type: ActionKind.SetSelectedLocale;
  payload: LocaleEditable;
};

type UpdateContentImageAction = {
  type: ActionKind.UpdateContentImage;
  payload: string;
};

type DetailsInitAction = {
  type: ActionKind.DetailsInit;
  payload: ContentItem;
};

type UpdateSelectedSEOImageAction = {
  type: ActionKind.UpdateSelectedSEOImage;
  payload: string;
};

export type DetailsReducerAction =
  | AddLocaleAction
  | DisplayLanguageDialogAction
  | UpdateSelectedDetails
  | UpdateSelectedTitle
  | UpdateSelectedSummary
  | SetSelectedLocaleAction
  | UpdateSlugAction
  | UpdateContentImageAction
  | UpdateContentStateAction
  | DetailsInitAction
  | UpdateContentTypeAction
  | UpdateSelectedSEOImageAction;

export interface StateInterface {
  showNewLanguageDialog: boolean;
  locales: Array<LocaleEditable>;
  contentType: ContentItemType;
  publishState: ContentItemState;
}

export const stateInit = (init: string | undefined | null): StateInterface => {
  return {
    showNewLanguageDialog: false,
    locales: [],
    publishState: ContentItemState.draft,
    contentType: ContentItemType.article,
  };
};

export const reducer = (
  state: StateInterface,
  action: DetailsReducerAction
): StateInterface => {
  const { type } = action;
  switch (type) {
    case ActionKind.AddLocale: {
      const { payload } = action as AddLocaleAction;
      return {
        ...state,
        locales: [
          ...state.locales.map((item) => ({ ...item, active: false })),
          { ...payload, default: state.locales.length == 0 },
        ],
      };
    }
    case ActionKind.ShowNewLangDialog: {
      const { payload } = action as DisplayLanguageDialogAction;
      return { ...state, showNewLanguageDialog: payload };
    }
    case ActionKind.UpdateSelectedDetails: {
      const { payload } = action as UpdateSelectedDetails;
      return {
        ...state,
        locales: [
          ...state.locales.map((locale) =>
            locale.active ? { ...locale, content: payload } : locale
          ),
        ],
      };
    }
    case ActionKind.UpdateSelectedTitle: {
      const { payload } = action as UpdateSelectedTitle;
      return {
        ...state,
        locales: [
          ...state.locales.map((locale) =>
            locale.active ? { ...locale, title: payload } : locale
          ),
        ],
      };
    }
    case ActionKind.UpdateSelectedSummary: {
      const { payload } = action as UpdateSelectedSummary;
      return {
        ...state,
        locales: [
          ...state.locales.map((locale) =>
            locale.active ? { ...locale, summary: payload } : locale
          ),
        ],
      };
    }
    case ActionKind.SetSelectedLocale: {
      const { payload } = action as SetSelectedLocaleAction;
      return {
        ...state,
        locales: [
          ...state.locales.map((item) =>
            item === payload
              ? { ...item, active: true }
              : { ...item, active: false }
          ),
        ],
      };
    }
    case ActionKind.UpdateSlug: {
      const { payload } = action as UpdateSlugAction;
      return {
        ...state,
        locales: [
          ...state.locales.map((locale) =>
            locale.active
              ? {
                  ...locale,
                  slug: payload.slug,
                  slugUpdatedManually:
                    locale.slugUpdatedManually || payload.manualUpdate,
                }
              : locale
          ),
        ],
      };
    }
    case ActionKind.UpdateContentImage: {
      const { payload } = action as UpdateContentImageAction;
      return {
        ...state,
        locales: [
          ...state.locales.map((locale) =>
            locale.active ? { ...locale, image: payload } : locale
          ),
        ],
      };
    }
    case ActionKind.UpdateContentState: {
      const { payload } = action as UpdateContentStateAction;
      return { ...state, publishState: payload };
    }
    case ActionKind.UpdateContentType: {
      const { payload } = action;
      return { ...state, contentType: payload };
    }
    case ActionKind.DetailsInit: {
      const { payload } = action as DetailsInitAction;
      return {
        showNewLanguageDialog: false,
        publishState: payload.publishState,
        contentType: payload.contentType,
        locales: payload.localizations.map((item, index) => ({
          ...item,
          active: index === 0,
          label: getLangForLocale(item.locale),
          slugUpdatedManually: slugify(item.title) != item.slug,
        })),
      };
    }
    case ActionKind.UpdateSelectedSEOImage: {
      const { payload } = action as UpdateSelectedSEOImageAction;
      return {
        ...state,
        locales: [
          ...state.locales.map((locale) =>
            locale.active ? { ...locale, seoImage: payload } : locale
          ),
        ],
      };
    }
    default:
      throw new Error("Invalid action type");
  }
};
