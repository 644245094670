import React, { useState, useEffect } from "react";
import Login from "./login";
import Admin from "./admin";
import GlobalStyle from "../styles/GlobalStyle";

import { Router } from "@reach/router";
import { getMe, User } from "../backend-api/admin-api";

import { UnauthorizedError, login } from "../backend-api/api";
import { navigate } from "gatsby";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

const Index = () => {
  const [user, setUser] = useState<User | null>(null);

  const queryClient = new QueryClient();

  const checkAuth = () => {
    return getMe()
      .then((result) => {
        //this breaks direct navigation
        //navigate("/admin");
        if (
          window &&
          typeof window !== "undefined" &&
          window.location.pathname === "/admin/login"
        ) {
          navigate("/admin");
        }
        setUser(result);
      })
      .catch((err) => {
        if (err instanceof UnauthorizedError) {
          setUser(null);
          navigate("/admin/login");
        }
      });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const doLogin = ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    login(username, password).then((result) => {
      console.log(result);
      if (result) {
        checkAuth();
      }
    });
  };

  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <Router basepath="/admin" className="h-full">
          <Login path="login" submit={doLogin} />
          {user && <Admin path="*" user={user} />}
        </Router>
      </QueryClientProvider>
    </>
  );
};

export default Index;
