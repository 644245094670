import React, { useState } from "react";
import {
  ContentBox,
  DesktopOnly,
  MobileOnly,
} from "../../components/StandardComponents";
import { MenuImageSelector } from "./MenuImageSelector";
import { MenuSection } from "./MenuSection";
import { MenuDefArray } from "./Types";
import { MenuTextSelector } from "./MenuTextSelector";
import styled from "styled-components";

interface RestaurantMenuInterface {
  menuData: MenuDefArray;
}

export const PurchaseManuLayoutDesktop: React.FC<RestaurantMenuInterface> = ({
  menuData,
}) => {
  const [selected, setSelected] = useState(0);

  const buildMenu = (selected: number, menuData: Array<any>) => {
    return menuData.map((item, index) => {
      return { name: item.name, img: item.img, selected: selected === index };
    });
  };

  return (
    <>
      {menuData.length > 1 && (
        <>
          {/*<ContentBox>*/}
          <MenuTextSelector
            menu={buildMenu(selected, menuData)}
            setSelected={setSelected}
          ></MenuTextSelector>
          {/*</ContentBox>*/}
        </>
      )}
      {menuData
        .filter((_item, index) => index === selected)[0]
        .sections.map((section, index) => {
          //console.log(section);
          return (
            <MenuSection section={section} dark={index % 2 === 0}></MenuSection>
          );
        })}
    </>
  );
};

const MenuSelectionMobile = styled.div`
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  color: "black";
  font-size: 150% ;
  display: flex ;
  justify-content: center;

  background-color: ${(props) => props.theme.colorOne};
  border-bottom: 2px solid ${(props) => props.theme.colorOneLighter};
  border-top: 2px solid ${(props) => props.theme.darkColor};
`;

export const PurchaseManuLayoutMobile: React.FC<RestaurantMenuInterface> = ({
  menuData,
}) => {
  return (
    <>
      {menuData.map((menu) => (
        <>
          {menuData.length > 1 && <MenuSelectionMobile>{menu.name}</MenuSelectionMobile>}
          {menu.sections.map((section, index) => (
            <MenuSection section={section} dark={index % 2 === 0}></MenuSection>
          ))}
        </>
      ))}
    </>
  );
};

export const PurchaseMenu: React.FC<RestaurantMenuInterface> = ({
  menuData,
}) => (
  <>
    <DesktopOnly>
      <PurchaseManuLayoutDesktop menuData={menuData} />
    </DesktopOnly>
    <MobileOnly>
      <PurchaseManuLayoutMobile menuData={menuData} />
    </MobileOnly>
  </>
);
