import React from "react";
import styled, { css } from "styled-components";
//import { I18n, I18nTFunction } from "react-i18next";

const TableContainerMobileCss = css`
  //background-color: pink;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const TableContainerDesktopCss = css`
  ${TableContainerMobileCss}
`;

const TableContainer = styled.div`
  @media only screen and (min-width: 660px) {
    ${TableContainerDesktopCss}
  }
  @media only screen and (max-width: 660px) {
    ${TableContainerMobileCss}
  }
`;

const TableRow = styled.div`
  display: flex;
  //background-color: yellow;
  justify-content: space-between;
  padding: 4px;

  &:nth-child(odd) {
    background-color: #eeeeee;
  }
`;
const TableCell = styled.div`
  font-size: 85%;
  display: flex;
  flex-direction: column;
  //background-color: blue;
  &:first-child {
    font-weight: bold;
  }
  &:last-child {
    //justify-content: ;
  }

  & > div {
    padding: 2px;
  }
`;

const Table: React.FC = ({ children }) => {
  const drawTable = () => {
    if (Array.isArray(children)) {
      return children.map((row, rowIndex) => {
        if (Array.isArray(row)) {
          return (
            <TableRow key={rowIndex}>
              {row.map((col, colIndex) => {
                if (Array.isArray(col)) {
                  return (
                    <TableCell key={`${rowIndex}-${colIndex}`}>
                      {col.map((colItem, colItemIndex) => (
                        <div key={`${rowIndex}-${colIndex}-${colItemIndex}`}>
                          {colItem}
                        </div>
                      ))}
                    </TableCell>
                  );
                } else if (col) {
                  return (
                    <TableCell key={`${rowIndex}-${colIndex}`}>{col}</TableCell>
                  );
                }
              })}
            </TableRow>
          );
        }
      });
    }
    return <></>;
  };

  return <TableContainer>{drawTable()}</TableContainer>;
};

export default Table;
