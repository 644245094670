import { RouteComponentProps, Router } from "@reach/router";
import React from "react";
import { ContentDetails } from "./details";
import ContentList from "./list";
import { MessagesProps } from "../types";


const ContentIndex = (props: RouteComponentProps & MessagesProps) => (
  <>
    <div className={"container p-10"}>
      <Router>
        <ContentDetails path=":id" messages={props.messages} />
        <ContentList default messages={props.messages}/>
      </Router>
    </div>
  </>
);

export default ContentIndex;
