import React, { useState, useEffect } from "react";
//import styled from "styled-components";
import TopNav from "./TopNav";
import TopNavMobile from "./TopNavMobile";
import BottomNav, { NavLink } from "./BottomNav.1";
import BottomNavMobile from "./BottomNavMobile";
import styled from "styled-components";
import { DesktopOnly, MobileOnly } from "../StandardComponents";

const NavContainer = styled.div`
  z-index: 10;
  position: ${(props) => (props.fixed ? "fixed" : "relative")};
  ${(props) => props.fixed && "width: 100%;"}
`;

interface Props {
  TopNavBackgroundColor: string;
  BottomNavBackgroundColor: string;
  BottomNavBackgroundColorOpacity?: number;
  BottomNavBackgroundColorMobile: string;
  BottomNavBackgroundColorOpacityMobile?: number;
  BottomNavLinks: Array<NavLink>;
  BottomLinkHoverColor: string;
}

const Nav: React.FC<Props> = ({
  TopNavBackgroundColor,
  BottomNavBackgroundColor,
  BottomNavBackgroundColorOpacity,
  BottomNavLinks,
  BottomLinkHoverColor,
  BottomNavBackgroundColorMobile,
  BottomNavBackgroundColorOpacityMobile,
}) => {
  const [navExpanded, setNavExpanded] = useState(false);
  const [doTransition, setDoTransition] = useState(false);

  const resetDoTransition = () => {
    setDoTransition(false);
  };

  const resetExpanded = () => {
    setNavExpanded(false);
  };

  const expandAction = () => {
    setDoTransition(true);
    setNavExpanded(!navExpanded);
  };

  useEffect(() => {
    const listener = () => {
      if (navExpanded) {
        setNavExpanded(false);
        setDoTransition(true);
      }
    };
    window.addEventListener("click", listener);
    return () => window.removeEventListener("click", listener);
  }, []);

  //console.log(navExpanded);
  return (
    <NavContainer fixed={navExpanded}>
      <DesktopOnly>
        <TopNav backColor={TopNavBackgroundColor} />
      </DesktopOnly>
      <DesktopOnly>
        <BottomNav
          backColor={BottomNavBackgroundColor}
          backColorOpacity={BottomNavBackgroundColorOpacity}
          bottomNavLinks={BottomNavLinks}
          linkHoverColor={BottomLinkHoverColor}
        />
      </DesktopOnly>
      <MobileOnly>
        <TopNavMobile
          backColor={TopNavBackgroundColor}
          expandAction={expandAction}
        />
      </MobileOnly>
      <MobileOnly>
        <BottomNavMobile
          backColor={BottomNavBackgroundColorMobile}
          backColorOpacity={BottomNavBackgroundColorOpacityMobile}
          bottomNavLinks={BottomNavLinks}
          linkHoverColor={BottomLinkHoverColor}
          expanded={navExpanded}
          doTransition={doTransition}
          resetDoTransition={resetDoTransition}
          resetExpanded={resetExpanded}
        />
      </MobileOnly>
    </NavContainer>
  );
};

export default Nav;
