import React, { useEffect, useState } from "react";

import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { RouteComponentProps } from "@reach/router";
import { Promotion, PromotionLocalization } from "../../backend-api/types";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import {usePromotions} from "../hooks/promotionsHooks";

const PromotionsList = (props: RouteComponentProps) => {
  const promotions = usePromotions();

  const tableTitleExtractor = (rowData: Promotion) => {
    if (!rowData.localizations || rowData.localizations.length === 0) {
      return "NO DATA";
    }

    let defaultLocal = rowData.localizations.find(
      (locale: PromotionLocalization) => locale.default === true
    );
    if (!defaultLocal) {
      defaultLocal = rowData.localizations[0];
    }

    return defaultLocal.title;
  };

  const tableActiveBody = (rowData: Promotion) => {
    return (
      <>
        {rowData.enabled ? (
          <Tag
            icon="pi pi-check"
            value={"Active"}
            rounded={true}
            severity={"success"}
          />
        ) : (
          <Tag
            icon="pi pi-times"
            value={"Disabled"}
            rounded={true}
            severity={"danger"}
          />
        )}
      </>
    );
  };

  const tableActionsBody = (rowData: Promotion) => (
    <div className="flex justify-center">
      <div className={"pr-1"}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success"
          onClick={() => {
            rowData.id && props.navigate && props.navigate(rowData.id);
            //setSelectedUser(rowData);
            //setEditVisible(true);
          }}
        />
      </div>
      <div className={"pl-1 pr-1"}>
        <ConfirmPopup />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={(event) => {
            //setSelectedUser(rowData);
            //setDeleteConfirmVisible(true);
            confirmPopup({
              target: event.currentTarget,
              message: "Are you sure you want to proceed?",
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                rowData.id && promotions.delete(rowData.id);
              },
              reject: () => null,
            });
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <Button
        icon={"pi pi-plus"}
        label={"New Promotion"}
        onClick={() => {
          props.navigate && props.navigate("new");
        }}
      />
      {promotions.get.data && (
        <DataTable value={promotions.get.data}>
          <Column header={"Title"} body={tableTitleExtractor}></Column>
          <Column field="slug" header={"Slug"}></Column>
          <Column header={"Active"} body={tableActiveBody}></Column>
          <Column body={tableActionsBody} />
        </DataTable>
      )}
    </>
  );
};

export default PromotionsList;
