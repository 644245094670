import React from "react";
import styled, { css } from "styled-components";

export const WhiteSVG = css`
  filter: invert(1);
`;

export const Icon = styled.img<{ filter?: String; Width?: Number }>`
  width: 20px;
  ${WhiteSVG}
  ${(props) => props.filter && `filter: ${props.filter};`}
  ${(props) => props.Width && `width: ${props.Width}px;`}
`;

const AssetsBase = "/assets";

interface AssetBaseProps {
  Name: string;
  AltText: string;
  Filter?: string;
  Width?: Number;
}

export const AssetIcon: React.FC<AssetBaseProps> = ({
  Name,
  AltText,
  Filter,
  Width,
}) => (
  <Icon
    src={`${AssetsBase}/${Name}`}
    alt={AltText}
    filter={Filter}
    Width={Width}
    //width={20}
    //height={25}
  />
);

export const FacebookIcon: React.FC = () => (
  <AssetIcon Name="facebook-square-brands.svg" AltText="facebook icon" />
);

export const InstagramIcon: React.FC = () => (
  <AssetIcon Name="instagram-brands.svg" AltText="instagram icon" />
);

export const TripadvisorIcon: React.FC = () => (
  <AssetIcon Name="tripadvisor-brands.svg" AltText="tripadvisor icon" />
);

export const TwitterIcon: React.FC = () => (
  <AssetIcon Name="twitter-brands.svg" AltText="twitter icon" />
);

export const MapMarkedIcon: React.FC = () => (
  <AssetIcon Name="map-marked-solid.svg" AltText="map marked icon" />
);

export const HeadsetIcon: React.FC = () => (
  <AssetIcon Name="headset-solid.svg" AltText="headset icon" />
);

export const EnvelopeIcon: React.FC = () => (
  <AssetIcon Name="envelope-regular.svg" AltText="envelope icon" />
);

export const BarsIcon: React.FC = () => (
  <AssetIcon Name="bars-solid.svg" AltText="expand icon" />
);

export const ChevronLeftIcon: React.FC<{ Width?: Number; Filter?: string }> = ({
  Width,
  Filter,
}) => (
  <AssetIcon
    Name="chevron-left-solid.svg"
    AltText="expand icon"
    Filter={Filter || "none"}
    Width={Width}
  />
);

export const ChevronRightIcon: React.FC = () => (
  <AssetIcon Name="chevron-right-solid.svg" AltText="expand icon" />
);

export const LocationMarkerIcon: React.FC = () => (
  <AssetIcon Name="map-marker-alt-solid.svg" AltText="location icon" />
);

export const PhoneIcon: React.FC = () => (
  <AssetIcon Name="phone-alt-solid.svg" AltText="phone icon" />
);

export const FaxIcon: React.FC = () => (
  <AssetIcon Name="fax-solid.svg" AltText="fax icon" />
);

export const WhatsAppIcon: React.FC = () => (
  <AssetIcon Name="whatsapp-brands.svg" AltText="whatsapp icon" />
);