import React from "react";
import styled from "styled-components";
import { rgba } from "polished";

//import { InputText as UnstyledInputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const InputText: React.FC<{
  className?: string;
  placeholder?: string;
  image: string;
}> = ({ className, ...rest }) => (
  <input type={"text"} className={className} {...rest} />
);

const StyledInputText = styled(InputText)`
  &&& {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    text-decoration: none;
    padding: 0.429em;
    width: 120px;
    height: 30px;
    background: url(${(props) => props.image}) no-repeat scroll 95px 4px;
    background-color: ${(props) => rgba("#1c1c1c", 0.7)};
    background-size: 20px 20px;
    color: white;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    :focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2em #8dcdff;
      border-color: #007ad9;
    }
    :hover {
      border-color: #212121;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bbbbbb;
      opacity: 1; /* Firefox */
      font-size: 11px;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bbbbbb;
      font-size: 11px;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bbbbbb;
      font-size: 11px;
    }
  }
`;

export default StyledInputText;
