import React from "react";
import styled from "styled-components";
import RatioContainer, { RatioContainerImage } from "./RatioContainer";

/*const PageImageHeader = styled.div`
  position: relative;
`;*/

/*const ImageStyled = styled.img`
  /*Need this to make image fill container to bottom*/
/*display: block;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  border: none;
`;*/

interface PageBannerProps {
  src: string;
}

export const PageImageBanner: React.FC<PageBannerProps> = (props) => {
  return (
    /*<PageImageHeader>
      <ImageStyled src={props.src}></ImageStyled>
    </PageImageHeader>*/
    <RatioContainer
      widthRatio={1280}
      heightRatio={300}
      backgroundColor={"#1c1c1c"}
    >
      <RatioContainerImage src={props.src}></RatioContainerImage>
    </RatioContainer>
  );
};
