import React from "react";
import styled, { css } from "styled-components";
import { getImage } from "../../../lib/image-resolve";
import { ContentBox } from "../StandardComponents";

interface MenuSelectionItemInterface {
  selected: boolean;
}

const MenuSelectionItem = styled.div<MenuSelectionItemInterface>`
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: ${(props) => (props.selected ? 500 : 400)};
  position: relative;
  width: 220px;
  height: 40px;
  color: ${(props) => (props.selected ? "white" : "Black")};
  background-color: ${(props) =>
    props.selected ? props.theme.darkColor : "unset"};

  border-bottom: 2px solid
    ${(props) =>
      props.selected ? props.theme.darkColor : props.theme.colorOne};
  border-top: 2px solid
    ${(props) =>
      props.selected ? props.theme.darkColor : props.theme.colorOne};

  cursor: pointer;

  & :hover {
    border-bottom: 2px solid ${(props) => "white"};
    border-top: 2px solid ${(props) => props.theme.darkColor};
    color: ${(props) => "white"};
    font-weight: 500;
    font-size: 105%;
  }

  //& :hover > div > div {
  //  background-color: rgba(0, 0, 0, 0.9);
  //  border: 1px solid white;
  //}
`;

const MenuSelectionItemsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 1024px;
  flex: 1;
`;

const MenuSelectionItemsBgContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colorOne};
`;

const MenuSelectionTextContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //border: 1px solid black;
`;

const MenuSelectionText = styled.div`
  //background-color: rgba(0, 0, 0, 0.7);
  //color: Black;
  width: 100%;
  text-align: center;
  //font-weight: 500;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 180%;
  //background-color: pink;
`;

interface MenuSeletorDataInterface {
  selected: boolean;
  img: string;
  name: string;
}

const DividerContainer = styled.div`
  //background-color: red;
  position: absolute;
  top: calc(50% - 10px);
  width: 100%;
  height: 20px;
  border-right: 1px solid ${(props) => props.theme.darkColor};
`;

type IndexSelectedFn = (index: number) => void;

interface MenuSelectorInterface {
  menu: Array<MenuSeletorDataInterface>;
  setSelected: IndexSelectedFn;
}

export const MenuTextSelector: React.FC<MenuSelectorInterface> = ({
  menu,
  setSelected,
}) => {
  return (
    <MenuSelectionItemsBgContainer>
      <MenuSelectionItemsContainer>
        {menu.map((item, index) => (
          <MenuSelectionItem
            key={`MenuSelectionItem-${index}`}
            selected={item.selected}
            onClick={() => setSelected(index)}
          >
            <MenuSelectionTextContainer>
              <MenuSelectionText>{item.name}</MenuSelectionText>
            </MenuSelectionTextContainer>
          </MenuSelectionItem>
        ))}
      </MenuSelectionItemsContainer>
    </MenuSelectionItemsBgContainer>
  );
};
