import React from "react";
import styled from "styled-components";
import { Link as UnstyledLink } from "gatsby-plugin-react-i18next";
import {
  FacebookIcon,
  TripadvisorIcon,
  InstagramIcon,
  TwitterIcon,
  MapMarkedIcon,
  EnvelopeIcon,
  HeadsetIcon,
  BarsIcon,
} from "../icons";
import { TawkToMessenger } from "../TawkTo";
//import FreshChat from "../Freshchat";

const Link = styled(UnstyledLink)`
  text-decoration: none;
`;

const StandardLink = styled.a`
  text-decoration: none;
`;

const SocialLinkWrapper: React.FC<{
  href: string;
  Icon: React.FC;
  standardLink?: boolean;
}> = ({ href, Icon, standardLink }) => {
  return (
    <>
      {standardLink ? (
        <StandardLink href={href}>
          <Icon />
        </StandardLink>
      ) : (
        <Link to={href}>
          <Icon />
        </Link>
      )}
    </>
  );
};

const SocialIconContainer = styled.div`
  display: flex;
  margin-right: 20px;
  & > a {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;

    & :first-child {
      margin-left: 0px;
    }

    & :last-child {
      margin-right: 0px;
    }
  }
`;

const ActionLabel = styled.span`
  color: white;
  font-size: 13px;
  margin-left: 10px;
`;

const ActionContainerStyle = styled.span`
  display: flex;
`;

const ActionContainer: React.FC<{ href: string; standardLink?: boolean }> = ({
  children,
  href,
  standardLink,
}) => {
  return (
    <>
      {standardLink ? (
        <StandardLink href={href}>
          <ActionContainerStyle>{children}</ActionContainerStyle>
        </StandardLink>
      ) : (
        <Link to={href}>
          <ActionContainerStyle>{children}</ActionContainerStyle>
        </Link>
      )}
    </>
  );
};

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: auto;
  & > a {
    margin-left: 10px;
    margin-right: 10px;

    & :first-child {
      margin-left: 0px;
    }

    & :last-child {
      margin-right: 0px;
    }
  }
`;

const LogoPath = "/assets/Laluna-Logo-150x150.png";

interface Props {
  backColor: string;
  expandAction?: Function;
}

const TopNavContainer = styled.div<Props>`
  background-color: ${(props) => props.backColor};
  height: 50px;
  display: flex;
  justify-content: space-between;
`;

const ExpandButtonContainer = styled.button`
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background-color: #1c1c1c;

  &:focus {
    border: none;
    outline: none;
  }
`;

const TopNav: React.FC<Props> = ({ backColor, expandAction }) => (
  <TopNavContainer backColor={backColor}>
    {/*<FreshChat></FreshChat>*/}
    <TawkToMessenger />
    <Link to={"/"}>
      <img src={LogoPath} width="48" alt="logo" />
    </Link>
    <ExpandButtonContainer
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        expandAction && expandAction();
      }}
    >
      <BarsIcon />
    </ExpandButtonContainer>
  </TopNavContainer>
);

export default TopNav;
