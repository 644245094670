import React from "react";
/*import { I18n, I18nTFunction } from "react-i18next";*/
import { graphql } from "gatsby";
import GlobalStyle from "../../../styles/GlobalStyle";
import {
  ContentBox,
  Center,
  BgBlock,
  SubSectionHeader,
  TextCenter,
} from "../../../components/StandardComponents";

import { MainLayout2 } from "../../../components/layouts";
import Card, { CardBox, CardTitleOverlay } from "../../../components/card";
import { LalunaDarkColor } from "../../../constants";

import { PrivateCarTransportTable } from "../../../components/Transportation/PrivateCarTransportTable";

import styled from "styled-components";
import { TransportationDataProvider } from "../../../dataProviders/TransportationDataProvider";
import { CarSerivceOptions } from "../../../components/Transportation/Types";
import { TourOptions } from "../../../dataProviders/ToursDataProvider";
import { ToursDataProvider } from "../../../dataProviders/ToursDataProvider";
import { encodeObject, ensureArray } from "../../../utils";
import { TourCardDetailsComposite } from "../../../components/Tours/TourCardDetails";
import { Modal } from "../../../components/modal/Modal";
import { getImage } from "../../../../lib/image-resolve";
import CardButton from "../../../components/card/CardButton";
import { useTranslation } from "react-i18next";

const TransportOptionsCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 120%;
`;

const OuterDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
`;

const IndexPage: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <>
      {console.log(pageContext)}
      <MainLayout2
        pageTitle={t("Private Driver and Transportation")}
        pageBannerImg={"/assets/general-banner.jpg"}
        pageCrumbMap={[
          { link: "/", name: t("Home") },
          {
            link: "/transportation-and-tours",
            name: t("Transportation & Tours"),
          },
        ]}
      >
        <ContentBox>
          <TextCenter>
            {
              "All of us at Laluna Hoi An Riverside Hotel & Spa strive to provide the best possible experience for you while you visit our lovely town of Hoi An and that's why we provide an airport transfer service."
            }
            {
              " Whether you are just landing at our nearby airport in Da nang or preparing to leave for your next adventure, we can provide you safe, reliable, and secure transport to and from the airport."
            }
          </TextCenter>

          <OuterDetailsContainer>
            <TextCenter>
              <CardButton
                href={`/contact-us/?encoded=${encodeObject({
                  selectedName: "Reserve Private Driver",
                })}`}
              >
                Reserve Now
              </CardButton>
            </TextCenter>
          </OuterDetailsContainer>

          <TransportationDataProvider>
            {(privateCarOptions: CarSerivceOptions) => {
              return <PrivateCarTransportTable options={privateCarOptions} />;
            }}
          </TransportationDataProvider>
          <Center>
            {
              "If you would like a quote for a custom itinerary, please email info@lalunahoian.com with the details of your desired itinerary."
            }
          </Center>
        </ContentBox>
      </MainLayout2>
    </>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
