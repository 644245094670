import React from "react";
import styled, { keyframes, css } from "styled-components";
import { rgba } from "polished";
import { Link, Center } from "../StandardComponents";

export interface NavLink {
  displayName: string;
  href: string;
}

interface ContainerProps {
  backColor: string;
  backColorOpacity?: number;
}

interface BottomNavLinkStyleProps {
  linkHoverColor: string;
}

interface Props {
  backColor: string;
  backColorOpacity?: number;
  linkHoverColor: string;
  bottomNavLinks: Array<NavLink>;
}

const BottomNavContainer = styled.div<ContainerProps>`
  background-color: ${(props) =>
    rgba(props.backColor, props.backColorOpacity || 1)};
  height: 35px;
  border-bottom: #1c1c1c solid 1px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

interface BottomNavItemContainerProps {
  active?: boolean;
}
const BottomNavItemContainer = styled.div<BottomNavItemContainerProps>`
  display: flex;
  //background-color: red;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid rgba(0, 0, 0, 0);

  &:hover {
    border-bottom: 3px solid #1c1c1c;
  }

  ${(props) => props.active && "border-bottom: 3px solid #1c1c1c;"}
`;

const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;

  color: white;
  text-shadow: 1px 1px 6px ${(props) => rgba("#000000", 1)};

  &:first-child > div > div {
    //background-color: red;
    border-left: none;
  }

  &:last-child > div > div {
    //background-color: red;
    border-right: none;
  }

  &:visited {
    color: white;
  }

  &:hover {
    color: ${(props) => props.linkHoverColor};
  }
`;

const BottomNavLinkStyle = styled.div<BottomNavLinkStyleProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  border-left: 1px solid #1c1c1c;
  font-family: "Playfair Display", serif;
  font-size: 85%;
  //border-right: 1px solid #1c1c1c;
  & > a {
    color: white;
    text-shadow: 1px 1px 6px ${(props) => rgba("#000000", 1)};
  }
  & > a :visited {
    color: white;
  }

  & > a :hover {
    color: ${(props) => props.linkHoverColor};
  }

  /*& :first-child {
    //background-color: red;
    border-left: none;
  }

  & :last-child {
    //background-color: red;
    border-right: none;
  }*/

  @media only screen and (max-width: 1000px) {
    font-size: 75%;
  }

  @media only screen and (max-width: 800px) {
    font-size: 65%;
  }

  @media only screen and (max-width: 768px) {
    font-size: 55%;
  }
`;

/*

hr.style-eight {
    overflow: visible
    padding: 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
}
hr.style-eight:after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: white;
}
*/
/*
const Dot = styled.span`
  border-radius: 3px;
  background-color: #3c3c3c;
  min-height:3px;
  max-height:3px;
  height: 3px;
  width: 3px;
  display: inline-block;
  margin: 2px 2px;
`;
const Dots: React.FC = () => (
  <>
  <Center>
    <Dot></Dot> <Dot></Dot> <Dot></Dot>
    </Center>
  </>
);

const ItemContainer = styled.div``;*/

const BottomNav: React.FC<Props> = ({
  backColor,
  backColorOpacity,
  bottomNavLinks,
  linkHoverColor,
}) => {
  const activeTab = (link: string): boolean => {
    if (typeof window !== "undefined") {
      return window.location.pathname.includes(link) && link.length > 1;
    }
    return false;
  };

  return (
    <BottomNavContainer
      backColor={backColor}
      backColorOpacity={backColorOpacity}
    >
      {bottomNavLinks.map((link, index) => {
        //console.log(link);
        return (
          <StyledLink to={link.href}>
            <BottomNavItemContainer active={activeTab(link.href)}>
              <BottomNavLinkStyle key={index} linkHoverColor={linkHoverColor}>
                {link.displayName}
              </BottomNavLinkStyle>
            </BottomNavItemContainer>
          </StyledLink>
        );
      })}
    </BottomNavContainer>
  );
};

export default BottomNav;
